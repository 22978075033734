import { PageDetails, Property } from "./property";
import { Type } from "class-transformer";

export class UserAccount {
  attachments?: Document[];
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  dateOfBirth?: string;
  @Type(() => Property)
  likedListings: Property[] = [];
  accountStatus?: AccountStatus;
}

export enum AccountStatus {
  INCOMPLETE_INFORMATION,
  INACTIVE,
  ACTIVE,
  SCHEDULED_FOR_DELETE,
}

export class Document {
  id?: string;
  link?: string;
  type?: DocumentType;
}

export enum DocumentType {
  I20 = "I20",
  Passport = "PASSPORT",
}

export class LikedListingsPaginatedResponse {
  @Type(() => Property)
  items?: Property[];
  pages?: PageDetails;
}
