// MainNav1.tsx
import React, { FC, useEffect, useRef, useState } from "react";
import Logo from "shared/Logo/Logo";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import SearchInput from "./SearchInput";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import GetAppDropdown from "./GetAppDropdown";
import { useLocation } from "react-router-dom";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { logout, isAuthenticated, isLoading, loginWithRedirect, user } =
    useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const documentRef = useRef<HTMLElement | null>(document.documentElement);

  let location = useLocation();

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        menuRef.current &&
        documentRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    documentRef.current!.addEventListener("mousedown", handleOutsideClick);

    return () => {
      documentRef.current!.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleLogout = () => {
    setIsOpen(false);
    logout();
  };

  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
<div className={`px-4 py-4 lg:py-5 lg:px-8 relative flex justify-between items-center ${isAuthenticated ? "ml-11" : ""}`}>        <div className="flex-none md:flex justify-start items-center">
          <Logo className="" />
        </div>

        {location.pathname !== "/" && (
          <>
            <div className="flex-1 md:flex justify-center items-center mx-5 hidden">
              <SearchInput
                type="Search by street name"
                className={"w-1/3"}
              />
            </div>
            <div className="ml-3 flex md:hidden justify-center items-center w-full">
              <SearchInput
                type="Street name"
                className={`${!isAuthenticated ? "w-3/5" : ""}`}
              />
            </div>
          </>
        )}
        <div
          className={`md:flex md:flex-shrink-0 ${
            !isAuthenticated ? "hidden" : "block"
          } items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100`}
        >
          <div className="md:flex items-center">
            {/* <SwitchDarkMode /> */}
            {/* <div className=""><Link to={"/connect"}>Senior Connect</Link></div> */}
            {/* <div className="mx-10"><Link to={"/resources"}>Resources</Link></div> */}
            <div className="hidden md:flex">
              <Link to={"/cosigner"}>Cosigner</Link>
            </div>

            {/* <div className=""><Link to={"/contact"}>contact</Link></div> */}

            {/* <GetAppDropdown /> */}
            <div className="px-10" />
            {!isAuthenticated && !isLoading && (
              <ButtonPrimary
                key="login"
                onClick={async () => {
                  await loginWithRedirect();
                }}
                className="hidden md:block"
              >
                Login
              </ButtonPrimary>
            )}
            <div className="flex justify-center items-center">
              {isAuthenticated && !isLoading && (
                <div className="relative" ref={menuRef}>
                  <button
                    onClick={handleToggle}
                    className="flex items-center focus:outline-none"
                  >
                    <img
                      className="h-11 w-11 rounded-full object-cover"
                      src={user!.picture}
                      alt={user!.name}
                    />
                  </button>
                  {isOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1">
                      {/* <button className="w-full px-6 py-2 text-left text-gray-800 hover:bg-gray-100">
                        Profile
                      </button> */}
                      <button
                        onClick={handleLogout}
                        className="w-full px-6 py-2 text-left text-gray-800 hover:bg-gray-100"
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
