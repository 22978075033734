import React, { FC } from "react";

export interface SectionWhyLandingProps {
    className?: string;
    children?: React.ReactNode;
}

const SectionWhyLanding: FC<SectionWhyLandingProps> = ({ className = "", children }) => {
    return (
        <div className="nc-SectionWhyLanding" data-nc-id="SectionWhyLanding">
            <div className="grid align-middle justify-center items-center">
                <h2 className="text-2xl md:text-5xl font-semibold text-center">Why choose Landing?</h2>
                <div className="mt-10 md:px-20 px-2">
                    <ul className="list-disc">
                        <li className="my-5">
                            <p><span className="font-semibold">Empathy and Understanding:</span> Our team is composed of individuals who have walked in your shoes. As former international students ourselves, we deeply understand the complexities and concerns of moving to a new country for education. We've experienced the hurdles and triumphs, and this firsthand knowledge fuels our commitment to making your journey smoother.</p>
                        </li>
                        <li className="my-5">
                            <p><span className="font-semibold">Trust and Safety:</span> Your well-being is our top priority. We ensure that every accommodation option we offer is thoroughly vetted for safety, reliability, and comfort. With Landing, you're not just finding a place to stay; you're finding a secure and welcoming home away from home.</p>
                        </li>
                        <li className="my-5">
                            <p><span className="font-semibold">Personalized Accommodation Solutions:</span> We believe that every student is unique, and so are their housing needs. Our team works tirelessly to provide personalized accommodation options that align with your preferences, budget, and lifestyle. Whether you're looking for a quiet study environment or a vibrant community experience, we've got you covered.</p>
                        </li>
                        <li className="my-5">
                            <p><span className="font-semibold">100% Money-Back Guarantee Against Scams:</span> We stand firmly against fraudulent practices. To safeguard your interests, Landing offers a 100% money-back guarantee in the rare event of a scam. We're committed to ensuring that your journey to the US is as smooth and secure as possible.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SectionWhyLanding;