// PersonalInformationSection.jsx

import {
  PersonalDetailsModel,
  PhoneNumberModel,
} from "data/models/rental-application/personal-details-model";
import React, { ChangeEvent, useState } from "react";
import Input from "shared/Input/Input";
import { SectionProps } from "./RentalApplicationForm";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DatePicker from "shared/Input/DatePicker";
import PhoneInput from "shared/Input/PhoneInput";

const PersonalInformationSection: React.FC<
  SectionProps<PersonalDetailsModel>
> = ({ sectionDetails, setSectionDetails, handleBack, handleNext }) => {
  const [validationErrorMap, setValidatorErrorMap] = useState<
    Map<string, string[]>
  >(sectionDetails.getValidatonErrorMap());
  const handleInputChange = (field: keyof PersonalDetailsModel, value: any) => {
    setSectionDetails(
      new PersonalDetailsModel({
        ...sectionDetails,
        [field]: value,
      })
    );
  };

  const onNextClick = () => {
    if (sectionDetails.isValid()) {
      handleNext();
    } else {
      setValidatorErrorMap(sectionDetails.getValidatonErrorMap());
    }
  };

  return (
    <div className="">
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <Input
            type="text"
            label="First Name"
            id="firstName"
            name="firstName"
            isRequired={true}
            error={
              validationErrorMap.get("firstName")?.length
                ? validationErrorMap.get("firstName")?.[0]
                : undefined
            }
            className="mt-1 p-2 w-80"
            placeholder="Enter your first name"
            value={sectionDetails.firstName || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange("firstName", e.target.value)
            }
          />
        </div>

        <div className="mb-6 lg:col-span-1">
          <Input
            type="text"
            label="Last Name"
            id="lastName"
            name="lastName"
            isRequired={true}
            error={
              validationErrorMap.get("lastName")?.length
                ? validationErrorMap.get("lastName")?.[0]
                : undefined
            }
            className="mt-1 p-2 w-80"
            placeholder="Enter your last name"
            value={sectionDetails.lastName || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange("lastName", e.target.value)
            }
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        {/* <div className="mb-6 lg:col-span-1">
          <DatePicker
            label="Date of Birth"
            name="dateOfBirth"
            isRequired={true}
            error={
              validationErrorMap.get("email")?.length
                ? validationErrorMap.get("email")?.[0]
                : undefined
            }
            className="mt-1 p-2 w-80"
            onChange={(date: Date | null, e) =>
              handleInputChange("email", date?.toDateString()!)
            }
          />
        </div> */}
        <div className="mb-6 lg:col-span-1">
          <Input
            type="text"
            label="Email"
            id="email"
            name="email"
            isRequired={true}
            error={
              validationErrorMap.get("email")?.length
                ? validationErrorMap.get("email")?.[0]
                : undefined
            }
            className="mt-1 p-2 w-80"
            placeholder="Enter your email"
            value={sectionDetails.email || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange("email", e.target.value)
            }
          />
        </div>

        <div className="mb-6 lg:col-span-1">
          <PhoneInput
            label="Contact"
            id="contact"
            name="contact"
            className="mt-1 p-2 w-full"
            isRequired={true}
            error={
              validationErrorMap.get("phoneNumber")?.length
                ? validationErrorMap.get("phoneNumber")?.[0]
                : undefined
            }
            countryCode={
              sectionDetails?.phoneNumber!.countryCode
                ? sectionDetails.phoneNumber?.countryCode
                : ""
            }
            phoneNumber={
              sectionDetails?.phoneNumber!.number
                ? sectionDetails.phoneNumber?.number
                : ""
            }
            onPhoneNumberChange={function (
              phoneNumber: string,
              countryCode: string
            ): void {
              handleInputChange(
                "phoneNumber",
                new PhoneNumberModel(countryCode, phoneNumber)
              );
            }}
          />
          {/* <PhoneInput
            className="mt-1 p-2 w-80"
            label="Contact"
            countryCode=""
            phoneNumber=""
            onPhoneNumberChange={() => {}}
          ></PhoneInput> */}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <label
            htmlFor="socialSecurityNumber"
            className="block text-sm font-medium text-gray-600"
          >
            Social Security Number
          </label>
          <Input
            type="text"
            id="socialSecurityNumber"
            name="socialSecurityNumber"
            className="mt-1 p-2 w-80"
            placeholder="Enter your social security number"
            value={sectionDetails.socialSecurityNumber || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange("socialSecurityNumber", e.target.value)
            }
          />
        </div>
      </div>
      <hr className="my-5"></hr>
      <div className="flex justify-around">
        <ButtonPrimary onClick={onNextClick}>Save & Continue</ButtonPrimary>
      </div>
    </div>
  );
};

export default PersonalInformationSection;
