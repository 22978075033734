export const formatBedsDisplay = (beds?: number) => {
  if (beds === null || beds === undefined) {
    return "N/A";
  }
  if (beds === 0) {
    return "Studio";
  } else if (beds === 1) {
    return "1 Bed";
  } else {
    return `${beds} Beds`;
  }
};

export const formatBathsDisplay = (baths?: number) => {
  if (baths === null || baths === undefined) {
    return "N/A";
  }
  if (baths <= 1) {
    return `${baths} Bath`;
  } else {
    return `${baths} Baths`;
  }
};
