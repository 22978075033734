import { plainToClass } from "class-transformer";
import { HttpError } from "data/models/errors/http-error";
import { Property, PropertyPaginatedResponse } from "data/models/property";
import { ApiService } from "services/api.service";

class ListingRepository {
  private apiService: ApiService;
  private static instance: ListingRepository | null = null;
  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  public static getInstance(apiService?: ApiService): ListingRepository {
    if (!ListingRepository.instance) {
      if (!apiService) {
        throw new Error(
          "ListingRepository.getInstance: apiService cannot be empty"
        );
      }
      ListingRepository.instance = new ListingRepository(apiService!);
    }
    return ListingRepository.instance;
  }

  async getPaginatedProperties(
    queryParams?: string
  ): Promise<PropertyPaginatedResponse> {
    try {
      let url = `${process.env.REACT_APP_LANDING_PUBLIC_API_URL}/v2/listings`;

      if (queryParams) {
        url += `/?${queryParams}`;
      }

      const jsonResponse = await this.apiService.get(url);

      const propertyPaginatedResponse = plainToClass(
        PropertyPaginatedResponse,
        jsonResponse
      );
      return propertyPaginatedResponse;
    } catch (error) {
      const httpError = error as HttpError;
      throw httpError;
    }
  }

  async getPropertyById(propertyId: string): Promise<Property> {
    try {
      const jsonResponse = await this.apiService.get(
        `${process.env.REACT_APP_LANDING_PUBLIC_API_URL}/v2/listings/${propertyId}`
      );

      const listing = plainToClass(Property, jsonResponse);

      return listing;
    } catch (error) {
      const httpError = error as HttpError;
      throw httpError;
    }
  }

}

export default ListingRepository;
