import { startsWith } from "lodash";
import { RequiredValidator } from "../form-validators/required-validator";
import { Address } from "../property";
import { PersonalDetailsModel } from "./personal-details-model";
import { RentalApplicationStepDataModel } from "./rental-application-step-model";

export class ReferencesModel extends RentalApplicationStepDataModel {
  id?: string;
  personalReference?: PersonalDetailsModel = new PersonalDetailsModel();
  emergencyReference?: PersonalDetailsModel = new PersonalDetailsModel();
  creditReference?: PersonalDetailsModel = new PersonalDetailsModel();
  isEmergencyRefSameAsPersonal?: boolean = true;
  isCreditRefSameAsPersonal?: boolean = true;

  constructor(data?: Partial<ReferencesModel>) {
    super();
    this.createValidationMap();
    if (data) {
      Object.assign(this, data);
    }
  }

  createValidationMap() {
    this.validationMap = new Map();
    for (const key of [
      "personalReference",
      "creditReference",
      "emergencyReference",
    ]) {
      this.validationMap.set(`${key}FullName`, {
        validators: [new RequiredValidator()],
        getValue: (currentModel: ReferencesModel) => {
          return (currentModel[
            key as keyof ReferencesModel
          ] as PersonalDetailsModel)!.firstName;
        },

        errors: [],
      });
      this.validationMap.set(`${key}Contact`, {
        validators: [new RequiredValidator()],
        getValue: (currentModel: ReferencesModel) => {
          const phoneNumberModel = (currentModel[
            key as keyof ReferencesModel
          ] as PersonalDetailsModel)!.phoneNumber;
          return phoneNumberModel?.countryCode &&
            phoneNumberModel.countryCode &&
            phoneNumberModel.number
            ? phoneNumberModel.number
            : "";
        },
        errors: [],
      });

      for (const entry of ["Line1", "City", "State", "Zip", "Country"]) {
        this.validationMap.set(`${key}Address${entry}`, {
          validators: [new RequiredValidator()],
          getValue: (currentModel: ReferencesModel) =>
            (currentModel[
              key as keyof ReferencesModel
            ] as PersonalDetailsModel)!.address![
              entry.toLowerCase() as keyof Address
            ]!,

          errors: [],
        });
      }
    }
  }

  static get key() {
    return "references";
  }

  validate(): void {
    Array.from(this.validationMap.keys()).forEach((key) => {
      this.validationMap.get(key)!.errors = [];
      this.validationMap.get(key)!.validators.forEach((validator: any) => {
        if (key.startsWith("emergency") && this.isEmergencyRefSameAsPersonal) {
          return;
        }
        if (key.startsWith("credit") && this.isCreditRefSameAsPersonal) {
          return;
        }
        const error = validator.validate(
          this.validationMap.get(key).getValue(this)
        );
        if (error) {
          this.validationMap.get(key)!.errors.push(error);
        }
      });
    });
  }

  toRentalApplicationDocumentJson(data: any) {
    data.personal_reference_name = this.personalReference!.firstName;
    data.personal_reference_complete_address =
      this.personalReference!.address != null
        ? this.personalReference!.address!.convertToString()
        : "";
    data.personal_reference_phone =
      this.personalReference!.phoneNumber != null
        ? this.personalReference!.phoneNumber.toSerialized()
        : "";

    if (this.isEmergencyRefSameAsPersonal!) {
      data.emergency_name = this.personalReference!.firstName;
      data.emergency_complete_address =
        this.personalReference!.address != null
          ? this.personalReference!.address!.convertToString()
          : "";
      data.emergency_phone =
        this.personalReference!.phoneNumber != null
          ? this.personalReference!.phoneNumber.toSerialized()
          : "";
    } else if (this.emergencyReference != null) {
      data.emergency_name = this.emergencyReference!.firstName;
      data.emergency_complete_address =
        this.emergencyReference!.address != null
          ? this.emergencyReference!.address!.convertToString()
          : "";
      data.emergency_phone =
        this.emergencyReference!.phoneNumber != null
          ? this.emergencyReference!.phoneNumber.toSerialized()
          : "";
    }

    if (this.isCreditRefSameAsPersonal!) {
      data.credit_reference_name = this.personalReference!.firstName;
      data.credit_reference_complete_address =
        this.personalReference!.address != null
          ? this.personalReference!.address!.convertToString()
          : "";
      data.credit_reference_phone =
        this.personalReference!.phoneNumber != null
          ? this.personalReference!.phoneNumber.toSerialized()
          : "";
    } else if (this.creditReference != null) {
      data.credit_reference_name = this.creditReference!.firstName;
      data.credit_reference_complete_address =
        this.creditReference!.address != null
          ? this.creditReference!.address!.convertToString()
          : "";
      data.credit_reference_phone =
        this.creditReference!.phoneNumber != null
          ? this.creditReference!.phoneNumber.toSerialized()
          : "";
    }
    return data;
  }
}

export enum Occupation {
  EMPLOYED,
  STUDENT,
  UNEMPLOYED,
  NONE,
}
