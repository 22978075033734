import { FC } from "react";
import privacyPolicyCode from "./PrivacyPolicyHtmlCode";

export interface PrivacyPolicyProps {
  className?: string;
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = ({ className = "" }) => {
  return (
    <div
      className="m-16"
      dangerouslySetInnerHTML={{ __html: privacyPolicyCode }}
    />
  );
};

export default PrivacyPolicy;
