import React, { FC, useState } from "react";
import { FaSearch } from "react-icons/fa";
import UniversityModal from "./UniversityModal";
import MoveInDateModal from "./MoveInDateModal";
import BedsModal from "./BedsModal";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useListingSearchParams } from "providers/SearchParamsProvider";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

interface SearchBarProps {
  onSearch: (searchParams: SearchParams) => void;
}

export interface SearchParams {
  university: string | null;
  city: string | null;
  beds: string | null;
  useExactMatchForBeds: string | null;
  moveInDate: string | null;
  baths: string | null;
  minRent: string | null;
  maxRent: string | null;
  sortBy: string | "priceAsc";
  neighborhood: string | null;
  address: string | null;
  [key: string]: string | null;
}

const SearchBar: FC<SearchBarProps> = ({ onSearch }) => {
  const { searchParams, setSearchParams } = useListingSearchParams();

  const [isUniversityModalOpen, setIsUniversityModalOpen] = useState(false);
  const [isBedsModalOpen, setIsBedsModalOpen] = useState(false);
  const [isMoveInDateModalOpen, setIsMoveInDateModalOpen] = useState(false);

  const [currentSearchParams, setCurrentSearchParams] = useState<SearchParams>({
    ...searchParams,
  });

  // New state for tracking the active category
  const [activeCategory, setActiveCategory] = useState("");
  const navigate = useNavigate();

  const searchListings = async (newSearchParams: SearchParams) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      ...newSearchParams,
    }));

    navigate({
      pathname: "/search-results",
      search: `?${createSearchParams({
        city: newSearchParams?.city!,
        university: newSearchParams?.university!,
        beds: newSearchParams?.beds!,
        moveInDate: newSearchParams?.moveInDate!,
      })}`,
    });
  };

  // Function to handle the selection of a category
  const handleCategorySelect = (category: string) => {
    setActiveCategory(category);
  };

  return (
    <div>
      <div className="hidden lg:block shadow-lg rounded-full overflow-hidden bg-white dark:bg-neutral-900  border border-gray-300 mb-2">
        <div className="flex justify-between">
          <div
            className={`cursor-pointer py-3 px-4 mr-5 ${activeCategory === "university"
              ? "text-primary-green font-semibold"
              : "text-neutral-700 dark:text-neutral-200"
              }`}
            onClick={() => {
              handleCategorySelect("university");
              setIsUniversityModalOpen(true);
            }}
          >
            University
          </div>

          <div
            className={`cursor-pointer py-3 px-4 mr-5 ${activeCategory === "beds"
              ? "text-primary-green font-semibold"
              : "text-neutral-700 dark:text-neutral-200"
              }`}
            onClick={() => {
              handleCategorySelect("beds");
              setIsBedsModalOpen(true);
            }}
          >
            Beds
          </div>
          <div
            className={`cursor-pointer py-3 px-4 mr-5 ${activeCategory === "moveInDate"
              ? "text-primary-green font-semibold"
              : "text-neutral-700 dark:text-neutral-200"
              }`}
            onClick={() => {
              handleCategorySelect("moveInDate");
              setIsMoveInDateModalOpen(true);
            }}
          >
            Move-in Date
          </div>

          <div
            className="search-icon bg-primary-green text-white px-6 flex items-center justify-center rounded-full cursor-pointer"
            onClick={() => {
              setSearchParams({
                city: "Boston",
                university: null,
                beds: null,
                useExactMatchForBeds: null,
                baths: null,
                moveInDate: null,
                maxRent: "10000",
                minRent: "0",
                sortBy: "priceAsc",
                neighborhood: null,
                address: null,
              });
              navigate("/search-results");
            }}
          >
            <FaSearch />
          </div>
        </div>
      </div>

      <button
        onClick={() => {
          setActiveCategory("university");
          setIsUniversityModalOpen(true);
        }}
        className="relative flex lg:hidden items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 pr-11 rounded-full shadow-lg"
      >
        <MagnifyingGlassIcon className="flex-shrink-0 w-5 h-5" />

        <div className="ml-3 flex-1 text-left overflow-hidden">
          <span className="block font-medium text-sm">Search</span>
          <div className="flex mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 ">
            <span className="line-clamp-1 mr-3">University</span>
            <span className="line-clamp-1 mr-3">• </span>
            <span className="line-clamp-1 mr-3"> Beds </span>
            <span className="line-clamp-1 mr-3">• </span>
            <span className="line-clamp-1 mr-3">Date</span>
          </div>
        </div>

        <span className="absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300">
          <svg
            viewBox="0 0 16 16"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            className="block w-4 h-4"
            fill="currentColor"
          >
            <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
          </svg>
        </span>
      </button>

      {/* Render different content based on the active category */}
      {activeCategory === "university" && (
        <UniversityModal
          isOpen={isUniversityModalOpen}
          onClose={() => setIsUniversityModalOpen(false)}
          selectedUniversity={searchParams?.university!}
          onSelectUniversity={(university) => {
            setCurrentSearchParams({
              ...currentSearchParams,
              university,
            } as SearchParams);
            setActiveCategory("beds");
            setIsBedsModalOpen(true);
          }}
        />
      )}

      {activeCategory === "beds" && (
        <BedsModal
          isOpen={isBedsModalOpen}
          onClose={() => setIsBedsModalOpen(false)}
          onSelectBeds={(beds) => {
            setCurrentSearchParams({
              ...currentSearchParams,
              beds,
            } as SearchParams);

            setActiveCategory("moveInDate");
            setIsMoveInDateModalOpen(true);
          }}
        />
      )}

      {activeCategory === "moveInDate" && (
        <MoveInDateModal
          isOpen={isMoveInDateModalOpen}
          onClose={() => setIsMoveInDateModalOpen(false)}
          onSelectMoveInDate={(moveInDate) => {
            setCurrentSearchParams({
              ...currentSearchParams,
              moveInDate: moveInDate,
            } as SearchParams);
            setActiveCategory("");
            setIsMoveInDateModalOpen(false);

            searchListings({
              ...currentSearchParams,
              moveInDate: moveInDate,
            });
          }}
        />
      )}
    </div>
  );
};

export default SearchBar;
