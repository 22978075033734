import React, { FC } from "react";

export interface SectionOurAchievementsProps {
  className?: string;
  data?: {
    id: number;
    img: string;
    heading: string;
    desc: string;
  }[];
}

const SectionOurAchievements: FC<SectionOurAchievementsProps> = ({
  className = "",
  data = [],
}) => {
  return (
    <div
      className={`nc-SectionOurAchievements ${className}`}
      data-nc-id="SectionOurAchievements"
    >
      <div className="dark:bg-black dark:bg-opacity-20 bg-primary-50 relative grid md:grid-cols-3 md:p-16 p-10 rounded-3xl">
        {data.map((item) => (
          <div key={item.id} className="my-5 md:my-0">
            <div className="">
              <div className="flex justify-center items-center align-middle">
                <img className="md:w-16 md:h-16 w-12 h-12" src={item.img} alt="" />
              </div>
              <div className="text-center mt-6">
                <h3 className="text-3xl font-semibold">
                  <span className="text-primary-green">{item.heading}</span>
                </h3>
                <span className="block mt-1 text-lg">
                  {item.desc}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionOurAchievements;
