import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  SelectHTMLAttributes,
  useEffect,
  useState,
} from "react";

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  id: string;
  className?: string;
  sizeClass?: string;
  fontClass?: string;
  options?: string[];
  rounded?: string;
  isRequired?: boolean;
  label?: string;
  error?: string;
  onChange?: ChangeEventHandler;
}

const Select: FC<SelectProps> = ({
  id,
  className = "",
  label = "",
  isRequired = false,
  sizeClass = "h-10 px-4",
  fontClass = "text-sm font-normal",
  rounded = "rounded-full",
  onChange = () => {},
  error,
  children,
  options,
  ...args
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(error!);

  useEffect(() => {
    // Update errorMessage when parentError changes
    setErrorMessage(error);
  }, [error]);

  const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;

    if (errorMessage && value) {
      setErrorMessage("");
    }

    onChange && onChange(event);
  };
  const onBlurHandler = (event: any) => {
    const { value } = event.target;
    let error: string | null = null;

    if (isRequired && !value) {
      error = "Required Field";
      setErrorMessage(error);
    }
  };
  return (
    <>
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-gray-600 mb-1"
        >
          {label} {isRequired ? <span className="text-red-500">*</span> : null}
          {errorMessage ? (
            <span className="text-red-500"> Required field</span>
          ) : null}
        </label>
      )}

      <select
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        className={`block w-full border-neutral-200 focus:border-primary-green focus:ring focus:ring-primary-green focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className} ${
          errorMessage
            ? "border-red-500 focus:border-red-500 focus:ring-red-500 "
            : ""
        }`}
        {...args}
      >
        {options &&
          options.map((option, index) => (
            <option
              className="p-2 bg-white"
              key={option + index}
              value={option}
            >
              {option}
            </option>
          ))}
      </select>
    </>
  );
};

export default Select;
