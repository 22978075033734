// UniversityModal.jsx
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
import northeasternuniversity from "./../../images/universities/northeasternuniversity.png";
import bostonuniversity from "./../../images/universities/bostonuniversity.png";
import harvarduniversity from "./../../images/universities/harvarduniversity.png";
import mitimage from "./../../images/universities/mitimage.png";
import umass from "images/universities/umassboston.jpg"
import { Address } from "data/models/property";

interface University {
  name: string;
  image: string;
  displayName: string;
  address: Address;
}

interface UniversityModalProps {
  isOpen: boolean;
  selectedUniversity: string | null;
  onClose: () => void;
  onSelectUniversity: (university: string) => void;
}

export const universities: Record<string, University[]> = {
  Boston: [
    {
      name: "Northeastern University",
      displayName: "Northeastern University",
      image: northeasternuniversity,
      address: new Address({
        lat: "42.3399", lon: "-71.0899",
       
      }),
    },

    {
      name: "Boston University",
      displayName: "Boston University",
      image: bostonuniversity,
      address: new Address({
        lat: "42.3505", lon: "-71.1054",
        
      }),
    },
    {
      name: "Harvard University",
      displayName: "Harvard University",
      image: harvarduniversity,
      address: new Address({
        lat: "42.3744", lon: "-71.1182",
       
      }),
    },

    {
      name: "Massachusetts Institute of Technology",
      displayName: "MIT",
      image: mitimage,
      address: new Address({
        lat: "42.3601", lon: "-71.0942",
      
      }),
    },
    {
      name: "Umass Boston",
      displayName: "Umass Boston",
      image: umass,
      address: new Address({
        lat: "42.3142", lon: "-71.0420",
        
      }),
    },
  ],
  //   "New Jersey": [
  //     {
  //       name: "Harvard University",
  //       image: harvarduniversity,
  //       address: { lat: "42.3399", lon: "-71.0899" },
  //     },

  //     {
  //       name: "MIT",
  //       image: mitimage,
  //       address: { lat: "42.3399", lon: "-71.0899" },
  //     },
  //     {
  //       name: "UMass, Boston",
  //       image: bostonuniversity,
  //       address: { lat: "42.3399", lon: "-71.0899" },
  //     },
  //   ],
};

const UniversityModal: React.FC<UniversityModalProps> = ({
  isOpen,
  onClose,
  selectedUniversity,
  onSelectUniversity,
}) => {
  const [selectedCity, setSelectedCity] = useState("Boston");

  useEffect(() => {
    setSelectedCity("Boston");
  }, []);

  const handleUniversitySelect = (university: string) => {
    onSelectUniversity(university);
    onClose();
  };

  const handleCitySelect = (city: string) => {
    setSelectedCity(city);
  };

  const handleSkip = () => {
    console.log("Skip");
  };

  const handleNext = () => {
    console.log("Next");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById("root") || undefined}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          position: "absolute",
          maxHeight: "675px",
          maxWidth: "800px",
          margin: "auto",

          padding: "20px",
          background: "white",
          border: "1px solid #ccc",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
        },
      }}
      contentLabel="University Modal"
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-gray-700">
          Select your university
        </h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <FaTimes />
        </button>
      </div>
      <hr className="border-t border-gray-300 mb-4" />
      <div className="mb-4">
        <button
          onClick={() => handleCitySelect("Boston")}
          className={`rounded-full px-4 py-2 mr-2 ${selectedCity === "Boston"
            ? "bg-primary-green text-white"
            : "bg-transparent text-gray-500"
            }`}
        >
          Boston
        </button>
        {/* <button
          onClick={() => handleCitySelect("NewJersey")}
          className={`rounded-full px-4 py-2 ${
            selectedCity === "NewJersey"
              ? "bg-primary-green text-white"
              : "bg-transparent text-gray-500"
          }`}
        >
          New Jersey
        </button> */}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {universities[selectedCity].map((university, index) => (
          <div key={index} className="text-center">
            <img
              src={university.image}
              alt={university.name}
              className="rounded-lg mb-2"
              style={{ width: "100%", maxWidth: "300px", height: "200px" }}
              onClick={() => handleUniversitySelect(university.name)}
            />
            <p
              className="text-sm text-center"
              style={{ width: "100%", maxWidth: "300px" }}
            >
              {university.name}
            </p>
          </div>
        ))}
      </div>

      {/* <div className="flex-grow"></div>
      <hr className="border-t border-gray-300 my-4" />
      <div className="flex justify-between mt-4">
        <button
          onClick={handleSkip}
          className="text-sm text-gray-600 hover:text-gray-800"
        >
          Skip
        </button>
        <button
          onClick={handleNext}
          className="text-sm bg-primary-green text-white px-4 py-2 rounded-full hover:bg-opacity-90"
        >
          Next
        </button>
      </div> */}
    </Modal>
  );
};

export default UniversityModal;
