const formatCurrency = (number?: number) => {
    if (!number) {
        return '';
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    return formatter.format(number);

};

export default formatCurrency;