import React, { FC } from "react";
import network from "images/graphics/networks.png"
import process from "images/graphics/process.png"
import approvals from "images/graphics/approvals.png"

export interface SectionInsurentAboutProps {
    className?: string;
}

const SectionInsurentAbout: FC<SectionInsurentAboutProps> = ({ className = "" }) => {

    const data =
        [
            {
                id: 1,
                img: network,
                heading: "2000+ Apartments in Network",
                desc: "Across Boston, Cambridge, Waltham, Burlington, Lowell, Worchester, Framingham, and Newton",
            },
            {
                id: 2,
                img: approvals,
                heading: "More Approvals",
                desc: "Providing international students, recent graduates, and international employed persons with more approval opportunities",
            },
            {
                id: 3,
                img: process,
                heading: "A Faster Process",
                desc: "Our lease is fully secured by Argonaut Insurance Company, giving landlords a peace of mind, and approving you faster.",
            },
        ]

    return (
        <div
            className={`nc-SectionInsurentAbout relative ${className}`}
            data-nc-id="SectionInsurentAbout"
        >
            <div className="bg-primary-50 md:p-12 p-8 grid md:grid-cols-3 space-x-2 rounded-3xl">
                {
                    data.map((item, index) =>
                        <div className="md:p-5 p-3" key={index}>
                            <div className="flex justify-center my-5">
                                <img className="object-contain w-14" src={item.img} alt="" />
                            </div>
                            <div className="text-center my-5">
                                <h2 className="font-semibold text-xl">{item.heading}</h2>
                            </div>
                            <div className="text-center my-5">
                                <p className="text-sm">{item.desc}</p>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default SectionInsurentAbout;