import { PhoneValidator } from "../form-validators/phone-validator";
import { RequiredValidator } from "../form-validators/required-validator";
import { Address } from "../property";
import { RentalApplicationStepDataModel } from "./rental-application-step-model";

export class PersonalDetailsModel extends RentalApplicationStepDataModel {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: string;
  phoneNumber?: PhoneNumberModel;
  address?: Address = new Address();
  socialSecurityNumber?: string;

  constructor(data: Partial<PersonalDetailsModel> = {}) {
    super();
    this.validationMap = new Map(
      Object.entries({
        firstName: {
          validators: [new RequiredValidator()],
          errors: [],
          getValue: (currentModel: PersonalDetailsModel) => {
            return currentModel.firstName;
          },
        },
        lastName: {
          validators: [new RequiredValidator()],
          errors: [],
          getValue: (currentModel: PersonalDetailsModel) => {
            return currentModel.lastName;
          },
        },
        email: {
          validators: [new RequiredValidator()],
          errors: [],
          getValue: (currentModel: PersonalDetailsModel) => {
            return currentModel.email;
          },
        },
        phoneNumber: {
          validators: [new RequiredValidator(), new PhoneValidator()],
          errors: [],
          getValue: (currentModel: PersonalDetailsModel) => {
            return currentModel.phoneNumber?.countryCode &&
              currentModel.phoneNumber.countryCode &&
              currentModel.phoneNumber.number
              ? currentModel.phoneNumber.number
              : "";
          },
        },
        socialSecurityNumber: {
          validators: [],
          errors: [],
          getValue: (currentModel: PersonalDetailsModel) => {
            return currentModel.socialSecurityNumber;
          },
        },
        address: {
          validators: [],
          errors: [],
          getValue: (currentModel: PersonalDetailsModel) => {
            return currentModel.address;
          },
        },
      })
    );

    this.firstName = data.firstName || "";
    this.lastName = data.lastName || "";
    this.email = data.email || "";
    this.phoneNumber = data.phoneNumber || new PhoneNumberModel();
    this.socialSecurityNumber = data.socialSecurityNumber || "";
    this.address = data.address || new Address();
  }

  toRentalApplicationDocumentJson(data: any) {
    data.name = this.firstName! + " " + this.lastName!;
    data.phone = this.phoneNumber ? this.phoneNumber.toSerialized() : "";
    data.email = this.email!;
    data.social_security = this.socialSecurityNumber || "";
    return data;
  }

  static get key() {
    return "personalDetails";
  }
}

export class PhoneNumberModel {
  countryCode?: string;
  number?: string;
  static delimiter = "-";

  constructor(countryCode?: string, number?: string) {
    this.countryCode = countryCode;
    this.number = number;
  }

  get completeNumber(): string {
    return this.countryCode! && this.number
      ? this.countryCode! + this.number!
      : "";
  }

  toSerialized(): string {
    return this.number && this.number.length > 0
      ? this.countryCode + PhoneNumberModel.delimiter + this.number
      : "";
  }

  static fromSerialized(serializedNumber: string): PhoneNumberModel {
    const list = serializedNumber.split(PhoneNumberModel.delimiter);

    const countryCode = list[0];
    const number = list[1];

    return new PhoneNumberModel(countryCode, number);
  }
}
