import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Heading from "components/Heading/Heading";
import React, { FC } from "react";

export interface SectionInsurentApartmentsInOurNetworkProps {
    className?: string;
}

const SectionInsurentApartmentsInOurNetwork: FC<SectionInsurentApartmentsInOurNetworkProps> = ({ className = "" }) => {

    const cosingerData = [
        {
            streetaddress: "100 Linden Street",
            landlord: "Jing Zhao",
            city: "Allston",
            state: "MA",
            zip: "02134"
        },
        {
            streetaddress: "100 Pier 4 Boulevard",
            landlord: "UDR, Inc.",
            city: "Boston",
            state: "MA",
            zip: "02210"
        },
        {
            streetaddress: "110 Beverly Street",
            landlord: "Windsor Property Management Co.",
            city: "Boston",
            state: "MA",
            zip: "02114"
        },
        {
            streetaddress: "1203 Boylston Street",
            landlord: "Delphi Properties, LLC",
            city: "Boston",
            state: "MA",
            zip: "02215"
        },
        {
            streetaddress: "1209 Boylston Street",
            landlord: "Delphi Properties, LLC",
            city: "Boston",
            state: "MA",
            zip: "02215"
        },
        {
            streetaddress: "135 Clarendon Street",
            landlord: "Related Management",
            city: "Boston",
            state: "MA",
            zip: "02116"
        },
        {
            streetaddress: "143 Franklin Avenue",
            landlord: "AdoEma Properties, LLC",
            city: "Chelsea",
            state: "MA",
            zip: "02150"
        },
        {
            streetaddress: "1480-1486 Tremont Street",
            landlord: "Wingate Companies",
            city: "Boston",
            state: "MA",
            zip: "02120"
        },
        {
            streetaddress: "1575 Tremont Street",
            landlord: "Wingate Companies",
            city: "Boston",
            state: "MA",
            zip: "02120"
        },
        {
            streetaddress: "1713 Commonwealth Avenue",
            landlord: "Delphi Properties, LLC",
            city: "Brighton",
            state: "MA",
            zip: "02135"
        },
        {
            streetaddress: "1715 Commonwealth Avenue",
            landlord: "Delphi Properties, LLC",
            city: "Brighton",
            state: "MA",
            zip: "02135"
        },
        {
            streetaddress: "1872 Commonwealth Aven",
            landlord: "Anne Peck",
            city: "Boston",
            state: "MA",
            zip: "02135"
        },
        {
            streetaddress: "199 Massachusetts Avenue",
            landlord: "Boyd/Smith Inc",
            city: "Boston",
            state: "MA",
            zip: "02115"
        },
        {
            streetaddress: "2 Winship Place",
            landlord: "Jinwei Jiang and Yiding Yan",
            city: "Boston",
            state: "MA",
            zip: "02135"
        },
        {
            streetaddress: "221 Massachusetts Avenue",
            landlord: "Boyd/Smith Inc.",
            city: "Boston",
            state: "MA",
            zip: "02115"
        },
        {
            streetaddress: "24 Peterborough Street",
            landlord: "Arnold A. Kraft",
            city: "Boston",
            state: "MA",
            zip: "02115"
        },
        {
            streetaddress: "268 Revere Beach Parkway",
            landlord: "AdoEma Properties, LLC",
            city: "Chelsea",
            state: "MA",
            zip: "02150"
        },
        {
            streetaddress: "3 Lothian Road",
            landlord: "Delphi Properties, LLC",
            city: "Brighton",
            state: "MA",
            zip: "02135"
        },
        {
            streetaddress: "32 Garrison Street",
            landlord: "UDR, Inc.",
            city: "Boston",
            state: "MA",
            zip: "02116"
        },
        {
            streetaddress: "350 Washington Avenue",
            landlord: "AdoEma Properties, LLC",
            city: "Chelsea",
            state: "MA",
            zip: "02150"
        },
        {
            streetaddress: "360 Riverway",
            landlord: "Investments Limited",
            city: "Boston",
            state: "MA",
            zip: "02115"
        },
        {
            streetaddress: "397 Beacon Street",
            landlord: "GPD Management LLC",
            city: "Boston",
            state: "MA",
            zip: "02116"
        },
        {
            streetaddress: "40 Brock Street",
            landlord: "Eric Boyer",
            city: "Brighton",
            state: "MA",
            zip: "02135"
        },
        {
            streetaddress: "43-47 Williams Street",
            landlord: "AdoEma Properties, LLC",
            city: "Chelsea",
            state: "MA",
            zip: "02150"
        },
        {
            streetaddress: "464 Commonwealth Avenue",
            landlord: "Holt Family Realty Trust",
            city: "Boston",
            state: "MA",
            zip: "02215"
        },
        {
            streetaddress: "5 Lothian Road",
            landlord: "Delphi Properties, LLC",
            city: "Brighton",
            state: "MA",
            zip: "02135"
        },
        {
            streetaddress: "554 Massachusetts Avenue",
            landlord: "Delphi Properties, LLC",
            city: "Boston",
            state: "MA",
            zip: "02118"
        },
        {
            streetaddress: "660 Washington Street",
            landlord: "Equity Residential",
            city: "Boston",
            state: "MA",
            zip: "02111"
        },
        {
            streetaddress: "88-90 Willow Street",
            landlord: "AdoEma Properties, LLC",
            city: "Chelsea",
            state: "MA",
            zip: "02150"
        },
    ]

    return (
        <div
            className={`nc-SectionInsurentApartmentsInOurNetwork relative ${className}`}
            data-nc-id="SectionInsurentApartmentsInOurNetwork"
        >
            <Heading isCenter desc="">
                Browse Apartments we <span className="text-primary-green">Cover</span>
            </Heading>
            <div>
                {/* <div className="grid md:grid-cols-2 gap-10">
                    <div>
                        <div className="mb-1">
                            <label htmlFor="state">State</label>
                        </div>
                        <select id="state" className="rounded-2xl md:px-4 md:py-2">
                            <option value="allselected">All Selected</option>
                            <option value="boston">Boston</option>
                        </select>
                    </div>
                    <div>
                        <div className="mb-1">
                            <label htmlFor="state">Street Address</label>
                        </div>
                        <input className="rounded-2xl" type="text" />
                    </div>
                    <div>
                        <div className="mb-1">
                            <label htmlFor="state">City</label>
                        </div>
                        <input className="rounded-2xl" type="text" />
                    </div>
                    <div>
                        <div className="mb-1">
                            <label htmlFor="state">Zip-Code</label>
                        </div>
                        <input className="rounded-2xl" type="text" />
                    </div>
                </div> */}
                <div className="mt-14">
                    <Paper sx={{ overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 500 }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Street Address</TableCell>
                                        <TableCell>Landlord</TableCell>
                                        <TableCell>City</TableCell>
                                        <TableCell>State</TableCell>
                                        <TableCell>Zip</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        cosingerData.map((item, map) =>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell>{item.streetaddress}</TableCell>
                                                <TableCell>{item.landlord}</TableCell>
                                                <TableCell>{item.city}</TableCell>
                                                <TableCell>{item.state}</TableCell>
                                                <TableCell>{item.zip}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            </div>
        </div>
    )
}

export default SectionInsurentApartmentsInOurNetwork;