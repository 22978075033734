import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
export interface MainSidebarProps {
    className?: string
}

const MainSidebar: FC<MainSidebarProps> = ({ className = "" }) => {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open)
    }
    return (
        <div className={`nc-MainSidebar h-screen fixed ${className}`}>
            <div className={`${open ? "w-60" : "w-16"} duration-300 border h-full bg-white border-neutral-100 shadow p-4`}>
                <div className="mb-40">
                    <span onClick={handleClick} className="cursor-pointer">{open ? <i className="text-2xl md:text-3xl las la-times"></i> : <i className="text-2xl md:text-3xl las la-bars"></i>}</span>
                </div>
                <div className="my-10">
                    <Link
                        className="inline-flex align-middle"
                        to={"/search-results"}
                        // onMouseEnter={() => { setOpen(!open) }}
                        // onMouseLeave={() => { setOpen(!open) }}
                    >
                        <div className="mr-2">
                            <span><i className="text-xl md:text-2xl las la-home"></i></span>
                        </div>
                        <div className={`duration-300 ${!open && "hidden"}`}>
                            <p className="line-clamp-1 font-semibold">Apartment Listings</p>
                        </div>
                    </Link>
                </div>
                {/* <div className="my-10">
                    <Link
                        className="inline-flex align-middle"
                        to={"/applications"}
                        onMouseEnter={() => { setOpen(!open) }}
                        onMouseLeave={() => { setOpen(!open) }}
                    >
                        <div className="mr-2">
                            <span><i className="text-xl md:text-2xl las la-folder-open"></i></span>
                        </div>
                        <div className={`duration-300 ${!open && "hidden"}`}>
                            <p className="line-clamp-1 font-semibold">My Applications</p>
                        </div>
                    </Link>
                </div> */}
                <div className="my-10">
                    <Link
                        className="inline-flex align-middle"
                        to={"/shortlist"}
                        onMouseEnter={() => { setOpen(!open) }}
                        onMouseLeave={() => { setOpen(!open) }}
                    >
                        <div className="mr-2">
                            <span><i className="font-semibold md:text-2xl lar la-bookmark"></i></span>
                        </div>
                        <div className={`duration-300 ${!open && "hidden"}`}>
                            <p className="line-clamp-1 font-semibold">Shortlist</p>
                        </div>
                    </Link>
                </div>
                {/* <div className="my-10">
                    <Link
                        className="inline-flex align-middle"
                        to={"/documents"}
                        onMouseEnter={() => { setOpen(!open) }}
                        onMouseLeave={() => { setOpen(!open) }}
                    >
                        <div className="mr-2">
                            <span><i className="text-xl md:text-2xl las la-folder"></i></span>
                        </div>
                        <div className={`duration-300 ${!open && "hidden"}`}>
                            <p className="line-clamp-1 font-semibold">My Documents</p>
                        </div>
                    </Link>
                </div> */}
            </div>
        </div>
    )
}

export default MainSidebar;