import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import vector1 from "images/graphics/vector1.png"

export interface SectionInsurentHowItWorkProps {
    className?: string;
    data1?: {
        id: number;
        title: string;
        desc: string;
        img: string;
        step: string
        imgDark?: string;
    }[];
    data2?: {
        id: number;
        title: string;
        desc: string;
        img: string;
        step: string
        imgDark?: string;
    }[];
}

const SectionInsurentHowItWork: FC<SectionInsurentHowItWorkProps> = ({
    className = "",
    data1,
    data2,
}) => {
    return (
        <div
            className={`nc-SectionInsurentHowItWork  ${className}`}
            data-nc-id="SectionInsurentHowItWork"
        >
            <Heading isCenter desc="">
                How it works
            </Heading>
            <div className="relative grid md:grid-cols-3 gap-20">
                <img
                    className="hidden md:block absolute inset-x-0 top-10"
                    src={vector1}
                    alt=""
                />
                {data1?.map((item) => (
                    <div
                        key={item.id}
                        className="relative flex flex-col items-center max-w-xs mx-auto"
                    >
                        {item.imgDark ? (
                            <>
                                <NcImage
                                    containerClassName="dark:hidden block mb-8 max-w-[200px] mx-auto"
                                    src={item.img}
                                />
                                <NcImage
                                    containerClassName="hidden dark:block mb-8 max-w-[200px] mx-auto"
                                    src={item.imgDark}
                                />
                            </>
                        ) : (
                            <NcImage
                                containerClassName="mb-8 max-w-[250px] mx-auto"
                                src={item.img}
                            />
                        )}
                        <div className="my-5">
                            <NcImage
                                containerClassName="max-w-[50px] mx-auto"
                                src={item.step}
                            />
                        </div>
                        <div className="text-center">
                            <h3 className="text-xl font-semibold">{item.title}</h3>
                            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                                {item.desc}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
            <div className="relative grid md:grid-cols-2 gap-20 mt-20">
                <img
                    className="hidden md:block absolute inset-x-0 top-10"
                    src={vector1}
                    alt=""
                />
                {data2?.map((item) => (
                    <div
                        key={item.id}
                        className="relative flex flex-col items-center max-w-xs mx-auto"
                    >
                        {item.imgDark ? (
                            <>
                                <NcImage
                                    containerClassName="dark:hidden block mb-8 max-w-[200px] mx-auto"
                                    src={item.img}
                                />
                                <NcImage
                                    containerClassName="hidden dark:block mb-8 max-w-[200px] mx-auto"
                                    src={item.imgDark}
                                />
                            </>
                        ) : (
                            <NcImage
                                containerClassName="mb-8 max-w-[250px] mx-auto"
                                src={item.img}
                            />
                        )}
                        <div className="my-5">
                            <NcImage
                                containerClassName="max-w-[50px] mx-auto"
                                src={item.step}
                            />
                        </div>
                        <div className="text-center">
                            <h3 className="text-xl font-semibold">{item.title}</h3>
                            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                                {item.desc}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SectionInsurentHowItWork;
