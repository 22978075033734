import React, { ChangeEvent, useState } from "react";
import Input from "shared/Input/Input";
import { SectionProps } from "./RentalApplicationForm";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {
  PersonalDetailsModel,
  PhoneNumberModel,
} from "data/models/rental-application/personal-details-model";
import { Checkbox, FormControlLabel } from "@mui/material";
import { ReferencesModel } from "data/models/rental-application/references-model";
import { Address } from "data/models/property";
import PhoneInput from "shared/Input/PhoneInput";

const ReferencesSection: React.FC<SectionProps<ReferencesModel>> = ({
  sectionDetails,
  setSectionDetails,
  handleBack,
  handleNext,
}) => {
  const [validationErrorMap, setValidatorErrorMap] = useState<
    Map<string, string[]>
  >(sectionDetails.getValidatonErrorMap());
  const handleInputChange = (field: keyof ReferencesModel, value: any) => {
    setSectionDetails(
      new ReferencesModel({
        ...sectionDetails,
        [field]: value,
      })
    );
  };

  const onNextClick = () => {
    if (sectionDetails.isValid()) {
      handleNext();
    } else {
      setValidatorErrorMap(sectionDetails.getValidatonErrorMap());
    }
  };

  const renderReferenceFormFields = (
    header: string,
    labelPrefix: string,
    reference: PersonalDetailsModel
  ) => {
    return (
      <>
        <h3 className="my-6">{header}</h3>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="mb-6 lg:col-span-1">
            <Input
              type="text"
              label="Full Name"
              id={labelPrefix + "FullName"}
              name={labelPrefix + "FullName"}
              isRequired={true}
              value={reference?.firstName || ""}
              error={
                validationErrorMap.get(labelPrefix + "FullName")?.length
                  ? validationErrorMap.get(labelPrefix + "FullName")?.[0]
                  : undefined
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(
                  labelPrefix as keyof ReferencesModel,
                  new PersonalDetailsModel({
                    ...reference,
                    firstName: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="mb-6 lg:col-span-1">
            <PhoneInput
              label="Reference contact number"
              id={labelPrefix + "Contact"}
              name={labelPrefix + "Contact"}
              isRequired={true}
              countryCode={reference?.phoneNumber?.countryCode || ""}
              phoneNumber={reference?.phoneNumber?.number || ""}
              error={
                validationErrorMap.get(labelPrefix + "Contact")?.length
                  ? validationErrorMap.get(labelPrefix + "Contact")?.[0]
                  : undefined
              }
              onPhoneNumberChange={(phoneNumber: string, countryCode: string) =>
                handleInputChange(
                  labelPrefix as keyof ReferencesModel,
                  new PersonalDetailsModel({
                    ...reference,
                    phoneNumber: new PhoneNumberModel(countryCode, phoneNumber),
                  })
                )
              }
              className="mt-1 p-2 w-full"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="mb-6 lg:col-span-1">
            <Input
              type="text"
              label="H-no, Street name"
              id={labelPrefix + "Line1"}
              name={labelPrefix + "Line1"}
              isRequired={true}
              value={reference?.address?.line1 || ""}
              error={
                validationErrorMap.get(labelPrefix + "AddressLine1")?.length
                  ? validationErrorMap.get(labelPrefix + "AddressLine1")?.[0]
                  : undefined
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(
                  labelPrefix as keyof ReferencesModel,
                  new PersonalDetailsModel({
                    ...reference,
                    address: new Address({
                      ...reference?.address,
                      line1: e.target.value,
                    }),
                  })
                )
              }
            />
          </div>

          <div className="mb-6 lg:col-span-1">
            <Input
              type="text"
              label="Apartment, suite, etc."
              id={labelPrefix + "Line2"}
              name={labelPrefix + "Line2"}
              isRequired={false}
              value={reference?.address?.line2 || ""}
              error={
                validationErrorMap.get(labelPrefix + "AddressLine2")?.length
                  ? validationErrorMap.get(labelPrefix + "AddressLine2")?.[0]
                  : undefined
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(
                  labelPrefix as keyof ReferencesModel,
                  new PersonalDetailsModel({
                    ...reference,
                    address: new Address({
                      ...reference?.address,
                      line2: e.target.value,
                    }),
                  })
                )
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="mb-6 lg:col-span-1">
            <Input
              type="text"
              label="City"
              id={labelPrefix + "City"}
              name={labelPrefix + "City"}
              isRequired={true}
              value={reference?.address?.city || ""}
              error={
                validationErrorMap.get(labelPrefix + "AddressCity")?.length
                  ? validationErrorMap.get(labelPrefix + "AddressCity")?.[0]
                  : undefined
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(
                  labelPrefix as keyof ReferencesModel,
                  new PersonalDetailsModel({
                    ...reference,
                    address: new Address({
                      ...reference?.address,
                      city: e.target.value,
                    }),
                  })
                )
              }
            />
          </div>

          <div className="mb-6 lg:col-span-1">
            <Input
              type="text"
              label="State"
              id={labelPrefix + "State"}
              name={labelPrefix + "State"}
              isRequired={true}
              value={reference?.address?.state || ""}
              error={
                validationErrorMap.get(labelPrefix + "AddressState")?.length
                  ? validationErrorMap.get(labelPrefix + "AddressState")?.[0]
                  : undefined
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(
                  labelPrefix as keyof ReferencesModel,
                  new PersonalDetailsModel({
                    ...reference,
                    address: new Address({
                      ...reference?.address,
                      state: e.target.value,
                    }),
                  })
                )
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="mb-6 lg:col-span-1">
            <Input
              type="text"
              label="Country"
              id={labelPrefix + "Country"}
              name={labelPrefix + "Country"}
              isRequired={true}
              value={reference?.address?.country || ""}
              error={
                validationErrorMap.get(labelPrefix + "AddressCountry")?.length
                  ? validationErrorMap.get(labelPrefix + "AddressCountry")?.[0]
                  : undefined
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(
                  labelPrefix as keyof ReferencesModel,
                  new PersonalDetailsModel({
                    ...reference,
                    address: new Address({
                      ...reference?.address,
                      country: e.target.value,
                    }),
                  })
                )
              }
            />
          </div>
          <div className="mb-6 lg:col-span-1">
            <Input
              type="text"
              label="Zip/Pin code"
              id={labelPrefix + "Zip"}
              name={labelPrefix + "Zip"}
              isRequired={true}
              value={reference?.address?.zip || ""}
              error={
                validationErrorMap.get(labelPrefix + "AddressZip")?.length
                  ? validationErrorMap.get(labelPrefix + "AddressZip")?.[0]
                  : undefined
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(
                  labelPrefix as keyof ReferencesModel,
                  new PersonalDetailsModel({
                    ...reference,
                    address: new Address({
                      ...reference?.address,
                      zip: e.target.value,
                    }),
                  })
                )
              }
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="">
      {renderReferenceFormFields(
        "Personal Reference",
        "personalReference",
        sectionDetails.personalReference!
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={sectionDetails.isEmergencyRefSameAsPersonal}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(
                "isEmergencyRefSameAsPersonal",
                e.target.checked
              )
            }
          />
        }
        label="Emergency contact is same as above"
      />

      {!sectionDetails.isEmergencyRefSameAsPersonal &&
        renderReferenceFormFields(
          "Emergency Reference",
          "emergencyReference",
          sectionDetails.emergencyReference!
        )}
      <hr className="my-6" />

      <FormControlLabel
        control={
          <Checkbox
            checked={sectionDetails.isCreditRefSameAsPersonal}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange("isCreditRefSameAsPersonal", e.target.checked)
            }
          />
        }
        label="Credit reference is same as above"
      />

      {!sectionDetails.isCreditRefSameAsPersonal &&
        renderReferenceFormFields(
          "Credit Reference",
          "creditReference",
          sectionDetails.creditReference!
        )}

      <hr className="mt-4 mb-8"></hr>
      <div className="flex justify-around">
        <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>

        <ButtonPrimary onClick={onNextClick}>Save & Continue</ButtonPrimary>
      </div>
    </div>
  );
};

export default ReferencesSection;
