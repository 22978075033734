import React from "react";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import { FaTimes, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

interface MoveInDateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectMoveInDate: (date: string | null) => void;
}

const MoveInDateModal: React.FC<MoveInDateModalProps> = ({
  isOpen,
  onClose,
  onSelectMoveInDate,
}) => {
  const handleMoveInDateSelect = (date: Date | null) => {
    onSelectMoveInDate(date ? moment(date).format("YYYYMMDD") : null);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById("root") || undefined}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          position: "absolute",
          height: "400px",
          width: "300px",
          margin: "auto",
          padding: "20px",
          background: "white",
          border: "1px solid #ccc",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
        },
      }}
      contentLabel="Move-In Date Modal"
    >
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-xl font-semibold text-gray-500">
          Select Move-in Date
        </h4>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <FaTimes />
        </button>
      </div>
      <hr className="border-t border-gray-300 mb-4" />
      <div className="flex justify-center">
        <DatePicker
          selected={null} // Set your default selected date here
          onChange={(val) => handleMoveInDateSelect(val)}
          minDate={moment().toDate()}
          showPopperArrow={false} // This will hide the popper arrow
          inline
        />
      </div>
      {/* <div className="flex-grow"></div>
      <hr className="border-t border-gray-300 my-4" />
      <div className="flex justify-between">
        <button
          onClick={() => handlePrev()}
          className="rounded-full px-4 py-2 bg-transparent text-gray-500"
        >
          Previous
        </button>
        <button
          className="text-sm bg-primary-green text-white px-4 py-2 rounded-full hover:bg-opacity-90"
          onClick={() => handleMoveInDateSelect(null)}
        >
          Search
        </button>
      </div> */}
    </Modal>
  );
};

export default MoveInDateModal;
