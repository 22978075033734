import React, { FC } from "react";
import { AttachmentType, Property } from "data/models/property";
import GallerySlider from "components/GallerySlider/GallerySlider";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button/Button";

export interface ApplicationCardProps {
    className?: string;
    property?: Property;
}

const ApplicationCard: FC<ApplicationCardProps> = (props) => {
    const { attachments, id, address } = props.property!;
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/listing-detail/${id}`)
    }

    const renderSliderGallery = () => {
        return (
            <div className="relative flex-shrink-0 w-full md:w-72 ">
                <GallerySlider
                    ratioClass="aspect-w-6 aspect-h-5"
                    galleryImgs={
                        attachments
                            ? attachments
                                .filter((a) => a.type === AttachmentType.Image)
                                .map((a) => a.link!)
                            : []
                    }
                    uniqueID={`${id}`}
                    href={`/listing-detail/${id}`}
                />
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className="grid w-full p-3 sm:p-5">
                <div className="flex-grow flex justify-between">
                    <div className="space-y-2">
                        <div className="flex items-center space-x-2">
                            <h2 className="text-lg font-medium capitalize">
                                <span className="line-clamp-1">{address?.line1} {address?.city}</span>
                            </h2>
                        </div>
                        <div className="text-sm text-neutral-500 dark:text-neutral-400">
                            <span>{address?.city}, {address?.state} </span>
                        </div>
                    </div>
                    <div className="">
                        <ButtonThird onClick={handleClick} className="px-1 py-[5px] border">Apartment Details</ButtonThird>
                    </div>
                </div>
                <div className="my-5">
                    <p> <i className="lar la-check-circle mr-2"></i>Congratulations! Your application is approved. Open chat to see next steps.</p>
                </div>
                <div className="flex items-end space-x-2">
                    <div>
                        <Button
                            onClick={() => {
                                window.open(
                                    `https://wa.me/18573813062`
                                );
                            }}
                            className="px-[1px] py-[5px] border bg-orange-600 text-white"><i className="lab la-whatsapp mr-2"></i> Open Chat</Button>
                    </div>
                    <div>
                        <ButtonPrimary className="px-[1px] py-[5px] border"><i className="las la-eye mr-2"></i> View Application</ButtonPrimary>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div
            className={`nc-ApplicationCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform`}
            data-nc-id="ApplicationCard"
        >
            <div className="grid grid-cols-1 md:flex md:flex-row">
                {renderSliderGallery()}
                {renderContent()}
            </div>
        </div>
    )
}

export default ApplicationCard;

