import { useAuth0 } from "@auth0/auth0-react";
import React, { FC } from "react";
import { Link } from "react-router-dom";

export interface MainSidebarMobileProps {
  className?: string;
}

const MainSidebarMobile: FC<MainSidebarMobileProps> = ({ className = "" }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  return (
    <div
      className={`nc-MainSidebarMobile w-screen fixed bg-white h-16 bottom-0 z-40 ${className}`}
    >
      <div
        className={`grid ${
          isAuthenticated && !isLoading ? "grid-cols-2" : "grid-cols-3"
        }`}
      >
        <div className="inline-flex text-center p-3 justify-center">
          <Link to={"/search-results"}>
            <span>
              <i className="text-3xl md:text-2xl las la-home"></i>
            </span>
            <p className="text-center text-xs">Explore</p>
          </Link>
        </div>
        {/* {
                    isAuthenticated &&
                    !isLoading &&
                    <div className="inline-flex text-center p-3 justify-center">
                        <Link to={"/applications"}>
                            <span><i className="text-3xl las la-folder-open"></i></span>
                            <p className="text-center text-xs">Application</p>
                        </Link>
                    </div>
                } */}
        <div className="inline-flex text-center p-3 justify-center">
          <Link to={"/shortlist"}>
            <span>
              <i className="text-3xl lar la-bookmark"></i>
            </span>
            <p className="text-center text-xs">Shortlist</p>
          </Link>
        </div>
        {/* {isAuthenticated && !isLoading && (
          <div className="inline-flex text-center p-3 justify-center">
            <Link to={"/documents"}>
              <span>
                <i className="text-3xl md:text-2xl las la-folder"></i>
              </span>
              <p className="text-center text-xs">Documents</p>
            </Link>
          </div>
        )} */}
        {!isAuthenticated && (
          <div
            className="inline-flex text-center p-3 justify-center"
            onClick={async () => {
              await loginWithRedirect();
            }}
          >
            <Link to={""}>
              <span>
                <i className="text-3xl md:text-2xl las la-user-circle"></i>
              </span>
              <p className="text-center text-xs">Login</p>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainSidebarMobile;
