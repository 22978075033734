// SearchResultsContext.js
import { SearchParams } from "components/Header/SearchBar";
import React, { createContext, useContext, useState, ReactNode } from "react";

interface SearchParamsContextContextProps {
  children: ReactNode;
}

export const SearchParamsContext = createContext<
  | {
    searchParams: SearchParams;
    setSearchParams: React.Dispatch<React.SetStateAction<SearchParams>>;
  }
  | undefined
>(undefined);

export const SearchParamsProvider: React.FC<
  SearchParamsContextContextProps
> = ({ children }) => {
  const [searchParams, setSearchParams] = useState<SearchParams>({
    city: "Boston",
    university: null,
    moveInDate: null,
    maxRent: "10000",
    minRent: "0",
    beds: null,
    useExactMatchForBeds: null,
    baths: null,
    sortBy: "priceAsc",
    neighborhood: null,
    status: "available",
    address: null,
  });

  return (
    <SearchParamsContext.Provider value={{ searchParams, setSearchParams }}>
      {children}
    </SearchParamsContext.Provider>
  );
};

export const useListingSearchParams = () => {
  const context = useContext(SearchParamsContext);
  if (!context) {
    throw new Error(
      "useSearchParams must be used within a SearchParamsProvider"
    );
  }
  return context;
};

