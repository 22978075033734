import { Document, UserAccount } from "data/models/user-account";
import React, { createContext, useContext, useState, ReactNode } from "react";

interface UserAccountContextProps {
  children: ReactNode;
}

export const UserAccountContext = createContext<
  | {
    userAccount: UserAccount;
    setUserAccount: React.Dispatch<React.SetStateAction<UserAccount>>;
  }
  | undefined
>(undefined);

export const UserAccountProvider: React.FC<UserAccountContextProps> = ({
  children,
}) => {
  const [userAccount, setUserAccount] = useState<UserAccount>({
    attachments: [],
    id: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    dateOfBirth: undefined,
    phoneNumber: undefined,
    accountStatus: undefined,
    likedListings: [],
  });

  return (
    <UserAccountContext.Provider value={{ userAccount, setUserAccount }}>
      {children}
    </UserAccountContext.Provider>
  );
};

export const useUserAccount = () => {
  const context = useContext(UserAccountContext);
  if (!context) {
    throw new Error("useUserAccount must be used within a UserAccountProvider");
  }
  return context;
};
