import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import formatCurrency from "utils/formatCurrency";

const MobileFooterSticky = ({ ...props }) => {
  const navigate = useNavigate();

  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-20">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block text-xl font-semibold">
            {`${formatCurrency(props.data?.rent!)}`}
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
              /month
            </span>
          </span>
        </div>

        <ButtonPrimary
          sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
          onClick={() => {
            navigate(`/listing-detail/${props.data?.id}/rental-application`);
          }}
        >
          Apply Now
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default MobileFooterSticky;
