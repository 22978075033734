import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import rectangle1 from "images/graphics/home1.png";
import rectangle2 from "images/graphics/home2.png";
import rectangle3 from "images/graphics/home3.png";
import { useNavigate } from "react-router-dom";
import { useListingSearchParams } from "providers/SearchParamsProvider";
import SearchBar, { SearchParams } from "components/Header/SearchBar";

export interface SectionHero2Props {
  className?: string;
  children?: React.ReactNode;
}

const SectionHero2: FC<SectionHero2Props> = ({ className = "", children }) => {
  const navigate = useNavigate();
  const { setSearchParams } = useListingSearchParams();

  return (
    <div
      className={`nc-SectionHero2 relative ${className}`}
      data-nc-id="SectionHero2"
    >
      {/* <div className="absolute inset-y-0 w-5/6 xl:w-3/4 right-0 flex-grow">
        <img src={rectangle1} alt="" />
      </div> */}
      {/* <div className="mt-10 md:mt-28 relative grid md:grid-cols-2">
        <div className="items-start pt-12 sm:py-20 lg:py-24 md:mr-3 space-y-8 sm:space-y-10 text-black">
          {children ? (
            children
          ) : (
            <div>
              <p className="font-thin text-sm md:text-lg text-neutral-500 dark:text-neutral-400 !leading-[145%]">
                BOSTON · NEW JERSEY · NEW YORK
              </p>
              <h3 className="font-semibold text-3xl md:text-4xl xl:text-5xl !leading-[140%]">
                <span className="text-primary-green">Dedicated</span> support
                for
                <br /> <span className="text-primary-green">your</span> student
                housing
              </h3>
              <br />
              <p className="font-normal text-sm md:text-lg xl:text-xl text-neutral-500 dark:text-neutral-400 !leading-[145%]">
                We understand the unique challenges you face when moving abroad.
                Landing is a platform built by international students for
                international students
              </p>
            </div>
          )}
          <ButtonPrimary
            onClick={() => {
              setSearchParams({
                city: "Boston",
                university: null,
                beds: null,
                useExactMatchForBeds: null,
                baths: null,
                moveInDate: null,
                maxRent: "10000",
                minRent: "0",
                sortBy: "priceAsc",
                neighborhood: null,
                address: null,
              });
              navigate({ pathname: "/search-results" });
            }}
          >
            Get Started For Free
          </ButtonPrimary>
        </div>
        <div className="relative grid md:grid-cols-2">
          <div className="hidden md:block mr-3">
            <div className="mb-7">
              <img className="object-contain" src={rectangle2} alt="" />
            </div>
            <div>
              <img className="object-contain" src={rectangle3} alt="" />
            </div>
          </div>
          <div className="hidden md:block mt-10">
            <img src={rectangle1} alt="" />
          </div>
        </div>
      </div> */}
      <div className="mt-14 md:mt-20 relative m-5 md:m-8">
        <div className="hidden md:grid md:grid-cols-8">
          <div className="col-span-2 flex justify-center items-center">
            <img className="object-contain" src={rectangle2} alt="" />
          </div>
          <div className="col-span-4 text-center mt-16 m-8">
            <h3 className="font-semibold text-3xl md:text-4xl xl:text-5xl !leading-[115%]">
              Dedicated support for
              <br /> your <span className="text-primary-green">student housing</span>
            </h3>
            <p className="font-thin text-sm md:text-base text-neutral-500 mt-2">
              BOSTON · NEW JERSEY · NEW YORK
            </p>
            <p className="font-normal text-sm md:text-base mt-6 mx-10">
              We understand the unique challenges you face when moving abroad.
              Landing is a platform built by international students for
              international students
            </p>
            <div className="mt-14 m-20">
              <SearchBar onSearch={function (searchParams: SearchParams): void {
                throw new Error("Function not implemented.");
              }} />
            </div>
            <p
              className="underline text-sm text-neutral-500 mt-20 cursor-pointer"
              onClick={() => {
                setSearchParams({
                  city: "Boston",
                  university: null,
                  beds: null,
                  useExactMatchForBeds: null,
                  baths: null,
                  moveInDate: null,
                  maxRent: "10000",
                  minRent: "0",
                  sortBy: "priceAsc",
                  neighborhood: null,
                  address: null,
                });
                navigate({ pathname: "/search-results" });
              }}
            >
              or search apartments manually
            </p>
          </div>
          <div className="col-span-2 flex justify-center items-center">
            <img className="object-contain" src={rectangle1} alt="" />
          </div>
        </div>
        <div className="md:hidden">
          <div className="flex justify-center items-center">
            <img className="object-contain" src={rectangle3} alt="" />
          </div>
          <div className="text-center mt-6">
            <h3 className="font-semibold text-3xl md:text-4xl xl:text-5xl !leading-[115%]">
              Dedicated support for
              <br /> your <span className="text-primary-green">student housing</span>
            </h3>
            <p className="font-thin text-sm md:text-base text-neutral-500 mt-2">
              BOSTON · NEW JERSEY · NEW YORK
            </p>
            <div className="mt-7">
              <SearchBar onSearch={function (searchParams: SearchParams): void {
                throw new Error("Function not implemented.");
              }} />
            </div>
            <p className="font-normal text-xs md:text-base mt-8">
              We understand the unique challenges you face when moving abroad.
              Landing is a platform built by international students for
              international students
            </p>
            <p
              className="underline text-xs text-neutral-500 mt-6 cursor-pointer"
              onClick={() => {
                setSearchParams({
                  city: "Boston",
                  university: null,
                  beds: null,
                  useExactMatchForBeds: null,
                  baths: null,
                  moveInDate: null,
                  maxRent: "10000",
                  minRent: "0",
                  sortBy: "priceAsc",
                  neighborhood: null,
                  address: null,
                });
                navigate({ pathname: "/search-results" });
              }}
            >
              or search apartments manually
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHero2;
