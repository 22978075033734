import ButtonPrimary from "shared/Button/ButtonPrimary";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/graphics/error_img.png";
import Heading from "components/Heading/Heading";

const Page404: FC = () => {

  const handleClick = () => {
    window.history.back()
  }
  return (
    <>
      <Helmet>404 Not Found</Helmet>
      <div className="nc-Page404">
        <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-11">
          {/* HEADER */}
          <header className="text-center max-w-2xl mx-auto space-y-2">
            <NcImage src={I404Png} />
          </header>
          <div className="my-16 text-center">
            <Heading desc="We truly apologize for that. Let’s get you back to the previous page." isCenter>Looks like your flight took a wrong turn.</Heading>
            <ButtonPrimary onClick={() => handleClick()}> <span className="mr-2"><i className="las la-arrow-left"></i></span> Take me back</ButtonPrimary>
          </div>
        </div>
      </div >
    </>
  )
};

export default Page404;
