import React from "react";
import { SectionProps } from "./RentalApplicationForm";
import { PersonalDetailsModel } from "data/models/rental-application/personal-details-model";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { RentalApplicationModel } from "data/models/rental-application/rental-application-model";


const ReviewSection: React.FC<SectionProps<RentalApplicationModel>> = ({
  sectionDetails,
  setSectionDetails,
  handleBack,
  handleNext,
}) => {
  const {
    personalDetails,
    professionalDetails,
    residences,
    additionalRoomates,
    references,
  } = sectionDetails;


  const onSubmit = () => {
    handleNext();
  };


  const renderAdditionalOccupants = (
    occupant: PersonalDetailsModel,
    index: number
  ) => {
    return (
      <div key={index}>
        <h3 className="mb-5">Occupant {index + 1}</h3>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="mb-6 lg:col-span-1">
            <h6 className="text-sm">First Name</h6>
            <p className="text-gray-700 font-light">{occupant?.firstName}</p>
          </div>
          <div className="mb-6 lg:col-span-1">
            <h6 className="text-sm">Last Name</h6>
            <p className="text-gray-700 font-light">{occupant?.lastName}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="mb-6 lg:col-span-1">
            <h6 className="text-sm">Contact Number</h6>
            <p className="text-gray-700 font-light">
              {occupant?.phoneNumber
                ? occupant?.phoneNumber.toSerialized()
                : "-"}
            </p>
          </div>
          <div className="mb-6 lg:col-span-1">
            <h6 className="text-sm">Email</h6>
            <p className="text-gray-700 font-light">{occupant?.email}</p>
          </div>
        </div>
      </div>
    );
  };

  const renderReference = (reference: PersonalDetailsModel) => {
    return (
      <div className="mt-5">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="mb-6 lg:col-span-1">
            <h6 className="text-sm">Full Name</h6>
            <p className="text-gray-700 font-light">{reference?.firstName}</p>
          </div>
          <div className="mb-6 lg:col-span-1">
            <h6 className="text-sm">Contact</h6>
            <p className="text-gray-700 font-light">
              {reference?.phoneNumber
                ? reference?.phoneNumber.toSerialized()
                : "-"}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="mb-6 lg:col-span-1">
            <h6 className="text-sm">Address</h6>
            <p className="text-gray-700 font-light">
              {reference!.address!.line1}, {reference!.address!.line2}
            </p>
            <p className="text-gray-700 font-light">
              {reference!.address!.city}, {reference!.address!.state},{" "}
              {reference!.address!.country}, {reference!.address!.zip}
            </p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <h1 className="mb-5 text-lg">Personal Information</h1>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <h6 className="text-sm">First Name</h6>
          <p className="text-gray-700 font-light">
            {personalDetails?.firstName}
          </p>
        </div>

        <div className="mb-6 lg:col-span-1">
          <h6 className="text-sm">Last Name</h6>
          <p className="text-gray-700 font-light">
            {personalDetails?.lastName}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <h6 className="text-sm">Contact Number</h6>
          <p className="text-gray-700 font-light">
            {personalDetails?.phoneNumber
              ? personalDetails?.phoneNumber.toSerialized()
              : "-"}
          </p>
        </div>

        <div className="mb-6 lg:col-span-1">
          <h6 className="text-sm">Social Security Number</h6>
          <p className="text-gray-700 font-light">
            {personalDetails?.socialSecurityNumber || "-"}
          </p>
        </div>
      </div>

      <hr className="my-5" />

      <h1 className="mb-5 text-lg">Professional Information</h1>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <h6 className="text-sm">Current Occupation</h6>
          <p className="text-gray-700 font-light">
            {professionalDetails!.currentOccupation || "-"}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <h6 className="text-sm">Income Source</h6>
          <p className="text-gray-700 font-light">
            {professionalDetails!.incomeSource || "-"}
          </p>
        </div>

        <div className="mb-6 lg:col-span-1">
          <h6 className="text-sm">Monthly Income</h6>
          <p className="text-gray-700 font-light">
            {professionalDetails!.monthlyIncome}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <h6 className="text-sm">Former Occupation</h6>
          <p className="text-gray-700 font-light">
            {professionalDetails!.formerOccupation || "-"}
          </p>
        </div>
      </div>

      <hr className="my-5" />

      <h1 className="mb-5 text-lg">Current Residence</h1>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <h6 className="text-sm">Residence Type</h6>
          <p className="text-gray-700 font-light">
            {residences!.currentResidence?.type}
          </p>
        </div>
        <div className="mb-6 lg:col-span-1">
          <h6 className="text-sm">Move-in Date Type</h6>
          <p className="text-gray-700 font-light">
            {residences!.currentResidence!.moveInDate}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <h6 className="text-sm">Address</h6>
          <p className="text-gray-700 font-light">
            {residences!.currentResidence!.address!.line1},{" "}
            {residences!.currentResidence!.address!.line2}
          </p>
          <p className="text-gray-700 font-light">
            {residences!.currentResidence!.address!.city},{" "}
            {residences!.currentResidence!.address!.state},{" "}
            {residences!.currentResidence!.address!.country},{" "}
            {residences!.currentResidence!.address!.zip}
          </p>
        </div>

        <div className="mb-6 lg:col-span-1">
          <h6 className="text-sm">Landlord Details</h6>
          <p className="text-gray-700 font-light">
            Name:{" "}
            {residences!.currentResidence!.landordDetails!.firstName || "-"}
          </p>
          <p className="text-gray-700 font-light">
            Contact:{" "}
            {residences!.currentResidence!.landordDetails!.phoneNumber?.toSerialized() ||
              "-"}
          </p>
        </div>
      </div>

      <hr className="my-5" />

      <h1 className="mb-5 text-lg">Additional Occupants</h1>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <h6 className="text-sm">Will you live with other adults?</h6>
          <p className="text-gray-700 font-light">
            {additionalRoomates!.dohaveRoomates ? "Yes" : "No"}
          </p>
        </div>
      </div>

      {additionalRoomates!.dohaveRoomates &&
        additionalRoomates!.roomates.map((personalDetailsModel, index) => {
          return renderAdditionalOccupants(personalDetailsModel, index);
        })}

      <hr className="my-5" />

      <h1 className="mb-5 text-lg">References</h1>
      <h3 className="mb-5">Personal Reference</h3>
      {renderReference(references!.personalReference!)}

      <h3>Emergency Reference</h3>
      {references?.isEmergencyRefSameAsPersonal ? (
        <p className="text-gray-700 font-light">Same as above</p>
      ) : (
        renderReference(references!.emergencyReference!)
      )}

      <h3 className="mt-5">Credit Reference</h3>
      {references?.isCreditRefSameAsPersonal ? (
        <p className="text-gray-700 font-light">Same as above</p>
      ) : (
        renderReference(references!.creditReference!)
      )}

      <hr className="my-5" />


      <div className="flex justify-around">
        <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>

        <ButtonPrimary onClick={onSubmit}>Sign</ButtonPrimary>
      </div>
    </div>
  );
};

export default ReviewSection;
