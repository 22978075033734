import React, { ChangeEvent, useEffect, useState } from "react";
import passport from "images/passport_inp.png"
import i_20_inp from "images/i_20_inp.png"
import offer_letter_inp from "images/offer_inp.png"
import cosigner_id_inp from "images/co-signer-Id.png"
import cosigner_credit_inp from "images/co-signer-credit.png"
import cosigner_pay_stub_inp from "images/co-signer-pay-stub.png"
import other from "images/other_inp.png"
import { FaUpload } from "react-icons/fa6";
import UserAccountRepository from "data/repositories/UserAccountRepository";
import { useUserAccount } from "providers/UserAccountProvider";
import { FaEye, FaTrash } from "react-icons/fa";
import { Document } from "data/models/user-account";

function PageUserDocuments() {

    const { userAccount, setUserAccount } = useUserAccount();

    const [i20Files, setI20Files] = useState<Document[]>(userAccount.attachments ? userAccount.attachments.filter(type => type.type === "I20") : [])
    const [passportFiles, setPassportFiles] = useState<Document[]>(userAccount.attachments ? userAccount.attachments.filter(type => type.type === "PASSPORT") : [])
    const [offerLetterFiles, setOfferLetterFiles] = useState<Document[]>()
    const [cosignerIdFiles, setCosignerIdFiles] = useState<Document[]>()
    const [cosignerCreditFiles, setCosignerCreditFiles] = useState<Document[]>()
    const [cosignerPaystubFiles, setCosignerPaystubFiles] = useState<Document[]>()
    const [otherFiles, setOtherFiles] = useState<Document[]>()

    // Keeping this as a comment because we might need them in future, as the new design is still not ready.

    // const [isPassportModalOpen, setIsPassportModalOpen] = useState(false)
    // const [isI20ModalOpen, setIsI20ModalOpen] = useState(false)
    // const [isOfferLetterModalOpen, setIsOfferLetterModalOpen] = useState(false)
    // const [isCosignerIdModalOpen, setIsCosignerIdModalOpen] = useState(false)
    // const [isCosginerCreditModalOpen, setIsCosginerCreditModalOpen] = useState(false)
    // const [isCosignerPaystubModalOpen, setIsCosignerPaystubModalOpen] = useState(false)
    // const [isOtherModalOpen, setIsOtherModalOpen] = useState(false)


    const [isCosignerIdFileUploaded, setIsCosignerIdFileUploaded] = useState(false)
    const [isCosignerCreditFileUploaded, setIsCosignerCreditFileUploaded] = useState(false)
    const [isCosignerPayStubFileUploaded, setIsCosignerPayStubFileUploaded] = useState(false)
    const [isPassportFileUploaded, setIsPassportFileUploaded] = useState(false)
    const [isOfferLetterFileUploaded, setIsOfferLetterFileUploaded] = useState(false)
    const [isOtherFileUploaded, setIsOtherFileUploaded] = useState(false)
    const [isI20FileUploaded, setIsI20FileUploaded] = useState(false)

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(true)

        if (i20Files?.length! > 0)
            setIsI20FileUploaded(true)
        if (passportFiles?.length! > 0)
            setIsPassportFileUploaded(true)
        if (offerLetterFiles)
            setIsOfferLetterFileUploaded(true)
        if (cosignerIdFiles)
            setIsCosignerIdFileUploaded(true)
        if (cosignerCreditFiles)
            setIsCosignerCreditFileUploaded(true)
        if (cosignerPaystubFiles)
            setIsCosignerPayStubFileUploaded(true)
        if (otherFiles)
            setIsOtherFileUploaded(true)

        console.log(passportFiles);


        setLoading(false)


    }, [userAccount])

    const inputCosignerIdDoc = () => {
        document.getElementById('cosignerid')?.click();
    }
    const inputPassportDoc = () => {
        document.getElementById('passport')?.click();
    }
    const inputI20Doc = () => {
        document.getElementById('i20doc')?.click();
    }
    const inputOfferLetterDoc = () => {
        document.getElementById('offerletter')?.click();
    }
    const inputCosignerCreditDoc = () => {
        document.getElementById('cosignercredit')?.click();
    }
    const inputCosignerPayStubDoc = () => {
        document.getElementById('cosignerpaystub')?.click();
    }
    const inputOtherDoc = () => {
        document.getElementById('other')?.click();
    }

    const handleCosignerIdDocChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        console.log(event.target.files);
        if (selectedFile?.name.split('.').pop()?.toLowerCase() !== 'pdf' && selectedFile?.name.split('.').pop()?.toLowerCase() !== 'png' && selectedFile?.name.split('.').pop()?.toLowerCase() !== 'jpg' && selectedFile?.name.split('.').pop()?.toLowerCase() !== 'jpeg')
            alert("please upload a pdf or an image");
        else {
            // sendFile(selectedFile, "i20");
            setIsCosignerIdFileUploaded(true);
        }

    }

    const handleI20DocChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile?.name.split('.').pop()?.toLowerCase() !== 'pdf' && selectedFile?.name.split('.').pop()?.toLowerCase() !== 'png' && selectedFile?.name.split('.').pop()?.toLowerCase() !== 'jpg' && selectedFile?.name.split('.').pop()?.toLowerCase() !== 'jpeg')
            alert("please upload a pdf or an image");
        else {
            setLoading(true);
            const created = sendFile(selectedFile, "i20");
            if (await created)
                setIsI20FileUploaded(true);
            else
                alert("There was some problem uploading the file \nTry reducing the file size.")
            setLoading(false);
            udpateUserAccount();
        }
    }

    const handleCosignerCreditDocChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile)
            setIsCosignerCreditFileUploaded(true);
    }

    const handleCosignerPayStubDocChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile)
            setIsCosignerPayStubFileUploaded(true);
    }

    const handleOfferLetterDocChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile)
            setIsOfferLetterFileUploaded(true);
    }

    const handlePassportDocChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (passportFiles.length > 0) {
            handlePassportFileDelete();
        }
        if (selectedFile?.name.split('.').pop()?.toLowerCase() !== 'pdf' && selectedFile?.name.split('.').pop()?.toLowerCase() !== 'png' && selectedFile?.name.split('.').pop()?.toLowerCase() !== 'jpg' && selectedFile?.name.split('.').pop()?.toLowerCase() !== 'jpeg')
            alert("please upload a pdf or an image");
        else {
            setLoading(true);
            const created = sendFile(selectedFile, "passport");
            if (await created) {
                setIsPassportFileUploaded(true);
            }
            else
                alert("There was some problem uploading the file \nTry reducing the file size.")
            udpateUserAccount();
            setLoading(false);
        }
    }

    const handleOtherDocChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile)
            setIsOtherFileUploaded(true);
    }

    const sendFile = async (file: File, type: string) => {
        const message = await UserAccountRepository.getInstance().submitUserDocument(userAccount.id!, file, type);
        return message;
    }

    const handleI20FileDelete = () => {
        setLoading(true)
        handleFileDelete(i20Files![0].id!);
        // udpateUserAccount();
        setI20Files([])
        setIsI20FileUploaded(false)
        setLoading(false)
    }

    const handlePassportFileDelete = () => {
        setLoading(true)
        handleFileDelete(passportFiles[0].id!);
        // udpateUserAccount();
        setPassportFiles([])
        setIsPassportFileUploaded(false);
        setLoading(false)
    }

    const udpateUserAccount = async () => {
        const account = await UserAccountRepository.getInstance().getAccountByEmailId(userAccount.email!);
        console.log(account);
        setUserAccount(account);
    }

    const handleFileDelete = (documentId: string) => {
        console.log("File Id: ", documentId);
        setTimeout(async () => {
            await UserAccountRepository.getInstance().deleteUserDocument(userAccount.id!, documentId);
        }, 500)
    }

    return (
        <div
            className={`nc-PageUserDocuments relative`}
            data-nc-id="PageUserDocuments"
        >
            {
                loading ?
                    <div className="flex justify-center items-center h-screen w-screen z-50">
                        {/* Display loading indicator here */}
                        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary-green"></div>
                    </div>
                    :
                    <div className="container relative space-y-16 mt-20 mb-24 lg:space-y-20 lg:mb-28">
                        <div>
                            <h3 className="font-semibold text-3xl md:text-4xl xl:text-5xl !leading-[140%]">Your application <span className="text-primary-green">documents</span>.</h3>
                            <br />
                            <p className="font-normal text-sm md:text-lg xl:text-xl text-neutral-500 dark:text-neutral-400 !leading-[145%]">
                                The documents you upload here will be used to apply for apartments.
                            </p>
                        </div>
                        <div className="w-full p-8 flex-shrink-0 xl:px-8 border rounded-3xl">
                            {
                                (!isCosignerIdFileUploaded ||
                                    !isOfferLetterFileUploaded ||
                                    !isI20FileUploaded ||
                                    !isPassportFileUploaded ||
                                    !isCosignerCreditFileUploaded ||
                                    !isCosignerPayStubFileUploaded ||
                                    !isOtherFileUploaded) &&
                                <div className="mb-7">
                                    <h2 className="font-semibold md:text-xl text-gray-600">Documents Pending</h2>
                                </div>
                            }
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                {
                                    !isPassportFileUploaded &&
                                    <div className="cursor-pointer" onClick={inputPassportDoc} id="vaccineBlock">
                                        <img className="object-contain" src={passport} alt="" />
                                        <input className="hidden" type="file" id='passport' onChange={handlePassportDocChange} />
                                    </div>
                                }
                                {
                                    !isI20FileUploaded &&
                                    <div className="cursor-pointer" onClick={inputI20Doc} id="vaccineBlock">
                                        <img className="object-contain" src={i_20_inp} alt="" />
                                        <input className="hidden" type="file" id='i20doc' onChange={handleI20DocChange} />
                                    </div>
                                }
                                {
                                    !isOfferLetterFileUploaded &&
                                    <div className="cursor-pointer" onClick={inputOfferLetterDoc} id="admitBlock">
                                        <img className="object-contain" src={offer_letter_inp} alt="" />
                                        <input className="hidden" type="file" id='offerletter' onChange={handleOfferLetterDocChange} />
                                    </div>
                                }
                                {
                                    !isCosignerIdFileUploaded &&
                                    <div className="cursor-pointer" onClick={inputCosignerIdDoc} id="passportBlock">
                                        <img className="object-contain" src={cosigner_id_inp} alt="" />
                                        <input className="hidden" type="file" id='cosignerid' onChange={handleCosignerIdDocChange} />
                                    </div>
                                }
                                {
                                    !isCosignerCreditFileUploaded &&
                                    <div className="cursor-pointer" onClick={inputCosignerCreditDoc} id="passportBlock">
                                        <img className="object-contain" src={cosigner_credit_inp} alt="" />
                                        <input className="hidden" type="file" id='cosignercredit' onChange={handleCosignerCreditDocChange} />
                                    </div>
                                }
                                {
                                    !isCosignerPayStubFileUploaded &&
                                    <div className="cursor-pointer" onClick={inputCosignerPayStubDoc} id="passportBlock">
                                        <img className="object-contain" src={cosigner_pay_stub_inp} alt="" />
                                        <input className="hidden" type="file" id='cosignerpaystub' onChange={handleCosignerPayStubDocChange} />
                                    </div>
                                }
                                {
                                    !isOtherFileUploaded &&
                                    <div className="cursor-pointer" onClick={inputOtherDoc} id="otherBlock">
                                        <img className="object-contain" src={other} alt="" />
                                        <input className="hidden" type="file" id='other' onChange={handleOtherDocChange} />
                                    </div>
                                }
                            </div>
                            {
                                (isCosignerIdFileUploaded ||
                                    isOfferLetterFileUploaded ||
                                    isI20FileUploaded ||
                                    isPassportFileUploaded ||
                                    isCosignerCreditFileUploaded ||
                                    isCosignerPayStubFileUploaded ||
                                    isOtherFileUploaded) &&
                                <div className="my-7">
                                    <h2 className="font-semibold md:text-xl text-gray-600">Uploaded Documents</h2>
                                </div>
                            }
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                {
                                    isPassportFileUploaded &&
                                    <div
                                        className="w-full border-2 border-gray-300 rounded-lg grid grid-cols-5 overflow-hidden bg-primary-50"

                                    >
                                        <div className="col-span-3 items-center align-middle p-3 md:p-8">
                                            <h2 className="md:font-semibold text-xs md:text-base">Passport <span className="text-red-600">*</span></h2>
                                        </div>
                                        <div
                                            className="flex justify-center items-center align-middle bg-red-500 p-3 md:p-5 cursor-pointer"
                                            onClick={handlePassportFileDelete}
                                        >
                                            <FaTrash className="text-sm md:text-2xl text-white" />
                                        </div>
                                        <div
                                            className="flex justify-center items-center align-middle bg-primary-green p-3 md:p-5 cursor-pointer"
                                            onClick={inputPassportDoc}
                                        >
                                            <FaUpload className="text-sm md:text-2xl text-white" />
                                            <input className="hidden" type="file" id='passport' onChange={handlePassportDocChange} />
                                        </div>
                                    </div>
                                }
                                {
                                    isI20FileUploaded &&
                                    <div
                                        className="w-full border-2 border-gray-300 rounded-lg grid grid-cols-5 overflow-hidden bg-primary-50"

                                    >
                                        <div className="col-span-3 items-center align-middle p-3 md:p-8">
                                            <h2 className="md:font-semibold text-xs md:text-base">i-20 <span className="text-red-600">*</span></h2>
                                        </div>
                                        <div
                                            className="flex justify-center items-center align-middle bg-red-500 p-3 md:p-5 cursor-pointer"
                                            onClick={handleI20FileDelete}
                                        >
                                            <FaTrash className="text-sm md:text-2xl text-white" />
                                        </div>
                                        <div className="flex justify-center items-center align-middle bg-primary-green p-3 md:p-5 cursor-pointer">
                                            <FaUpload className="text-sm md:text-2xl text-white" />
                                        </div>
                                    </div>
                                }
                                {
                                    isOfferLetterFileUploaded &&
                                    <div
                                        className="w-full border-2 border-gray-300 rounded-lg grid grid-cols-5 overflow-hidden bg-primary-50"

                                    >
                                        <div className="col-span-3 items-center align-middle p-3 md:p-8">
                                            <h2 className="md:font-semibold text-xs md:text-base">Offer Letter <span className="text-red-600">*</span></h2>
                                        </div>
                                        <div className="flex justify-center items-center align-middle bg-red-500 p-3 md:p-5 cursor-pointer">
                                            <FaTrash className="text-sm md:text-2xl text-white" />
                                        </div>
                                        <div className="flex justify-center items-center align-middle bg-primary-green p-3 md:p-5 cursor-pointer">
                                            <FaUpload className="text-sm md:text-2xl text-white" />
                                        </div>
                                    </div>
                                }
                                {
                                    isCosignerIdFileUploaded &&
                                    <div
                                        className="w-full border-2 border-gray-300 rounded-lg grid grid-cols-5 overflow-hidden bg-primary-50"

                                    >
                                        <div className="col-span-3 items-center align-middle p-3 md:p-8">
                                            <h2 className="md:font-semibold text-xs md:text-base">Co-signer ID</h2>
                                            {/* <p className="text-xs md:text-base">Updated 12/01/2024 10:42</p> */}
                                        </div>
                                        <div className="flex justify-center items-center align-middle bg-red-500 p-3 md:p-5 cursor-pointer">
                                            <FaTrash className="text-sm md:text-2xl text-white" />
                                        </div>
                                        <div className="flex justify-center items-center align-middle bg-primary-green p-3 md:p-5 cursor-pointer">
                                            <FaUpload className="text-sm md:text-2xl text-white" />
                                        </div>
                                    </div>
                                }
                                {
                                    isCosignerCreditFileUploaded &&
                                    <div
                                        className="w-full border-2 border-gray-300 rounded-lg grid grid-cols-5 overflow-hidden bg-primary-50"

                                    >
                                        <div className="col-span-3 items-center align-middle p-3 md:p-8">
                                            <h2 className="md:font-semibold text-xs md:text-base">Co-signer credit report</h2>
                                        </div>
                                        <div className="flex justify-center items-center align-middle bg-red-500 p-3 md:p-5 cursor-pointer">
                                            <FaTrash className="text-sm md:text-2xl text-white" />
                                        </div>
                                        <div className="flex justify-center items-center align-middle bg-primary-green p-3 md:p-5 cursor-pointer">
                                            <FaUpload className="text-sm md:text-2xl text-white" />
                                        </div>
                                    </div>
                                }
                                {
                                    isCosignerPayStubFileUploaded &&
                                    <div
                                        className="w-full border-2 border-gray-300 rounded-lg grid grid-cols-5 overflow-hidden bg-primary-50"

                                    >
                                        <div className="col-span-3 items-center align-middle p-3 md:p-8">
                                            <h2 className="md:font-semibold text-xs md:text-base">Co-signer paystub</h2>
                                        </div>
                                        <div className="flex justify-center items-center align-middle bg-red-500 p-3 md:p-5 cursor-pointer">
                                            <FaTrash className="text-sm md:text-2xl text-white" />
                                        </div>
                                        <div className="flex justify-center items-center align-middle bg-primary-green p-3 md:p-5 cursor-pointer">
                                            <FaUpload className="text-sm md:text-2xl text-white" />
                                        </div>
                                    </div>
                                }
                                {
                                    isOtherFileUploaded &&
                                    <div
                                        className="w-full border-2 border-dashed border-gray-300 rounded-lg grid grid-cols-5 overflow-hidden bg-primary-50"

                                    >
                                        <div className="col-span-3 items-center align-middle p-3 md:p-8">
                                            <h2 className="md:font-semibold text-xs md:text-base">Other</h2>
                                        </div>
                                        <div className="flex justify-center items-center align-middle bg-red-500 p-3 md:p-5 cursor-pointer">
                                            <FaTrash className="text-sm md:text-2xl text-white" />
                                        </div>
                                        <div className="flex justify-center items-center align-middle bg-primary-green p-3 md:p-5 cursor-pointer">
                                            <FaUpload className="text-sm md:text-2xl text-white" />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
            }

            {/**
             * 
             * Keeping this commented because we might need them in future, as the new design is still not ready.
             * 
             */}
            {/* {
                isPassportModalOpen &&
                <PassportModal
                    isOpen={isPassportModalOpen}
                    onClose={() => setIsPassportModalOpen(false)}
                    files={passportFiles!}
                />
            }
            {
                isI20ModalOpen &&
                <I20Modal
                    isOpen={isI20ModalOpen}
                    onClose={() => setIsI20ModalOpen(false)}
                    file={pdf}
                />
            }
            {
                isOfferLetterModalOpen &&
                <OfferLetterModal
                    isOpen={isOfferLetterModalOpen}
                    onClose={() => setIsOfferLetterModalOpen(false)}
                    file={pdf}
                />
            }
            {
                isCosignerIdModalOpen &&
                <CosignerIdModal
                    isOpen={isCosignerIdModalOpen}
                    onClose={() => setIsCosignerIdModalOpen(false)}
                    file={pdf}
                />
            }
            {
                isCosginerCreditModalOpen &&
                <CosignerCreditModal
                    isOpen={isCosginerCreditModalOpen}
                    onClose={() => setIsCosginerCreditModalOpen(false)}
                    file={pdf}
                />
            }
            {
                isCosignerPaystubModalOpen &&
                <CosignerPaystubModal
                    isOpen={isCosignerPaystubModalOpen}
                    onClose={() => setIsCosignerPaystubModalOpen(false)}
                    file={pdf}
                />
            }
            {
                isOtherModalOpen &&
                <OtherModal
                    isOpen={isOtherModalOpen}
                    onClose={() => setIsOtherModalOpen(false)}
                    file={pdf}
                />
            } */}
        </div>
    )
}

export default PageUserDocuments;