import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import image from "images/graphics/allinone.png"

export interface SectionAllInOnePlatformProps {
    className?: string;
}

const SectionAllInOnePlatform: FC<SectionAllInOnePlatformProps> = ({ className = "" }) => {
    return (
        <div className={`nc-SectionAllInOnePlatform relative overflow-hidden ${className}`}
            data-nc-id="SectionAllInOnePlatform"
        >
            <h3 className="text-center font-semibold text-2xl md:text-3xl xl:text-4xl mb-10 !leading-[140%]">All-in-one platform to make renting simple</h3>
            {/* <Heading isCenter desc="">All-in-one platform to make renting simple</Heading> */}
            <div className="relative grid md:grid-cols-2 gap-10">
                <div className="flex items-center md:mt-14">
                    <img src={image} alt="" />
                </div>
                <div className="md:mx-5 mt-3">
                    <div className="relative overflow-hidden w-full">
                        <div>
                            <div className="absolute rounded-full bg-indigo-950 md:w-16 md:h-16 w-12 h-12 text-center top-9">
                                <h2 className="text-white md:text-4xl text-3xl md:mt-3 mt-2 font-semibold">1</h2>
                            </div>
                        </div>
                        <div className="md:p-6 p-5 flex bg-primary-50 rounded-full md:ml-10 ml-7">
                            <div className="md:flex justify-center items-center w-1/4 hidden">
                                <i className="las la-search text-primary-800 text-4xl -rotate-90 font-semibold"></i>
                            </div>
                            <div className="text-left ml-5 md:ml-0">
                                <h2 className="md:text-lg text-base font-semibold">Browse Apartments</h2>
                                <p className="text-xs md:text-sm">Use our intuitive filters to narrow down your <br className="md:block hidden" />choices based on location, price, amenities, and more</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 relative overflow-hidden w-full">
                        <div>
                            <div className="absolute rounded-full bg-indigo-950 md:w-16 md:h-16 w-12 h-12 text-center top-9">
                                <h2 className="text-white md:text-4xl text-3xl md:mt-3 mt-2 font-semibold">2</h2>
                            </div>
                        </div>
                        <div className="md:p-6 p-5 flex bg-primary-50 rounded-full md:ml-10 ml-5">
                            <div className="md:flex justify-center items-center w-1/4 hidden">
                                <i className="las la-eye text-primary-800 text-4xl font-semibold"></i>
                            </div>
                            <div className="text-left ml-5 md:ml-0">
                                <h2 className="md:text-lg text-base font-semibold">Virtual Tours</h2>
                                <p className="text-xs md:text-sm">Shortlist your favorite apartments and <br className="md:block hidden" />schedule a live tour with us to get all your <br className="md:block hidden" />questions answered</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 relative overflow-hidden w-full">
                        <div>
                            <div className="absolute rounded-full bg-indigo-950 md:w-16 md:h-16 w-12 h-12 text-center top-9">
                                <h2 className="text-white md:text-4xl text-3xl md:mt-3 mt-2 font-semibold">3</h2>
                            </div>
                        </div>
                        <div className="md:p-6 p-5 flex bg-primary-50 rounded-full md:ml-10 ml-5">
                            <div className="md:flex justify-center items-center w-1/4 hidden">
                                <i className="las la-check-circle text-primary-800 text-4xl font-semibold"></i>
                            </div>
                            <div className="text-left ml-5 md:ml-0">
                                <h2 className="md:text-lg text-base font-semibold">Apply Online</h2>
                                <p className="text-xs md:text-sm">Our dashboard lets you manage your <br className="md:block hidden" />documents, apply to different apartments <br className="md:block hidden" />quickly, and sign leases digitally</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 relative overflow-hidden w-full">
                        <div>
                            <div className="absolute rounded-full bg-indigo-950 md:w-16 md:h-16 w-12 h-12 text-center top-9">
                                <h2 className="text-white md:text-4xl text-3xl md:mt-3 mt-2 font-semibold">4</h2>
                            </div>
                        </div>
                        <div className="md:p-6 p-5 flex bg-primary-50 rounded-full md:ml-10 ml-5">
                            <div className="md:flex justify-center items-center w-1/4 hidden">
                                <i className="las la-rupee-sign text-primary-800 text-4xl font-semibold"></i>
                            </div>
                            <div className="text-left ml-5 md:ml-0">
                                <h2 className="md:text-lg text-base font-semibold">Pay in India</h2>
                                <p className="text-xs md:text-sm">Reserve your apartment with a convenient UPI <br className="md:block hidden" />deposit. Your funds are always protected by <br className="md:block hidden" />our 100% scam protection guarantee</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionAllInOnePlatform