import React, { FC, useEffect, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Link } from "react-router-dom";
import { Attachment, AttachmentType, Property } from "data/models/property";
import formatCurrency from "../../utils/formatCurrency";
import BedIcon from "../../images/icons/bed.svg";
import BathIcon from "../../images/icons/bath.svg";
import { FaBookmark, FaCalendarAlt, FaRegBookmark } from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react";
import UserAccountRepository from "data/repositories/UserAccountRepository";
import { useUserAccount } from "providers/UserAccountProvider";
import commingsoon from "images/graphics/comming_soon_image.png";
import { formatBathsDisplay, formatBedsDisplay } from "utils/bedbathFormatter";
import { UserAccount } from "data/models/user-account";

export interface ListingCardProps {
  className?: string;
  property?: Property;
  userId?: string;
}

const ListingCard: FC<ListingCardProps> = (props: ListingCardProps) => {
  const { title, attachments, bed, bath, rent, id, address, availabilityDate } =
    props.property!;

  const { isAuthenticated } = useAuth0();

  const { userAccount, setUserAccount } = useUserAccount();

  const handleClick = () => {
    if (isAuthenticated) shortlistTheItem(userAccount.id!);
    else alert("You need to log in first");
  };

  const shortlistTheItem = async (userId: string) => {
    const newShortListValue = !userAccount.likedListings?.some(
      (listing) => listing.id === id
    );

    let updatedShortListings;

    if (newShortListValue) {
      updatedShortListings = [...userAccount.likedListings!, props.property!];
    } else {
      updatedShortListings = userAccount.likedListings!.filter(
        (a) => a.id !== props.property!.id
      );
    }

    setUserAccount({ ...userAccount, likedListings: updatedShortListings });

    await UserAccountRepository.getInstance().shortlistListing(
      userId,
      props.property!.id!
    );
  };

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={id!}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={
            attachments &&
            attachments.filter(
              (a: Attachment) => a.type === AttachmentType.Image
            ).length > 0
              ? attachments
                  .filter((a: Attachment) => a.type === AttachmentType.Image)
                  .map((a: Attachment) => a.link!)
              : [commingsoon]
          }
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="p-4 space-y-4">
        <div className="space-y-2">
          <div className="flex items-center space-x-2 justify-between">
            <h2 className="capitalize text-md">
              <span className="line-clamp-1">
                {title?.length ? title : address?.line1}
              </span>
            </h2>
            <div className="z-11">
              <button className="text-2xl" onClick={handleClick}>
                {userAccount.likedListings?.some(
                  (listing) => listing.id === id
                ) ? (
                  <FaBookmark className="text-red-500" />
                ) : (
                  <FaRegBookmark />
                )}
              </button>
            </div>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 space-x-2">
            {address?.city}, {address!.state}
          </div>

          <div className="flex col-2 lg:col-3 justify-between">
            <span className="flex text-sm text-neutral-500 dark:text-neutral-400 mr-3">
              <img className="mr-2" src={BedIcon} alt="Bed"></img>
              {formatBedsDisplay(bed)}
            </span>
            <span className="flex text-sm text-neutral-500 dark:text-neutral-400 mr-3">
              <img className="mr-2" src={BathIcon} alt="Bath"></img>
              {formatBathsDisplay(bath)}
            </span>

            {availabilityDate && (
              <span className="flex text-sm text-neutral-500 dark:text-neutral-400 mr-3">
                <FaCalendarAlt className="mr-2"></FaCalendarAlt>
                {new Date(availabilityDate).toLocaleDateString("en-US", {
                  month: "short", // "Jun"
                  day: "2-digit", // "01"
                  timeZone: "UTC",
                })}
              </span>
            )}
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {formatCurrency(rent)}
            {` `}
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              / month
            </span>
          </span>
          <Link to={`/listing-detail/${id}`}>
            <button className="rounded-full px-4 py-2 mr-2 bg-primary-green text-white border text-sm">
              See details
            </button>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border-2 border-neutral-200 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-2xl transition-shadow h-full`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      {renderContent()}
    </div>
  );
};

export default ListingCard;
