import React, { FC, useState } from "react";
import MainSidebar from "./MainSidebar";
import { useAuth0 } from "@auth0/auth0-react";
export interface SidebarProps {
    className?: string;
}



const Sidebar: FC<SidebarProps> = ({ className = "" }) => {
    const { isLoading, user, isAuthenticated, loginWithRedirect, logout } =
        useAuth0();
    return (
        isAuthenticated && !isLoading ?
            <div className={`nc-Sidebar absolute right-full top-0 bottom-0 left-0 z-40 ${className}`}>
                <div className="relative z-11">
                    <MainSidebar />
                </div>
            </div> : <></>
    )
}

export default Sidebar;