import React from "react";
import ReactDOM from "react-dom/client";
//
import "reflect-metadata";

import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "es6-shim";
import { UserAccountProvider } from "providers/UserAccountProvider";
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "providers/Auth0ProviderWithNavigate";
import { ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const theme = createTheme({
  palette: {
    primary: {
      main: "#00bfa5",
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter basename={"/"}>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <UserAccountProvider>
          <Auth0ProviderWithNavigate>
            <App />
          </Auth0ProviderWithNavigate>
        </UserAccountProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </BrowserRouter>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
