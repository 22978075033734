import React, { ChangeEvent, useState } from "react";
import Input from "shared/Input/Input";
import { SectionProps } from "./RentalApplicationForm";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Type } from "class-transformer";

import {
  ResidenceModel,
  ResidenceType,
  ResidencesModel,
} from "data/models/rental-application/residences-model";
import {
  PersonalDetailsModel,
  PhoneNumberModel,
} from "data/models/rental-application/personal-details-model";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Address } from "data/models/property";
import PhoneInput from "shared/Input/PhoneInput";

const ResidencesSection: React.FC<SectionProps<ResidencesModel>> = ({
  sectionDetails,
  setSectionDetails,
  handleBack,
  handleNext,
}) => {
  const [validationErrorMap, setValidatorErrorMap] = useState<
    Map<string, string[]>
  >(sectionDetails.getValidatonErrorMap());
  const handleInputChange = (field: keyof ResidencesModel, value: any) => {
    setSectionDetails(
      new ResidencesModel({
        ...sectionDetails,
        [field]: value,
      })
    );
  };

  const onNextClick = () => {
    if (sectionDetails.isValid()) {
      handleNext();
    } else {
      setValidatorErrorMap(sectionDetails.getValidatonErrorMap());
    }
  };

  return (
    <div className="">
      <div className="mb-3 lg:col-span-2">
        <label
          htmlFor="currentResidence"
          className="block text-sm font-medium text-gray-600"
        >
          Current Residence
          <span className="ml-1 text-red-500">*</span>
          {validationErrorMap.get("currentResidence")?.length! > 0 && (
            <span className="ml-1 text-red-500">Required field</span>
          )}
        </label>
        <FormControl>
          <RadioGroup
            row
            name="currentResidence"
            value={sectionDetails.currentResidence?.type}
            onChange={(e) => {
              handleInputChange(
                "currentResidence",
                new ResidenceModel({
                  ...sectionDetails.currentResidence,
                  type: e.target.value as ResidenceType,
                })
              );
            }}
          >
            {Object.values(ResidenceType).map((key) => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio />}
                label={key}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>

      <hr className="mb-6"></hr>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <Input
            type="text"
            label="H-no, Street name"
            id="currentResidenceLine1"
            name="currentResidenceLine1"
            isRequired={true}
            value={sectionDetails.currentResidence?.address?.line1 || ""}
            error={
              validationErrorMap.get("currentResidenceLine1")?.length
                ? validationErrorMap.get("currentResidenceLine1")?.[0]
                : undefined
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(
                "currentResidence",
                new ResidenceModel({
                  ...sectionDetails.currentResidence,
                  address: new Address({
                    ...sectionDetails.currentResidence?.address,
                    line1: e.target.value,
                  }),
                })
              )
            }
          />
        </div>

        <div className="mb-6 lg:col-span-1">
          <Input
            type="text"
            label="Apartment, suite, etc."
            id="currentResidenceLine2"
            name="currentResidenceLine2"
            isRequired={false}
            value={sectionDetails.currentResidence?.address?.line2 || ""}
            error={
              validationErrorMap.get("currentResidenceLine2")?.length
                ? validationErrorMap.get("currentResidenceLine2")?.[0]
                : undefined
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(
                "currentResidence",
                new ResidenceModel({
                  ...sectionDetails.currentResidence,
                  address: new Address({
                    ...sectionDetails.currentResidence?.address,
                    line2: e.target.value,
                  }),
                })
              )
            }
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <Input
            type="text"
            label="City"
            id="currentResidenceCity"
            name="currentResidenceCity"
            isRequired={true}
            value={sectionDetails.currentResidence?.address?.city || ""}
            error={
              validationErrorMap.get("currentResidenceCity")?.length
                ? validationErrorMap.get("currentResidenceCity")?.[0]
                : undefined
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(
                "currentResidence",
                new ResidenceModel({
                  ...sectionDetails.currentResidence,
                  address: new Address({
                    ...sectionDetails.currentResidence?.address,
                    city: e.target.value,
                  }),
                })
              )
            }
          />
        </div>

        <div className="mb-6 lg:col-span-1">
          <Input
            type="text"
            label="State"
            id="currentResidenceState"
            name="currentResidenceState"
            isRequired={true}
            value={sectionDetails.currentResidence?.address?.state || ""}
            error={
              validationErrorMap.get("currentResidenceState")?.length
                ? validationErrorMap.get("currentResidenceState")?.[0]
                : undefined
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(
                "currentResidence",
                new ResidenceModel({
                  ...sectionDetails.currentResidence,
                  address: new Address({
                    ...sectionDetails.currentResidence?.address,
                    state: e.target.value,
                  }),
                })
              )
            }
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <Input
            type="text"
            label="Country"
            id="currentResidenceCountry"
            name="currentResidenceCountry"
            isRequired={true}
            value={sectionDetails.currentResidence?.address?.country || ""}
            error={
              validationErrorMap.get("currentResidenceCountry")?.length
                ? validationErrorMap.get("currentResidenceCountry")?.[0]
                : undefined
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(
                "currentResidence",
                new ResidenceModel({
                  ...sectionDetails.currentResidence,
                  address: new Address({
                    ...sectionDetails.currentResidence?.address,
                    country: e.target.value,
                  }),
                })
              )
            }
          />
        </div>
        <div className="mb-6 lg:col-span-1">
          <Input
            type="text"
            label="Zip/Pin code"
            id="currentResidenceZip"
            name="currentResidenceZip"
            isRequired={true}
            value={sectionDetails.currentResidence?.address?.zip || ""}
            error={
              validationErrorMap.get("currentResidenceZip")?.length
                ? validationErrorMap.get("currentResidenceZip")?.[0]
                : undefined
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(
                "currentResidence",
                new ResidenceModel({
                  ...sectionDetails.currentResidence,
                  address: new Address({
                    ...sectionDetails.currentResidence?.address,
                    zip: e.target.value,
                  }),
                })
              )
            }
          />
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <Input
            type="text"
            placeholder="MM/DD/YYYY"
            label="Move-in date"
            id="currentResidenceMoveInDate"
            name="currentResidenceMoveInDate"
            isRequired={true}
            value={sectionDetails.currentResidence?.moveInDate || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(
                "currentResidence",
                new ResidenceModel({
                  ...sectionDetails.currentResidence,
                  moveInDate: e.target.value,
                })
              )
            }
          />
        </div>
      </div>
      <hr className="mt-2 mb-6"></hr>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <Input
            type="text"
            label="Landlord's name"
            id="currentResidenceLandlordName"
            name="currentResidenceLandlordName"
            isRequired={false}
            value={
              sectionDetails.currentResidence?.landordDetails?.firstName || ""
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(
                "currentResidence",
                new ResidenceModel({
                  ...sectionDetails.currentResidence,
                  landordDetails: new PersonalDetailsModel({
                    ...sectionDetails.currentResidence?.landordDetails,
                    firstName: e.target.value,
                  }),
                })
              )
            }
          />
        </div>
        <div className="mb-6 lg:col-span-1">
          <PhoneInput
            label="Landlord's contact number"
            id="currentResidenceLandlord"
            name="currentResidenceLandlord"
            isRequired={false}
            phoneNumber={
              sectionDetails.currentResidence?.landordDetails?.phoneNumber
                ?.number || ""
            }
            countryCode={
              sectionDetails.currentResidence?.landordDetails?.phoneNumber
                ?.countryCode || ""
            }
            onPhoneNumberChange={(phoneNumber: string, countryCode: string) =>
              handleInputChange(
                "currentResidence",
                new ResidenceModel({
                  ...sectionDetails.currentResidence,
                  landordDetails: new PersonalDetailsModel({
                    ...sectionDetails.currentResidence?.landordDetails,
                    phoneNumber: new PhoneNumberModel(countryCode, phoneNumber),
                  }),
                })
              )
            }
            className="mt-1 p-2 w-full"
          />
        </div>
      </div>
      <hr className="mt-4 mb-8"></hr>
      <div className="flex justify-around">
        <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>

        <ButtonPrimary onClick={onNextClick}>Save & Continue</ButtonPrimary>
      </div>
    </div>
  );
};

export default ResidencesSection;
