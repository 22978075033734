import { useAuth0 } from "@auth0/auth0-react";
import { plainToClass } from "class-transformer";
import { HttpError, HttpErrorStatus } from "data/models/errors/http-error";
import { UserAccount } from "data/models/user-account";
import ListingRepository from "data/repositories/ListingRepository";
import PlacesRepository from "data/repositories/PlacesRepository";
import UserAccountRepository from "data/repositories/UserAccountRepository";
import { SearchParamsProvider } from "providers/SearchParamsProvider";
import { useUserAccount } from "providers/UserAccountProvider";
import React, { useEffect, useMemo } from "react";
import MyRouter from "routers/index";
import { ApiService } from "services/api.service";
import Hotjar from "@hotjar/browser";
import "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";

function App() {
  const { isAuthenticated, user, isLoading, getAccessTokenSilently } =
    useAuth0();

  const siteId = 3912922;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
  const { userAccount, setUserAccount } = useUserAccount();
  useMemo(async () => {
    const apiService = ApiService.getInstance();
    UserAccountRepository.getInstance(apiService);
    ListingRepository.getInstance(apiService);
    PlacesRepository.getInstance(apiService);
    const loadUser = async () => {
      if (isAuthenticated && user && !isLoading) {
        await getAccessTokenSilently().then(async (token) => {
          ApiService.getInstance().setToken(token);
          try {
            const userAccount =
              await UserAccountRepository.getInstance().getAccountByEmailId(
                user.email!
              );
            setUserAccount(userAccount);
          } catch (e) {
            const httpError = e as HttpError;
            if (httpError.status === HttpErrorStatus.NOT_FOUND) {
              // User account not found. Create a new account
              const userAccount =
                await UserAccountRepository.getInstance().createUserAccount(
                  plainToClass(UserAccount, user)
                );
              setUserAccount(userAccount);
            }
          }
        });
      }
    };
    await loadUser();
  }, [isLoading]);

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <SearchParamsProvider>
        <MyRouter />
      </SearchParamsProvider>
    </div>
  );
}
export default App;
