import React, { useEffect, useRef, useState } from "react";
import Stepper from "@mui/material/Stepper";
import { Step, StepContent, StepLabel } from "@mui/material";
import PersonalInformationSection from "./PersonalInformationSection";
import ProfessionalInformationSection from "./ProfessionalInformationSection";
import {
  RentalApplicationStepDataModel,
  RentalApplicationStepUIProps,
} from "../../data/models/rental-application/rental-application-step-model";
import AddressInformationSection from "./ResidencesSection";
import ReferencesSection from "./ReferencesSection";
import { PersonalDetailsModel } from "data/models/rental-application/personal-details-model";
import { ProfessionalDetailsModel } from "data/models/rental-application/professional-details-model";
import { ReferencesModel } from "data/models/rental-application/references-model";
import { RentalApplicationModel } from "../../data/models/rental-application/rental-application-model";
import AdditionalRoomatesSection from "./AdditionalRoomatesSection";
import { ResidencesModel } from "../../data/models/rental-application/residences-model";
import ReviewSection from "./ReviewSection";
import { AdditionalRoomatesModel } from "data/models/rental-application/additional-roomates-model";
import ListingRepository from "data/repositories/ListingRepository";
import { useNavigate, useParams } from "react-router-dom";
import RentalApplicationRepository from "data/repositories/RentalApplicationRepository";
import ApiService from "services/api.service";
import { useUserAccount } from "providers/UserAccountProvider";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { AiFillCheckCircle } from 'react-icons/ai';
import SignaturePad from "react-signature-canvas";
import Modal from "react-modal";import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
export interface SectionProps<T> {
  sectionDetails: T;
  setSectionDetails: Function;
  handleBack: () => void;
  handleNext: () => void;
}

const RentalApplicationForm: React.FC = () => {
  const { userAccount } = useUserAccount();
  const [rentalApplication, setRentalApplication] =
    useState<RentalApplicationModel>(
      new RentalApplicationModel({
        personalDetails: new PersonalDetailsModel({
          firstName: userAccount.firstName,
          lastName: userAccount.lastName,
          email: userAccount.email,
        }),
      })
    );
  const [activeStep, setActiveStep] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    navigate(`/search-results`)
    
  };
  const steps: RentalApplicationStepUIProps<any>[] = [
    {
      name: "Personal Information",
      shortName: "Personal Info",
      component: PersonalInformationSection,
      model: rentalApplication.personalDetails!,
      key: PersonalDetailsModel.key,
    },
    {
      name: "Professional Information",
      shortName: "Professional Info",
      component: ProfessionalInformationSection,
      model: rentalApplication.professionalDetails!,
      key: ProfessionalDetailsModel.key,
    },
    {
      name: "Current Residence",
      shortName: "Current Residence",
      component: AddressInformationSection,
      model: rentalApplication.residences!,
      key: ResidencesModel.key,
    },
    {
      name: "Additional Roomates",
      shortName: "Additional Roomates",
      component: AdditionalRoomatesSection,
      model: rentalApplication.additionalRoomates!,
      key: AdditionalRoomatesModel.key,
    },
    {
      name: "References",
      shortName: "References",
      component: ReferencesSection,
      model: rentalApplication.references!,
      key: ReferencesModel.key,
    },
    {
      name: "Review",
      shortName: "Review",
      component: ReviewSection,
      model: rentalApplication, // Pass the entire rental application model for review
      key: "review",
    },
  ];

  const { id } = useParams();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const sigCanvas = useRef<any>();
  const [openSignatureModel, setOpenSignatureModel] = useState(false);

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const listing = await ListingRepository.getInstance().getPropertyById(
          id!
        );
        setRentalApplication(
          new RentalApplicationModel({
            ...rentalApplication,
            listing: listing,
          })
        );
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchListing();
  }, []);

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      setOpenSignatureModel(true);

    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const saveSignatureAndSubmit = async () => {
    const base64Signature = sigCanvas.current.getCanvas();
    base64Signature.toBlob(async (blob: any) => {
      const fd = new FormData();
      fd.append("signature", blob, "signature.png");
      await RentalApplicationRepository.getInstance(
        ApiService.getInstance()
      ).saveSignature(userAccount.id!, fd);
      await submitRentalApplication();
    });
  };

  const submitRentalApplication = async () => {
    const apiService = ApiService.getInstance();

    // if (
    //   rentalApplication.additionalRoomates?.dohaveRoomates &&
    //   rentalApplication.additionalRoomates?.roomates.length > 0
    // ) {
    //   await RentalApplicationRepository.getInstance(
    //     apiService
    //   ).creategroupRentalApplication(
    //     Date.now().toString(),
    //     rentalApplication.additionalRoomates.roomates.map((r) => r.email!),
    //     userAccount.id!
    //   );
    // }

    await RentalApplicationRepository.getInstance(
      apiService
    ).submitCompletedRentalApplication(
      rentalApplication.toRentalApplicationDocumentJson(),
      userAccount.id!
    );

    setOpenSignatureModel(false);

    setDialogOpen(true);
  };

  return (
    <div className="m-4 p-4 lg:p-20 lg:m-10 flex flex-col">
      <div className="mb-10">
        <h1 className="text-xl lg:text-3xl font-semi-bold">
          Rental Application
        </h1>
        <p className="my-3 text-sm text-gray-500">
          {rentalApplication.listing?.address?.line1},{" "}
          {rentalApplication.listing?.address?.city},{" "}
          {rentalApplication.listing?.address?.state},{" "}
          {rentalApplication.listing?.address?.zip}
        </p>
        <button
          className="rounded-full px-4 py-1.5 border text-sm"
          onClick={() => {
            window.open(
              `/listing-detail/${rentalApplication.listing?.id}`,
              "_blank"
            );
          }}
        >
          View Listing
        </button>
      </div>

      <div className="flex flex-col lg:flex-row">
        {/* Show Stepper on larger screens */}
        <div className="hidden lg:block lg:mr-10 w-[200px]">
          <Stepper orientation="vertical" activeStep={activeStep}>
            {steps.map((step) => (
              <Step key={step.shortName}>
                <StepLabel>{step.shortName}</StepLabel>
                <StepContent>{/* Content for each step */}</StepContent>
              </Step>
            ))}
          </Stepper>
        </div>

        <div className="lg:border lg:rounded-xl lg:p-10 w-full">
          <h1 className="text-lg lg:text-xl font-semi-bold">
            {steps[activeStep].name}
          </h1>
          <hr className="my-5"></hr>
          {loading && (
              <div className="flex justify-center items-center h-screen">
                {/* Display loading indicator here */}
                <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary-green"></div>
              </div>
            )}
          {!loading && steps[activeStep].component &&
            React.createElement(steps[activeStep].component, {
              handleBack: handleBack,
              handleNext: handleNext,
              sectionDetails: steps[activeStep].model,
              setSectionDetails: (
                sectionDetails: RentalApplicationStepDataModel
              ) => {
                setRentalApplication(
                  new RentalApplicationModel({
                    ...rentalApplication,
                    [steps[activeStep].key]: sectionDetails,
                  })
                );
              },
            })}
        </div>

        <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle className="flex items-center">Application Submitted! <AiFillCheckCircle className="ml-2" color="primary"  /></DialogTitle>
        <DialogContent>
          <DialogContentText>
          You're application has been submitted. Please wait for response from our support team.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
        </Dialog>
        
        <Modal
        isOpen={openSignatureModel}
        appElement={document.getElementById("root") || undefined}
        onRequestClose={() => setOpenSignatureModel(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            position: "absolute",
            height: "480px",
            width: "100v",
            margin: "auto",
            padding: "3px",
            background: "white",
            border: "1px solid #ccc",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
          },
        }}
        contentLabel="Sign Application"
      >
        <div className="flex items-center justify-center flex-col align-middle">
          <h2 className="text-xl m-5 text-center text-gray-700">
            Sign Application <span className="text-red-500">*</span>
          </h2>

          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              className:
                "w-[90%] h-[300px] border border-2 border-dotted border-gray-700",
            }}
          />
        </div>

        <div className="my-5 flex justify-around">
          <ButtonSecondary onClick={clearSignature}>Clear</ButtonSecondary>

          <ButtonPrimary onClick={saveSignatureAndSubmit}>Submit</ButtonPrimary>
        </div>
      </Modal>
        {/* Show MobileStepper on smaller screens */}
        {/* <div className="lg:hidden">
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            nextButton={
              <ButtonPrimary
                onClick={handleNext}
                disabled={activeStep === steps.length - 1}
              >
                Save and Continue
              </ButtonPrimary>
            }
            backButton={
              <ButtonSecondary onClick={handleBack} disabled={activeStep === 0}>
                Back
              </ButtonSecondary>
            }
          />
        </div> */}
      </div>
    </div>
  );
};

export default RentalApplicationForm;
