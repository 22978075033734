import React, { FC } from "react";
import SectionAboutUsHeader from "components/SectionAboutUsHeader/SectionAboutUsHeader";
import SectionWhyLanding from "components/SectionWhyLanding/SectionWhyLanding";
import SectionOurTeam from "components/SectionOurTeam/SectionOurTeam";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      {/* ======== BG GLASS ======== */}
      {/* <BgGlassmorphism /> */}

      <div className="container py-10 lg:py-14 space-y-16 lg:space-y-28">
        {/* <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We help international students land in the United States by
          providing housing, roommate matching services and
          much more!"
        />

        <SectionFounder /> */}

        <SectionAboutUsHeader />

        <SectionWhyLanding />

        <SectionOurTeam />

        {/* <PageContact /> */}
      </div>
    </div>
  );
};

export default PageAbout;
