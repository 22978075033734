import { RequiredValidator } from "../form-validators/required-validator";
import { RentalApplicationStepDataModel } from "./rental-application-step-model";

export class ProfessionalDetailsModel extends RentalApplicationStepDataModel {
  id?: string;
  currentOccupation?: Occupation;
  formerOccupation?: Occupation;
  monthlyIncome?: number;
  incomeSource?: string = "Loans";

  static get key() {
    return "professionalDetails";
  }

  constructor(data?: Partial<ProfessionalDetailsModel>) {
    super();
    this.validationMap = new Map(
      Object.entries({
        currentOccupation: {
          validators: [new RequiredValidator()],
          errors: [],
          getValue: (currentModel: ProfessionalDetailsModel) => {
            return currentModel.currentOccupation;
          },
        },
        incomeSource: {
          validators: [new RequiredValidator()],
          errors: [],
          getValue: (currentModel: ProfessionalDetailsModel) => {
            return currentModel.incomeSource;
          },
        },
        monthlyIncome: {
          validators: [new RequiredValidator()],
          errors: [],
          getValue: (currentModel: ProfessionalDetailsModel) => {
            return currentModel.monthlyIncome;
          },
        },
        formerOccupation: {
          validators: [],
          errors: [],
          getValue: (currentModel: ProfessionalDetailsModel) => {
            return currentModel.formerOccupation;
          },
        },
      })
    );
    if (data) {
      Object.assign(this, data);
    }
  }

  toRentalApplicationDocumentJson(data: any) {
    data.current_employer_occupation = this.currentOccupation!;
    data.former_employer_occupation = this.formerOccupation!;
    data.income_source = this.incomeSource!;
    data.current_employer_salary = this.monthlyIncome!;
    return data;
  }
}

export enum Occupation {
  EMPLOYED = "Employed",
  STUDENT = "Student",
  UNEMPLOYED = "Unemployed",
  NONE = "None",
}
