import { RequiredValidator } from "../form-validators/required-validator";
import { PersonalDetailsModel } from "./personal-details-model";
import { RentalApplicationStepDataModel } from "./rental-application-step-model";

export class AdditionalRoomatesModel extends RentalApplicationStepDataModel {
  id?: string;
  dohaveRoomates: boolean = true;
  roomates: PersonalDetailsModel[] = [new PersonalDetailsModel()];
  constructor(data?: Partial<AdditionalRoomatesModel>) {
    super();
    this.validationMap = new Map();
    if (data) {
      Object.assign(this, data);
    }
  }

  static get key() {
    return "additionalRoomates";
  }
  validate(): void {
    this.validationMap = new Map();

    if (this.dohaveRoomates) {
      this.roomates.forEach((roomate, index) => {
        for (const entry of ["firstName", "lastName", "email"]) {
          this.validationMap.set(`${index}${entry}`, {
            validators: [new RequiredValidator()],
            getValue: (currentModel: AdditionalRoomatesModel) => {
              const personalDetails = currentModel.roomates[
                index
              ] as PersonalDetailsModel;
              return personalDetails[entry as keyof PersonalDetailsModel];
            },

            errors: [],
          });
        }
        this.validationMap.set(`${index}phoneNumber`, {
          validators: [new RequiredValidator()],
          getValue: (currentModel: AdditionalRoomatesModel) => {
            const personalDetails = currentModel.roomates[
              index
            ] as PersonalDetailsModel;
            return personalDetails.phoneNumber?.countryCode &&
              personalDetails.phoneNumber.countryCode &&
              personalDetails.phoneNumber.number
              ? personalDetails.phoneNumber.number
              : "";
          },

          errors: [],
        });
      });
      Array.from(this.validationMap.keys()).forEach((key) => {
        this.validationMap.get(key)!.errors = [];
        this.validationMap.get(key)!.validators.forEach((validator: any) => {
          if (!this.dohaveRoomates) {
            return;
          }

          const error = validator.validate(
            this.validationMap.get(key).getValue(this)
          );
          if (error) {
            this.validationMap.get(key)!.errors.push(error);
          }
        });
      });
    }
  }

  toRentalApplicationDocumentJson(data: any) {
    const roomates = this.dohaveRoomates ? this.roomates : [];
    data.no_of_occupant = roomates.length;
    data.no_of_adult = roomates.length;
    data.co_tenants = roomates.map((e) => e.firstName + ' ' + e.lastName).join(", ");
    return data;
  }
}
