import React, { ChangeEvent, useState } from "react";
import Input from "shared/Input/Input";
import { SectionProps } from "./RentalApplicationForm";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { AdditionalRoomatesModel } from "data/models/rental-application/additional-roomates-model";
import {
  PersonalDetailsModel,
  PhoneNumberModel,
} from "data/models/rental-application/personal-details-model";
import { FaPlus, FaTrash } from "react-icons/fa";
import PhoneInput from "shared/Input/PhoneInput";

const AdditionalRoomatesSection: React.FC<
  SectionProps<AdditionalRoomatesModel>
> = ({ sectionDetails, setSectionDetails, handleBack, handleNext }) => {
  const [validationErrorMap, setValidatorErrorMap] = useState<
    Map<string, string[]>
  >(sectionDetails.getValidatonErrorMap());
  const handleInputChange = (
    field: keyof PersonalDetailsModel,
    value: any,
    index: number
  ) => {
    setSectionDetails(
      new AdditionalRoomatesModel({
        ...sectionDetails,
        roomates: sectionDetails.roomates.map((roomate, i) => {
          return index !== i
            ? roomate
            : new PersonalDetailsModel({
                ...roomate,
                [field]: value,
              });
        }),
      })
    );
  };

  const onNextClick = () => {
    if (sectionDetails.isValid()) {
      handleNext();
    } else {
      setValidatorErrorMap(sectionDetails.getValidatonErrorMap());
    }
  };

  const renderAdditionalRoomatesSection = (
    personalDetailsModel: PersonalDetailsModel,
    index: number
  ) => {
    return (
      <div key={index}>
        <div className="flex justify-between items-center my-5">
          <h5 className=" text-gray-500">OCCUPANT {index + 1}</h5>
          {index > 0 && (
            <Button
              variant="text"
              color="error"
              startIcon={<FaTrash />}
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                setSectionDetails(
                  new AdditionalRoomatesModel({
                    ...sectionDetails,
                    roomates: sectionDetails.roomates.filter((roomate, i) => {
                      return i !== index;
                    }),
                  })
                );
              }}
            >
              Delete
            </Button>
          )}
        </div>

        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="mb-6 lg:col-span-1">
            <Input
              type="text"
              label="First Name"
              id="firstName"
              name="firstName"
              isRequired={true}
              error={
                validationErrorMap.get(index + "firstName")?.length
                  ? validationErrorMap.get(index + "firstName")?.[0]
                  : undefined
              }
              className="mt-1 p-2 w-80"
              placeholder="Enter your first name"
              value={personalDetailsModel.firstName || ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange("firstName", e.target.value, index)
              }
            />
          </div>

          <div className="mb-6 lg:col-span-1">
            <Input
              type="text"
              label="Last Name"
              id="lastName"
              name="lastName"
              isRequired={true}
              error={
                validationErrorMap.get(index + "lastName")?.length
                  ? validationErrorMap.get(index + "lastName")?.[0]
                  : undefined
              }
              className="mt-1 p-2 w-80"
              placeholder="Enter your last name"
              value={personalDetailsModel.lastName || ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange("lastName", e.target.value, index)
              }
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="mb-6 lg:col-span-1">
            <Input
              type="text"
              label="Email"
              name="email"
              isRequired={true}
              error={
                validationErrorMap.get(index + "email")?.length
                  ? validationErrorMap.get(index + "email")?.[0]
                  : undefined
              }
              className="mt-1 p-2 w-80"
              value={personalDetailsModel.email || ""}
              placeholder="Enter your email"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange("email", e.target.value, index)
              }
            />
          </div>

          <div className="mb-6 lg:col-span-1">
            <PhoneInput
              label="Contact"
              id="phoneNumber"
              name="phoneNumber"
              isRequired={true}
              className="mt-1 p-2 w-full"
              error={
                validationErrorMap.get(index + "phoneNumber")?.length
                  ? validationErrorMap.get(index + "phoneNumber")?.[0]
                  : undefined
              }
              countryCode={
                personalDetailsModel?.phoneNumber!.countryCode
                  ? personalDetailsModel.phoneNumber?.countryCode
                  : ""
              }
              phoneNumber={
                personalDetailsModel?.phoneNumber!.number
                  ? personalDetailsModel.phoneNumber?.number
                  : ""
              }
              onPhoneNumberChange={(
                phoneNumber: string,
                countryCode: string
              ) => {
                handleInputChange(
                  "phoneNumber",
                  new PhoneNumberModel(countryCode, phoneNumber),
                  index
                );
              }}
            />
          </div>
        </div>

        <hr className="my-5"></hr>
      </div>
    );
  };

  return (
    <div className="">
      <label
        htmlFor="currentOccupation"
        className="block text-sm font-medium text-gray-600"
      >
        Will you live with any other adults?
      </label>
      <FormControl>
        <RadioGroup
          row
          name=""
          value={sectionDetails.dohaveRoomates ? "Yes" : "No"}
          onChange={(e) => {
            setSectionDetails(
              new AdditionalRoomatesModel({
                ...sectionDetails,
                dohaveRoomates: e.target.value === "Yes" ? true : false,
              })
            );
          }}
        >
          <FormControlLabel
            key="Yes"
            value={"Yes"}
            control={<Radio />}
            label={"Yes"}
          />
          <FormControlLabel
            key="No"
            value={"No"}
            control={<Radio />}
            label={"No"}
          />
        </RadioGroup>
      </FormControl>

      <hr className="my-5"></hr>
      {sectionDetails.dohaveRoomates &&
        sectionDetails.roomates.map((personalDetailsModel, index) => {
          return renderAdditionalRoomatesSection(personalDetailsModel, index);
        })}

      {sectionDetails.dohaveRoomates && (
        <div>
          <Button
            variant="text"
            startIcon={<FaPlus />}
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              setSectionDetails(
                new AdditionalRoomatesModel({
                  ...sectionDetails,
                  roomates: [
                    ...sectionDetails.roomates,
                    new PersonalDetailsModel(),
                  ],
                })
              );
            }}
          >
            Add Occupant
          </Button>
          <hr className="my-5"></hr>
        </div>
      )}

      <div className="flex justify-around">
        <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>
        <ButtonPrimary onClick={onNextClick}>Save & Continue</ButtonPrimary>
      </div>
    </div>
  );
};

export default AdditionalRoomatesSection;
