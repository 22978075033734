import React from "react";
import SectionInsurentHero from "./SectionInsurentHero";
import SectionInsurentHero2 from "./SectionInsurentHero2";
import SectionInsurentAbout from "./SectionInsurentAbout";
import SectionInsurentHowItWork from "./SectionInsurentHowItWorks";
import SectionInsurentApartmentsInOurNetwork from "./SectionInsurentApartmentsInOurNetwork";
import SectionInsurentFAQs from "./SectionInsurentFAQs";
import hiw1 from "images/graphics/hiw1.png"
import hiw2 from "images/graphics/hiw2.png"
import hiw3 from "images/graphics/hiw3.png"
import hiw4 from "images/graphics/hiw4.png"
import hiw5 from "images/graphics/hiw5.png"
import step1 from "images/graphics/step1.png"
import step2 from "images/graphics/step2.png"
import step3 from "images/graphics/step3.png"
import step4 from "images/graphics/step4.png"
import step5 from "images/graphics/step5.png"


const PageInsurent = () => {
    return (
        <div
            className={`nc-PageInsurent relative`}
            data-nc-id="PageInsurent"
        >
            <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
                <SectionInsurentHero />
                <SectionInsurentHero2 />
                <SectionInsurentAbout />
                <SectionInsurentHowItWork
                    data1={[
                        {
                            id: 1,
                            img: hiw1,
                            step: step1,
                            title: "Application Form",
                            desc: "Fill out the lease guarantee application online and send us your paperwork. It’s easy, fast, and most importantly, secure.",
                        },
                        {
                            id: 2,
                            img: hiw2,
                            step: step2,
                            title: "Income Verification & Identity",
                            desc: "Submit copies of your two most recent pay stubs or a letter from your new employer, and a copy of your Passport and Visa.",
                        },
                        {
                            id: 3,
                            img: hiw3,
                            step: step3,
                            title: "Self-Employed/Non Employed With Cash Liquid Assets",
                            desc: "Submit copy of last year’s tax return or accountant’s letter and a copy of your Passport. Submit a copy of Bank / Brokerage statement.",
                        },
                    ]}
                    data2={[
                        {
                            id: 1,
                            img: hiw4,
                            step: step4,
                            title: "US and International Students",
                            desc: "Submit a copy of Passport, Visa and School ID and parent’s passport and income verification or bank letter.",
                        },
                        {
                            id: 2,
                            img: hiw5,
                            step: step5,
                            title: "Done! Get your Certificate!",
                            desc: "We usually prequalify within 30 minutes and send Qualification Certificates and issue the Guarantee within 24 hours.",
                        },
                    ]}
                />
                <SectionInsurentApartmentsInOurNetwork />
                {/* <SectionInsurentFAQs /> */}
            </div>
        </div>
    );
}

export default PageInsurent;