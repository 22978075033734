// FilterModal.tsx
import React from "react";
import FiltersComponent from "./FiltersComponent";
import { SearchParams } from "./Header/SearchBar";
import { FaTimes } from "react-icons/fa";
import Modal from "react-modal";

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedSearchParams: SearchParams;
  onFilterClick: (filterType: string, value: string) => void;
  onClearFilter: () => void;
  onUpdateFilter: () => void;
  onPriceChange: (price: number | number[]) => void;
  tabs: string[];
  tabActive: string;
}

const FilterModal: React.FC<FilterModalProps> = ({
  isOpen,
  onClose,
  selectedSearchParams,
  onFilterClick,
  onClearFilter,
  onUpdateFilter,
  onPriceChange,
  tabActive,
  tabs,
}) => {
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-[100] bg-white dark:bg-neutral-900 rounded-t-md p-4 overflow-y-auto">
          <div className="flex justify-end p-4">
            <button onClick={onClose}>
              <FaTimes>Close</FaTimes>
            </button>
          </div>
          <div className="">
            <FiltersComponent
              selectedSearchParams={selectedSearchParams}
              onFilterClick={onFilterClick}
              onClearFilter={onClearFilter}
              onUpdateFilter={onUpdateFilter}
              onPriceChange={onPriceChange}
              tabActive={tabActive}
              tabs={tabs}
              onClose={onClose}
            />{" "}
          </div>
        </div>
      )}
    </>
  );
};

export default FilterModal;
