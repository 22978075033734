import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import rect1 from "images/Rectangle 56.png"
import rect2 from "images/Rectangle 57.png"
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface PageResourcesProps {
    className?: string;
}

const PageResources: FC<PageResourcesProps> = ({ className = "" }) => {
    return (
        <div className="nc-PageResources relative" data-nc-id="PageResources">
            <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
                <div className="my-10 md:my-16">
                    <Heading isCenter desc="Everything you need to know before you move in.">Resources</Heading>
                </div>
                <div className="my-10">
                    <div className="grid md:grid-cols-3 bg-green-50 p-16 rounded-3xl">
                        <div className="p-4">
                            <div className="grid items-center justify-center">
                                <img src={rect1} alt="" />
                            </div>
                            <div className="text-center">
                                <h2 className="font-semibold text-xl my-2">Travelling Guide for beginners</h2>
                                <p>Everything you need to know for your first trip as an international student.</p>
                            </div>
                            <div className="grid items-center justify-center my-5">
                                <ButtonPrimary><i className="las la-arrow-circle-down text-xl mx-2"></i> Download PDF</ButtonPrimary>
                            </div>
                        </div>
                        <div className="p-4">
                            <div className="grid items-center justify-center">
                                <img src={rect2} alt="" />
                            </div>
                            <div className="text-center">
                                <h2 className="font-semibold text-xl my-2">Purchase Guide for International Students</h2>
                                <p>Furnish your new apartment based on your comfort levels.</p>
                            </div>
                            <div className="grid items-center justify-center my-5">
                                <ButtonPrimary><i className="las la-arrow-circle-down text-xl mx-2"></i> Download PDF</ButtonPrimary>
                            </div>
                        </div>
                        <div className="p-4">
                            <div className="grid items-center justify-center">
                                <img src={rect1} alt="" />
                            </div>
                            <div className="text-center">
                                <h2 className="font-semibold text-xl my-2">Travelling Guide for beginners</h2>
                                <p>Everything you need to know for your first trip as an international student.</p>
                            </div>
                            <div className="grid items-center justify-center my-5">
                                <ButtonPrimary><i className="las la-external-link-alt text-xl mx-2"></i> Open Article</ButtonPrimary>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageResources;