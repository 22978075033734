import { Transform, Type } from "class-transformer";

export class Property {
  @Type(() => Date)
  createDate?: Date;

  @Type(() => Date)
  modifyDate?: Date;

  id?: string;
  title?: string;
  description?: string;
  bath?: number;
  bed?: number;
  rent?: number;

  area?: number;

  @Transform(
    ({ value }) =>
      value!.charAt(0).toUpperCase() + value!.slice(1).toLowerCase()
  )
  listingType?: string;
  status?: string;

  @Type(() => Date)
  availabilityDate?: Date;

  @Type(() => Date)
  postingDate?: Date;

  @Type(() => Attachment)
  attachments?: Attachment[];

  address?: Address;
  amenities?: string[];
  rentInformation?: Rent;
  fees?:string;
  externalId?:string
}

export class Address {
  @Type(() => Date)
  createDate?: Date;

  @Type(() => Date)
  modifyDate?: Date;
  id?: string;
  line1?: string;
  city?: string;
  state?: string;
  country?: string;
  lat?: string;
  lon?: string;
  zip?: string;
  walkScoreResponse?: WalkScoreResponse;
  addressType?: string;
  line2?: string;
  neighbourhood?: string;

  constructor(data?: Partial<Address>) {
    if (data) {
      Object.assign(this, data);
    }
  }

  convertToString(): string {
    let addressInString = '';
    if (this.line1 != null) {
        addressInString += this.line1 + ', ';
    }
    if (this.line2 != null) {
        addressInString += this.line2 + ', ';
    }
    if (this.city != null) {
        addressInString += this.city + ', ';
    }
    if (this.state != null) {
        addressInString += this.state + ', ';
    }
    if (this.country != null) {
        addressInString += this.country + ', ';
    }
    if (this.zip != null) {
        addressInString += this.zip;
    }
    return addressInString;
  }
}

export class WalkScoreResponse {
  status?: number;
  walkscore?: number;
  description?: string;
  logo_url?: string;
  more_info_icon?: string;
  more_info_link?: string;
  ws_link?: string;
  snapped_lat?: string;
  snapped_lon?: string;
  transit?: Transit;
  bike?: Bike;
}

export class Rent{
  brokerFees?: number;
  firstMonth?: number;
  lastMonth?: number;
  securityDeposit?: number;
}

export class Bike {
  score?: number;
  description?: string;
}

export class Transit {
  score?: number;
  description?: string;
  summary?: string;
}

export class Attachment {
  id?: string;
  @Transform(({ value }) => {
    return value.replace(
      "students-landing.static-content.s3.amazonaws.com",
      "images-students-landing.gumlet.io"
    );
  })
  link?: string;

  type?: AttachmentType;
}

export enum AttachmentType {
  Image = "IMAGE",
  Video = "VIDEO",
  I20 = "I20",
  Passport = "PASSPORT",
}

export class PropertyPaginatedResponse {
  @Type(() => Property)
  items?: Property[];
  pages?: PageDetails;
}

export class PageDetails {
  current?: number;
  total?: number;
  items?: number;
  size?: number;
  maxSize?: number;
}