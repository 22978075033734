import { FC, useEffect, useState } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import StayCardH from "components/StayCardH/StayCardH";
import GoogleMapReact from "google-map-react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Property } from "data/models/property";
import Skeleton from "react-loading-skeleton";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import ListingRepository from "data/repositories/ListingRepository";
import { useListingSearchParams } from "providers/SearchParamsProvider";

export interface SectionGridHasMapProps { }

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [properties, setProperties] = useState<Property[]>([]);
  const [mapViewProperties, setMapViewProperties] = useState<Property[]>([]);
  const navigate = useNavigate();
  const { searchParams, setSearchParams } = useListingSearchParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const propertyPaginatedResponse =
          await ListingRepository.getInstance().getPaginatedProperties();

        const listings = propertyPaginatedResponse.items!;
        setMapViewProperties(listings);
        setProperties(listings.slice(0, 4));
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);

  const handleClick = () => {
    setSearchParams({
      city: "Boston",
      university: null,
      beds: null,
      useExactMatchForBeds: null,
      baths: null,
      moveInDate: null,
      maxRent: "10000",
      minRent: "0",
      sortBy: "priceAsc",
      neighborhood: null,
      address: null,
    });
    navigate(`/search-results`);
  };

  const renderPropertyListView = () => {
    return properties.map((property) => (
      <div
        key={property.id}
        onMouseEnter={() => setCurrentHoverID((_) => property.id!)}
        onMouseLeave={() => setCurrentHoverID((_) => -1)}
      >
        <StayCardH property={property} />
      </div>
    ));
  };

  const renderPropertyMapView = () => {
    return (
      <GoogleMapReact
        defaultZoom={13}
        defaultCenter={{
          lat: +mapViewProperties[0]?.address?.lat!,
          lng: +mapViewProperties[0]!.address?.lon!,
        }}
        bootstrapURLKeys={{
          key: "AIzaSyBKAfYPRG77UvTMaEPEkl2_KvZeRKzuZlA",
        }}
        yesIWantToUseGoogleMapApiInternals
      >
        {mapViewProperties.map((property) => (
          <AnyReactComponent
            isSelected={currentHoverID === property.id}
            key={property.id}
            lat={+property!.address!.lat!}
            lng={+property!.address!.lon!}
            listing={property}
          />
        ))}
      </GoogleMapReact>
    );
  };

  return (
    <div>
      <div className="relative flex min-h-screen">
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-[780px] flex-shrink-0 xl:pr-8">
          <div className="grid grid-cols-1 gap-8">
            {properties && properties.length > 1 ? (
              renderPropertyListView()
            ) : (
              <Skeleton count={20} />
            )}
          </div>
          <div className="flex mt-16 justify-center items-center">
            {/* <Pagination /> */}
            <ButtonPrimary onClick={handleClick}>View More</ButtonPrimary>
          </div>
        </div>

        {/* {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-20 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )} */}

        {/* MAPPPPP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
            }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            {/* <div className="absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white dark:bg-neutral-800 shadow-xl z-10 rounded-2xl min-w-max">
              <Checkbox
                className="text-xs xl:text-sm"
                name="xx"
                label="Search as I move the map"
              />
            </div> */}
            {properties && properties.length ? (
              renderPropertyMapView()
            ) : (
              <Skeleton count={20} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
