// FiltersComponent.tsx
import React, { useMemo, useState } from "react";
import Slider from "@mui/material/Slider";
import DatePicker from "react-datepicker";
import { SearchParams } from "./Header/SearchBar";
import { universities } from "./Header/UniversityModal";
import moment from "moment";
import { Checkbox, FormControlLabel } from "@mui/material";
import { HttpError } from "data/models/errors/http-error";
import PlacesRepository from "data/repositories/PlacesRepository";
import ApiService from "services/api.service";

export interface FiltersProps {
  selectedSearchParams: SearchParams;
  onClose: () => void;
  onFilterClick: (filterType: string, value: string) => void;
  onClearFilter: () => void;
  onUpdateFilter: () => void;
  onPriceChange: (price: number | number[]) => void;
  tabs: string[];
  tabActive: string;
}

const FiltersComponent: React.FC<FiltersProps> = ({
  selectedSearchParams,
  onFilterClick,
  onClearFilter,
  onPriceChange,
  tabActive,
  onClose,
}) => {
  const beds = ["0", "1", "2", "3", "4", "5"];
  const baths = ["1", "1.5", "2", "2.5", "3"];
  const [tabActiveState, setTabActiveState] = useState(tabActive);
  const [neighborhoods, setNeighborhoods] = useState<string[]>([]);
  const [trucatedNeighbourhoodShowLess, setTruncatedNeighbourhoodShowLess] = useState<string[]>([]);
  const [trucatedNeighbourhoodShowMore, setTruncatedNeighbourhoodShowMore] = useState<string[]>([]);
  const [toggleValue, setToggleValue] = useState(1);
  const [priceMin, setPriceMin] = useState(
    selectedSearchParams && selectedSearchParams.minRent
      ? selectedSearchParams.minRent
      : 0
  );
  const [priceMax, setPriceMax] = useState(
    selectedSearchParams && selectedSearchParams.maxRent
      ? selectedSearchParams.maxRent
      : 10000
  );
  const getBeds = (value: string, useExactMatch: string | null) => {
    return useExactMatch === "true" ? value : value + "+";
  };

  useMemo(() => {
    const fetchNeighborhoods = async () => {
      try {
        const jsonResponse = await PlacesRepository.getInstance(
          ApiService.getInstance()
        ).getAllNeighborhoods();
        setNeighborhoods(jsonResponse);
        setTruncatedNeighbourhoodShowLess(jsonResponse.slice(0, 11));
        setTruncatedNeighbourhoodShowMore(jsonResponse.slice(0, 36));
      } catch (error) {
        const httpError = error as HttpError;
        throw httpError;
      }
    };
    fetchNeighborhoods();
  }, []);

  const handleSliderPriceChange = (price: number | number[]) => {
    const priceRange = price as [number, number];
    setPriceMin(priceRange[0]);
    setPriceMax(priceRange[1]);
  };

  const handleShowMoreAndShowLess = () => {
    switch (toggleValue) {
      case 1:
        setToggleValue(2);
        break;
      case 2:
        setToggleValue(3);
        break;
      case 3:
        setToggleValue(1);
        break;
      default:
        setToggleValue(1);
    }
  }

  return (
    <div className="">
      <div className="p-3">
        {/* University Filter */}
        <div className="mb-10">
          <h3 className="text-lg font-semibold mb-2">Universities</h3>
          {/* Assuming universities is imported from another file */}
          {/* Replace with your actual data source */}
          {universities[selectedSearchParams?.city!].map(
            (university, index) => (
              <button
                key={index}
                onClick={() => onFilterClick("university", university.name)}
                className={`rounded-full px-4 py-2 mr-2 mb-3 ${selectedSearchParams.university === university.name
                  ? "bg-primary-green text-white"
                  : "bg-transparent border border-gray-400"
                  }`}
              >
                {university.displayName}
              </button>
            )
          )}
        </div>

        <hr className="border-t mb-4" />

        {/* Beds Filter */}
        <div className="mb-10">
          <h3 className="text-lg font-semibold mb-2">Beds</h3>
          {beds.map((bed, index) => (
            <button
              key={index}
              onClick={() => onFilterClick("beds", bed)}
              className={`rounded-full px-4 py-1 mr-2 mb-2 ${selectedSearchParams.beds === bed
                ? "bg-primary-green text-white"
                : "bg-transparent border border-gray-400"
                }`}
            >
              {bed === "0"
                ? "Studio"
                : getBeds(bed, selectedSearchParams.useExactMatchForBeds)}
            </button>
          ))}

          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedSearchParams.useExactMatchForBeds === "true"}
                  onChange={(e) => {
                    onFilterClick(
                      "useExactMatchForBeds",
                      e.target.checked === true ? "true" : "false"
                    );
                  }}
                />
              }
              label="Use exact match"
            />
          </div>
        </div>

        {/* Baths Filter */}
        <div className="mb-10">
          <h3 className="text-lg font-semibold mb-2">Baths</h3>
          {baths.map((bath, index) => (
            <button
              key={index}
              onClick={() => onFilterClick("baths", bath)}
              className={`rounded-full px-4 py-1 mr-2 mb-2 ${selectedSearchParams.baths === bath
                ? "bg-primary-green text-white"
                : "bg-transparent border border-gray-400"
                }`}
            >
              {bath + "+"}
            </button>
          ))}
        </div>

        <hr className="border-t mb-4" />

        <div className="mb-10">
          <h3 className="text-lg font-semibold mb-2">Neighborhood</h3>
          {/* Assuming universities is imported from another file */}
          {/* Replace with your actual data source */}
          {
            toggleValue === 1 &&
            trucatedNeighbourhoodShowLess.map((neighborhood, index) => (
              <button
                key={index}
                onClick={() =>
                  onFilterClick("neighborhood", neighborhood)
                }
                className={`rounded-lg px-4 py-2 mr-2 mb-3 ${selectedSearchParams.neighborhood === neighborhood
                  ? "bg-primary-50 text-primary-green border-2 border-primary-green"
                  : "bg-transparent border border-gray-400"
                  }`}
              >
                {neighborhood}
              </button>
            ))
          }
          {
            toggleValue === 2 &&
            trucatedNeighbourhoodShowMore.map((neighborhood, index) => (
              <button
                key={index}
                onClick={() =>
                  onFilterClick("neighborhood", neighborhood)
                }
                className={`rounded-lg px-4 py-2 mr-2 mb-3 ${selectedSearchParams.neighborhood === neighborhood
                  ? "bg-primary-50 text-primary-green border-2 border-primary-green"
                  : "bg-transparent border border-gray-400"
                  }`}
              >
                {neighborhood}
              </button>
            ))
          }
          {
            toggleValue === 3 &&
            neighborhoods.map((neighborhood, index) => (
              <button
                key={index}
                onClick={() =>
                  onFilterClick("neighborhood", neighborhood)
                }
                className={`rounded-lg px-4 py-2 mr-2 mb-3 ${selectedSearchParams.neighborhood === neighborhood
                  ? "bg-primary-50 text-primary-green border-2 border-primary-green"
                  : "bg-transparent border border-gray-400"
                  }`}
              >
                {neighborhood}
              </button>
            ))
          }
          <div>
            <span
              className="cursor-pointer m-1 text-primary-green"
              onClick={handleShowMoreAndShowLess}
            >
              {toggleValue === 1 && "Show more"}
              {toggleValue === 2 && "Show all"}
              {toggleValue === 3 && "Show less"}
            </span>
          </div>
        </div>

        <hr className="border-t mb-4" />

        {/* Price Filter */}
        <div className="mb-10">
          <h3 className="text-lg font-semibold mb-2">Monthly Rent</h3>
          <Slider
            min={0}
            max={10000}
            value={[priceMin ? +priceMin : 0, priceMax ? +priceMax : 10000]}
            onChangeCommitted={(e, newValue: number | number[]) => {
              const priceRange = newValue as [number, number];

              onPriceChange([priceRange[0], priceRange[1]]);
            }}
            onChange={(e, newValue) => handleSliderPriceChange(newValue)}
          />
          <div className="flex justify-between mt-2">
            <span>${selectedSearchParams.minRent}</span>
            <span>${selectedSearchParams.maxRent}</span>
          </div>
        </div>

        <hr className="border-t mb-4" />

        {/* Move-in Date section */}
        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2">Move-in Date</h2>
          <DatePicker
           
            placeholderText={"Move-in Date"}
            selected={
              selectedSearchParams?.moveInDate
                ? moment(selectedSearchParams?.moveInDate).toDate()
                : null
            }
            onChange={(date) => {
              onFilterClick("moveInDate", moment(date).format("YYYYMMDD")!);
            }}
            className="filter-date-picker w-[220px]"
            fixedHeight
          />
        </div>
      </div>
      <div className="flex justify-between p-5 mt-5 bg-white dark:bg-neutral-900 z-1000">
        <button
          onClick={() => {
            onClearFilter();
            onClose();
          }}
          className="text-sm text-gray-600 border rounded-full px-4 py-2 dark:text-neutral-200"
        >
          Clear
        </button>
        <button
          onClick={onClose}
          className="text-sm bg-primary-green text-white px-4 py-2 rounded-full hover:bg-opacity-90"
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default FiltersComponent;
