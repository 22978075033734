import Logo from "shared/Logo/Logo";
import React from "react";
import AppStoreButtons from "components/AppStoreButtons";
import { Link, useNavigate } from "react-router-dom";
import img from "images/graphics/equal_housing-opp.png";
import logoImg from "images/logo.png";
import { useListingSearchParams } from "providers/SearchParamsProvider";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const { searchParams, setSearchParams } = useListingSearchParams();

  return (
    <div className="nc-Footer relative  border-t border-neutral-200 dark:border-neutral-700">
      <div className="relative text-center sm:text-left grid md:grid-cols-4 gap-20 pt-20 pr-20 pl-20 pb-10">
        <div className="p-3">
          <h3 className="font-semibold text-lg mb-6">Quick Search</h3>
          <span
            className="cursor-pointer"
            onClick={() => {
              setSearchParams({
                city: "Boston",
                university: null,
                beds: null,
                useExactMatchForBeds: null,
                baths: null,
                moveInDate: null,
                maxRent: null,
                minRent: null,
                sortBy: "priceAsc",
                neighborhood: null,
                address: null,
              });
              navigate(`/search-results`);
            }}
          >
            <p className="mb-4 underline">Apartments for Rent in Boston</p>
          </span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setSearchParams({
                city: "Boston",
                university: null,
                beds: null,
                useExactMatchForBeds: null,
                baths: null,
                moveInDate: null,
                maxRent: null,
                minRent: null,
                sortBy: "priceAsc",
                neighborhood: "Fenway",
                address: null,
              });
              navigate(`/search-results`);
            }}
          >
            <p className="mb-4 underline">Cheap Apartments in Fenway</p>
          </span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setSearchParams({
                city: "Boston",
                university: null,
                beds: "2",
                useExactMatchForBeds: "true",
                baths: null,
                moveInDate: null,
                maxRent: null,
                minRent: null,
                sortBy: "priceAsc",
                neighborhood: "Allston",
                address: null,
              });
              navigate(`/search-results`);
            }}
          >
            <p className="mb-4 underline">2BR Apartments in Allston</p>
          </span>
        </div>
        <div className="p-3">
          <h3 className="font-semibold text-lg mb-6">About</h3>
          <Link to={"/cosigner"}>
            <p className="mb-4">Cosigner</p>
          </Link>
          <Link to={"/about"}>
            <p className="mb-4">Our Team</p>
          </Link>
          <Link to={"/terms-and-conditions"}>
            <p className="mb-4">Terms of service</p>
          </Link>
          <Link to={"/privacy-policy"}>
            <p className="mb-4">Privacy Policy</p>
          </Link>
        </div>
        <div className="p-3 hidden sm:block"></div>
        <div className="p-3">
          <Link
            to="/"
            className={`flex ttnc-logo items-center text-primary-green focus:outline-none focus:ring-0 hover:opacity-90 mb-4`}
          >
            {logoImg ? (
              <img className={`block max-h-14`} src={logoImg} alt="Logo" />
            ) : (
              "Logo Here"
            )}

            <h1 className="text-lg font-sans mb-2 ml-2 text-neutral-600 dark:text-neutral-50">
              Students
              <br />
              Landing
            </h1>
          </Link>{" "}
          <div className="flex space-x-3">
            <img src={img} alt="" />
            <div>
              <a
                href="https://www.instagram.com/studentslanding/"
                target="_blank"
                rel="noreferrer"
              >
                <p className="mb-2">
                  <i className="lab la-instagram text-xl"></i> Instagram
                </p>
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/@studentslanding"
                rel="noreferrer"
              >
                <p className="">
                  <i className="las la-video text-xl"></i> Youtube
                </p>
              </a>
            </div>
          </div>
          <p className="my-6 text-center md:text-left">
            34 Fisher Ave <br />
            Boston, MA 02120
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
