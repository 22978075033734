import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import React, { FC } from "react";

export interface SectionAboutUsProps {
    className?: string;
}

const SectionAboutUs: FC<SectionAboutUsProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-SectionAboutUs overflow-hidden relative ${className}`}
            data-nc-id="SectionAboutUs"
        >
            <div className="container p-5 space-y-16 lg:space-y-28">
                <div className="relative py-16">
                    <BackgroundSection />
                    <SectionClientSay uniqueClassName="SectionAboutUs_" />
                </div>
            </div>
        </div>
    )
}

export default SectionAboutUs;