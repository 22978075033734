import React, { FC } from "react";
import image from "images/mentor_connect.png"

export interface SectionSeniorConnectHeaderProps {
    className?: string;
}

const SectionSeniorConnectHeader: FC<SectionSeniorConnectHeaderProps> = ({ className = "" }) => {
    return (
        <div
            className="nc-SectionSeniorConnectHeader"
            data-nc-id="SectionSeniorConnectHeader"
        >
            <div className="grid align-middle justify-center items-center text-center">
                <div className="flex justify-center">
                    <img className="object-contain" src={image} alt="" />
                </div>
                <div className="mt-6 md:mt-14 space-y-3">
                    <h2 className="text-2xl md:text-5xl font-bold"><span className="text-primary-green">Senior Connect</span> program</h2>
                    <h2 className="text-2xl md:text-5xl font-bold">for remote apartment tours.</h2>
                </div>
                <div className="mt-5 md:mt-12">
                    <p>A buddy bridges the gap between you and your potential house by touring your selected house for you. Your <br />buddy will scan through the apartment and identify concerns like quality of furnishings, availability of <br />kitchen equipment, any damages, pests, and more.</p>
                </div>
            </div>
        </div>
    )
}

export default SectionSeniorConnectHeader;