import ApplicationCard from "components/ApplicationCard/ApplicationCard";
import { Property } from "data/models/property";
import React, { FC, useState } from "react";
import Skeleton from "react-loading-skeleton";
import cannot_find from "images/graphics/cannot_find.png"
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";

export interface SectionNewUpdatesProps {
    className?: string;
    properties?: Property[]
}

const SectionNewUpdates: FC<SectionNewUpdatesProps> = ({ className = "", properties = [] }) => {
    const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
    const navigate = useNavigate();


    const renderPropertyListView = () => {
        return properties.map((property) => (
            <div
                key={property?.id}
                onMouseEnter={() => setCurrentHoverID((_) => property.id!)}
                onMouseLeave={() => setCurrentHoverID((_) => -1)}
            >
                <ApplicationCard property={property} />
            </div>
        ));
    };

    const handleClick = () => {
        navigate("/search-results");
    }

    return (
        <div
            className={`nc-SectionNewUpdates relative bg-green-50 rounded-3xl`}
            data-nc-id="SectionNewUpdates"
        >
            {
                properties.length > 0 ? (
                    <div className="w-full p-8 flex-shrink-0 xl:px-8">
                        <div className="mb-7">
                            <h2 className="font-semibold md:text-xl text-gray-600">New updates ({properties.length})</h2>
                        </div>
                        <div className="grid grid-cols-1 gap-8">
                            {properties && properties.length > 1 ? (
                                renderPropertyListView()
                            ) : (
                                <Skeleton count={20} />
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="grid w-full p-8 md:p-14 flex-shrink-0 xl:px-8 justify-center space-y-10">
                        <div>
                            <img className="object-contain" src={cannot_find} alt="" />
                        </div>
                        <div className="text-center">
                            <h2 className="font-semibold md:text-3xl text-xl text-gray-600">Oh no!</h2>
                            <p className="font-normal text-sm md:text-lg xl:text-xl text-neutral-500 dark:text-neutral-400 mt-2">
                                You haven’t started any apartment applications.
                            </p>
                        </div>
                        <div className="text-center">
                            <ButtonPrimary onClick={handleClick}>Search Listings</ButtonPrimary>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default SectionNewUpdates;