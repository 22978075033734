export class HttpError extends Error {
  name = "CustomError";
  status: HttpErrorStatus;

  constructor(message: string, status: HttpErrorStatus, public url?: string) {
    super(`HTTP ${status} Error: ${message}`);
    this.status = status;

    // Include URL in the error message if provided
    if (url) {
      this.message += `\nURL: ${url}`;
    }
  }
}

export enum HttpErrorStatus {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
}
