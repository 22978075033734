import ApplicationCard from "components/ApplicationCard/ApplicationCard";
import { Property } from "data/models/property";
import React, { FC, useState } from "react";
import Skeleton from "react-loading-skeleton";

export interface SectionInProgressProps {
    className?: string;
    properties?: Property[]
}

const SectionInProgress: FC<SectionInProgressProps> = ({ className = "", properties = [] }) => {
    const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);

    const renderPropertyListView = () => {
        return properties.map((property) => (
            <div
                key={property.id}
                onMouseEnter={() => setCurrentHoverID((_) => property.id!)}
                onMouseLeave={() => setCurrentHoverID((_) => -1)}
            >
                <ApplicationCard property={property} />
            </div>
        ));
    };

    return (
        <div
            className={`nc-SectionInProgress relative`}
            data-nc-id="SectionInProgress"
        >
            {
                properties.length > 0 ? (
                    <div className="border rounded-3xl">
                        <div className="w-full p-8 flex-shrink-0 xl:px-8 ">
                            <div className="mb-7">
                                <h2 className="font-semibold md:text-xl text-gray-600">In-progress ({properties.length})</h2>
                            </div>
                            <div className="grid grid-cols-1 gap-8">
                                {properties && properties.length > 1 ? (
                                    renderPropertyListView()
                                ) : (
                                    <Skeleton count={20} />
                                )}
                            </div>
                        </div>
                    </div>
                ) : ("")
            }
        </div>
    )
}

export default SectionInProgress;
