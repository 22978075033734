import React, { useEffect, useMemo, useRef, useState } from "react";
import ListingCard from "./listingcard/ListingCard";
import Pagination from "./Pagination";
import { plainToClass } from "class-transformer";
import SearchHouse from "./../images/search_house.svg";

import { PageDetails, Property } from "data/models/property";
import { SearchParams } from "./Header/SearchBar";
import FilterModal from "./FilterModalMobileScreen";
import { School } from "data/models/school";
import moment from "moment";
import ListingRepository from "data/repositories/ListingRepository";
import { useListingSearchParams } from "providers/SearchParamsProvider";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useUserAccount } from "providers/UserAccountProvider";
import Slider from "@mui/material/Slider";
import { Checkbox, FormControlLabel } from "@mui/material";
import DatePicker from "react-datepicker";
import { universities } from "./Header/UniversityModal";
import { FaFilter } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import PlacesRepository from "data/repositories/PlacesRepository";
import ApiService from "services/api.service";
import { HttpError } from "data/models/errors/http-error";
import GoogleMapReact from "google-map-react";
import AnyReactComponent from "./AnyReactComponent/AnyReactComponent";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Helmet } from "react-helmet";


const SearchResultsScreen = () => {
  const { searchParams, setSearchParams } = useListingSearchParams();

  const { userAccount, setUserAccount } = useUserAccount();

  const beds = ["0", "1", "2", "3", "4", "5"];
  const baths = ["1", "1.5", "2", "2.5", "3"];
  const getBeds = (value: string, useExactMatch: string | null) => {
    return useExactMatch === "true" ? value : value + "+";
  };

  const [loading, setLoading] = useState(false);

  const [listings, setListings] = useState<Property[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDetails, setPageDetails] = useState<PageDetails>();
  const [togglePrice, setTogglePrice] = useState(false);
  const [toggleBeds, setToggleBeds] = useState(false);
  const [toggleUniversities, setToggleUniversities] = useState(false);
  const [toggleBaths, setToggleBaths] = useState(false);
  const [toggleMore, setToggleMore] = useState(false);
  const [priceMin, setPriceMin] = useState(0);
  const [priceMax, setPriceMax] = useState(10000);
  const [neighborhoods, setNeighborhoods] = useState<string[]>([]);
  const [trucatedNeighbourhoodShowLess, setTruncatedNeighbourhoodShowLess] =
    useState<string[]>([]);
  const [trucatedNeighbourhoodShowMore, setTruncatedNeighbourhoodShowMore] =
    useState<string[]>([]);
  const [universityButtonText, setUniversityButtonText] = useState<string>("");
  const [toggleView, setToggleView] = useState(false);
  const [toggleValue, setToggleValue] = useState(1);
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);


  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const filterRef = useRef<HTMLDivElement>(null);

  // Close filter popups when clicking anywhere on the website
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target as Node)
      ) {
        // Click is outside the filter popup, close it
        setTogglePrice(false);
        setToggleBeds(false);
        setToggleUniversities(false);
        // setToggleBaths(false);
        setToggleMore(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setTogglePrice, setToggleBeds, setToggleBaths, setToggleMore]);

  useMemo(() => {
    const fetchNeighborhoods = async () => {
      try {
        const jsonResponse = await PlacesRepository.getInstance(
          ApiService.getInstance()
        ).getAllNeighborhoods();
        setNeighborhoods(jsonResponse);
        setTruncatedNeighbourhoodShowLess(jsonResponse.slice(0, 11));
        setTruncatedNeighbourhoodShowMore(jsonResponse.slice(0, 36));
      } catch (error) {
        const httpError = error as HttpError;
        throw httpError;
      }
    };
    // const fetchSchools = async () => {
    //   const jsonResponse = await PlacesRepository.getInstance(ApiService.getInstance()).getAllSchools()
    //   console.log(jsonResponse);
    // }
    fetchNeighborhoods();
    // fetchSchools()
  }, []);
  useEffect(() => {
    if (searchParams.university !== null) {
      if (searchParams.university === "Northeastern University")
        setUniversityButtonText("NEU")
      else if (searchParams.university === "Boston University")
        setUniversityButtonText("BU")
      else if (searchParams.university === "Harvard University")
        setUniversityButtonText("HAR")
      else if (searchParams.university === "Massachusetts Institute of Technology")
        setUniversityButtonText("MIT")
      else if (searchParams.university === "Umass Boston")
        setUniversityButtonText("UMB")
    }
    fetchSearchResults(currentPage, searchParams);
  }, [searchParams]);

  const handleFilterClick = (filterType: string, value: string | null) => {
    if (filterType === "university") {
      if (value === "Northeastern University") setUniversityButtonText("NEU");
      else if (value === "Boston University") setUniversityButtonText("BU");
      else if (value === "Harvard University") setUniversityButtonText("HAR");
      else if (value === "Massachusetts Institute of Technology")
        setUniversityButtonText("MIT");
      else if (value === "Umass Boston") setUniversityButtonText("UMB");
    }
    setSearchParams({
      ...searchParams,
      [filterType]: searchParams[filterType] === value ? null : value,
    });
  };

  const handleClearFilter = () => {
    setPriceMin(0);
    setPriceMax(10000);
    setSearchParams({
      city: "Boston",
      university: null,
      beds: null,
      useExactMatchForBeds: null,
      baths: null,
      moveInDate: null,
      maxRent: "10000",
      minRent: "0",
      sortBy: "priceAsc",
      neighborhood: null,
      address: null,
    });
  };

  const handleUpdateFilter = () => {
    // Perform actions when Update button is clicked

    setIsFilterModalOpen(false);
    setCurrentPage(1);
    fetchSearchResults(1);
  };

  const handlePageChange = (page: number) => {
    // Make an API call to fetch results for the new page
    setCurrentPage(page);
    fetchSearchResults(page);
  };

  const handleSliderPriceChange = (price: number | number[]) => {
    const priceRange = price as [number, number];
    setPriceMin(priceRange[0]);
    setPriceMax(priceRange[1]);
  };

  const handlePriceChange = (price: number | number[]) => {
    const priceRange = price as [number, number];

    setSearchParams({
      ...searchParams,
      minRent: priceRange[0].toString(),
      maxRent: priceRange[1].toString(),
    });
  };

  const getSearchParamsForAPICall = async (params: SearchParams) => {
    let queryParam = "searchBy=";
    let schools: School[] = [];

    if (params.beds != null) {
      if (params.useExactMatchForBeds === "true" || params.beds === "0") {
        queryParam += `Bed:${params.beds}-${params.beds},`;
      } else {
        queryParam += `Bed:${params.beds}-MAX,`;
      }
    }
    if (params.baths != null) {
      queryParam += `Bath:${params.baths}-MAX,`;
    } else {
      queryParam += "Bath:1-MAX,";
    }
    let rentMin, rentMax;
    if (params.minRent == null || +params.minRent < 450) {
      rentMin = "450";
    } else {
      rentMin = params.minRent;
    }

    if (params.maxRent == null) {
      rentMax = 10000;
    } else {
      rentMax = params.maxRent;
    }

    queryParam += `Rent:${rentMin}-${rentMax}`;

    if (params.moveInDate) {
      const startDate = moment(params.moveInDate)
        .subtract(10, "days")
        .format("YYYYMMDD");
      const endDate = moment(params.moveInDate)
        .add(10, "days")
        .format("YYYYMMDD");
      queryParam += `,availabilityDate:${startDate}-${endDate}`;
    }

    if (params.university) {
      let url = `${process.env.REACT_APP_LANDING_PUBLIC_API_URL}/v2/schools/${params.university}`;

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      const response = await fetch(url, { headers });
      const schoolResponse = await response.json();
      schools = plainToClass(School, schoolResponse) as School[];

      if (schools.length) {
        queryParam += `&nearbySchool=${schools[0].id}&radius=2`;
      }
    }

    if (params.neighborhood) {
      queryParam += `&neighborhood=${params.neighborhood}`;
    }

    if (params.address) {
      queryParam += `&address=${params.address}`;
    }

    queryParam += `&status=available`;

    return queryParam;
  };

  const getSortParamsForAPICall = (params: SearchParams) => {
    let sortParams = "";
    if (params.sortBy != null) {
      switch (params.sortBy) {
        case "priceAsc":
          sortParams = "&sortBy=rent&order=ASC";
          break;
        case "priceDesc":
          sortParams = "&sortBy=rent&order=DESC";
          break;
        case "newest":
          sortParams = "&sortBy=postingDate&order=DESC";
          break;

        default:
          break;
      }
    }
    return sortParams;
  };

  const fetchSearchResults = async (page: number, params?: SearchParams) => {
    if (!params) {
      params = searchParams;
    }
    window.scrollTo(0, 0);

    setCurrentPage(page);
    setLoading(true);
    try {
      const propertyPaginatedResponse =
        // Page Number starts from zero.
        // So subtract -1 to the current page.
        await ListingRepository.getInstance().getPaginatedProperties(
          `pageTotal=true&page=${
            page - 1
          }&pageSize=15&${await getSearchParamsForAPICall(
            params
          )}${getSortParamsForAPICall(params)}`
        );

      setListings(propertyPaginatedResponse.items!);
      setPageDetails(propertyPaginatedResponse.pages!);
    } catch (error) {
      console.log("error", error);
      setListings([]);
      setPageDetails({
        current: 0,
        total: 0,
        items: 0,
        size: 0,
        maxSize: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleShowMoreAndShowLess = () => {
    switch (toggleValue) {
      case 1:
        setToggleValue(2);
        break;
      case 2:
        setToggleValue(3);
        break;
      case 3:
        setToggleValue(1);
        break;
      default:
        setToggleValue(1);
    }
  };

  return (
    <>
      <div
        className={`nc-SearchResultsScreen relative`}
        data-nc-id="SearchResultsScreen"
      >
        <Helmet><title>Students Landing</title></Helmet>
        <div className="container relative space-y-10 mt-20 mb-16 lg:mb-28">
          <div>
            <div className="flex justify-between">
              <h3 className="font-semibold text-3xl md:text-4xl xl:text-5xl">
                Apartment <span className="text-primary-green">Listings</span>
              </h3>
              <div className="md:flex cursor-pointer hidden">
                <div
                  className={`flex items-center rounded-l-lg px-4 border-2 ${toggleView ? "" : "bg-primary-100 text-primary-green font-semibold"}`}
                  onClick={() => { setToggleView(!toggleView) }}
                >
                  <i className="las la-bars text-2xl font-bold mr-2"></i>
                  List View
                </div>
                <div
                  className={`flex items-center rounded-r-lg px-4 border-2 ${toggleView ? "bg-primary-100 text-primary-green font-semibold" : ""}`}
                  onClick={() => { setToggleView(!toggleView) }}
                >
                  <i className="las la-map text-2xl font-bold mr-2"></i>
                  Map View
                </div>
              </div>
            </div>
            <br />
            {pageDetails &&
              pageDetails.total! >= 1 &&
              pageDetails.items! > 0 && (
                <h1 className="text-[1.25rem] font-semibold">
                  Showing{" "}
                  <span className="text-primary-green">
                    {pageDetails?.items! === pageDetails?.size!
                      ? (pageDetails?.total! - 1) * pageDetails?.size!
                      : pageDetails?.items!}
                  </span>{" "}
                  apartment listings
                </h1>
              )}
          </div>
          {/* Filters */}
          <div className="flex md:justify-between">
            <div className="md:hidden">
              <ButtonSecondary
                onClick={() => {
                  setIsFilterModalOpen(true);
                }}
                className="text-lg"
              >
                Filters <FaFilter className="ml-2" />
              </ButtonSecondary>
            </div>
            <div
              ref={filterRef}
              className="hidden md:flex text-center items-center space-x-5"
            >
              <div>
                <h2 className="font-semibold">Filters</h2>
              </div>
              <div>
                <ButtonSecondary
                  className={`rounded-md ${
                    searchParams.university !== null
                      ? "bg-primary-100 border-2 border-primary-green  font-semibold text-primary-700"
                      : ""
                  }`}
                  onClick={() => {
                    setToggleUniversities(!toggleUniversities);
                    setTogglePrice(false);
                    setToggleBeds(false);
                    setToggleMore(false);
                  }}
                >
                  {searchParams.university
                    ? universityButtonText
                    : "All Universities"}{" "}
                  <i className="ml-2 las la-angle-down"></i>
                </ButtonSecondary>
                {toggleUniversities && (
                  <div className="mt-3 absolute border p-5 z-10 bg-white rounded-lg transition-shadow shadow-xl text-left">
                    <h3 className="font-semibold mb-2">Universities</h3>
                    {/* Assuming universities is imported from another file */}
                    {/* Replace with your actual data source */}
                    {universities[searchParams?.city!].map(
                      (university, index) => (
                        <button
                          key={index}
                          onClick={() =>
                            handleFilterClick("university", university.name)
                          }
                          className={`rounded-lg px-4 py-2 mr-2 mb-3 ${
                            searchParams.university === university.name
                              ? "bg-primary-50 text-primary-green border-2 border-primary-green"
                              : "bg-transparent border border-gray-400"
                          }`}
                        >
                          {university.displayName}
                        </button>
                      )
                    )}
                  </div>
                )}
              </div>
              <div>
                <ButtonSecondary
                  className={`rounded-md ${
                    searchParams.maxRent !== "10000" ||
                    searchParams.minRent !== "0"
                      ? "bg-primary-100 border-primary-green border-2 font-semibold text-primary-700"
                      : ""
                  }`}
                  onClick={() => {
                    setTogglePrice(!togglePrice);
                    setToggleBeds(false);
                    setToggleUniversities(false);
                    setToggleMore(false);
                  }}
                >
                  {(searchParams.maxRent && searchParams.maxRent !== "10000") ||
                  (searchParams.minRent && searchParams.minRent !== "0")
                    ? "Price $" +
                      searchParams.minRent +
                      " - $" +
                      searchParams.maxRent
                    : "Any Price"}{" "}
                  <i className="ml-2 las la-angle-down"></i>
                </ButtonSecondary>
                {togglePrice && (
                  <div className="mt-3 absolute border w-1/5 p-5 z-10 bg-white rounded-lg transition-shadow shadow-xl text-left">
                    <h3 className="font-semibold mb-2">Price Range</h3>
                    <Slider
                      min={0}
                      max={10000}
                      value={[
                        priceMin ? priceMin : 0,
                        priceMax ? priceMax : 10000,
                      ]}
                      onChangeCommitted={(e, newValue) =>
                        handlePriceChange(newValue)
                      }
                      onChange={(e, newValue) =>
                        handleSliderPriceChange(newValue)
                      }
                    />
                    <div className="flex justify-between mt-2">
                      <span>${priceMin}</span>
                      <span>${priceMax}</span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <ButtonSecondary
                  className={`rounded-md ${
                    searchParams.beds !== null
                      ? "bg-primary-100 border-2 border-primary-green  font-semibold text-primary-700"
                      : ""
                  }`}
                  onClick={() => {
                    setToggleBeds(!toggleBeds);
                    setTogglePrice(false);
                    setToggleUniversities(false);
                    setToggleMore(false);
                  }}
                >
                  {searchParams.beds ? searchParams.beds + " Beds" : "All Beds"}{" "}
                  <i className="ml-2 las la-angle-down"></i>
                </ButtonSecondary>
                {toggleBeds && (
                  <div className="mt-3 absolute border p-5 z-10 bg-white rounded-lg transition-shadow shadow-xl text-left">
                    <h3 className="font-semibold mb-2">Beds</h3>
                    {beds.map((bed, index) => (
                      <button
                        key={index}
                        onClick={() => handleFilterClick("beds", bed)}
                        className={`rounded-lg px-4 py-2 mr-2 mb-2 ${
                          searchParams.beds === bed
                            ? "bg-primary-50 text-primary-green border-2 border-primary-green"
                            : "bg-transparent border border-gray-400"
                        }`}
                      >
                        {bed === "0"
                          ? "Studio"
                          : getBeds(bed, searchParams.useExactMatchForBeds)}
                      </button>
                    ))}
                    <div className="text-left">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              searchParams.useExactMatchForBeds === "true"
                            }
                            onChange={(e) => {
                              handleFilterClick(
                                "useExactMatchForBeds",
                                e.target.checked === true ? "true" : "false"
                              );
                            }}
                          />
                        }
                        label="Use exact match"
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* <div>
                <ButtonSecondary
                  className={`rounded-md ${
                    searchParams.baths !== null
                      ? "bg-primary-100 border-primary-green border-2 font-semibold text-primary-700"
                      : ""
                  }`}
                  onClick={() => {
                    setToggleBaths(!toggleBaths);
                    setTogglePrice(false);
                    setToggleBeds(false);
                    setToggleMore(false);
                  }}
                >
                  {searchParams.baths
                    ? searchParams.baths + " Baths"
                    : "All Baths"}{" "}
                  <i className="ml-2 las la-angle-down"></i>
                </ButtonSecondary>
                {toggleBaths && (
                  <div className="mt-3 absolute border p-5 z-10 bg-white rounded-lg transition-shadow shadow-xl text-left">
                    <h3 className="font-semibold mb-2">Baths</h3>
                    {baths.map((bath, index) => (
                      <button
                        key={index}
                        onClick={() => handleFilterClick("baths", bath)}
                        className={`rounded-lg px-4 py-2 mr-2 mb-2 ${
                          searchParams.baths === bath
                            ? "bg-primary-50 text-primary-green border-2 border-primary-green"
                            : "bg-transparent border border-gray-400"
                        }`}
                      >
                        {bath + "+"}
                      </button>
                    ))}
                  </div>
                )}
              </div> */}
              <div>
                <ButtonSecondary
                  className={`rounded-md ${
                    searchParams.neighborhood !== null ||
                    searchParams.moveInDate !== null ||
                    searchParams.baths !== null
                      ? "bg-primary-100 border-2 border-primary-green  font-semibold text-primary-700"
                      : ""
                  }`}
                  onClick={() => {
                    setToggleMore(!toggleMore);
                    setTogglePrice(false);
                    setToggleUniversities(false);
                    setToggleBeds(false);
                  }}
                >
                  More <i className="ml-2 las la-angle-down"></i>
                </ButtonSecondary>
                {toggleMore && (
                  <div className="mt-3 absolute border p-5 z-10 bg-white rounded-lg transition-shadow shadow-xl text-left">
                    <div className="mb-4">
                      <h3 className="font-semibold mb-2">Baths</h3>
                      {baths.map((bath, index) => (
                        <button
                          key={index}
                          onClick={() => handleFilterClick("baths", bath)}
                          className={`rounded-lg px-4 py-2 mr-2 mb-2 ${
                            searchParams.baths === bath
                              ? "bg-primary-50 text-primary-green border-2 border-primary-green"
                              : "bg-transparent border border-gray-400"
                          }`}
                        >
                          {bath + "+"}
                        </button>
                      ))}
                    </div>
                    <hr className="border-t mb-4" />
                    <div className="mb-4">
                      <h3 className="font-semibold mb-2">Neighborhood</h3>
                      {toggleValue === 1 &&
                        trucatedNeighbourhoodShowLess.map(
                          (neighborhood, index) => (
                            <button
                              key={index}
                              onClick={() =>
                                handleFilterClick("neighborhood", neighborhood)
                              }
                              className={`rounded-lg px-4 py-2 mr-2 mb-3 ${
                                searchParams.neighborhood === neighborhood
                                  ? "bg-primary-50 text-primary-green border-2 border-primary-green"
                                  : "bg-transparent border border-gray-400"
                              }`}
                            >
                              {neighborhood}
                            </button>
                          )
                        )}
                      {toggleValue === 2 &&
                        trucatedNeighbourhoodShowMore.map(
                          (neighborhood, index) => (
                            <button
                              key={index}
                              onClick={() =>
                                handleFilterClick("neighborhood", neighborhood)
                              }
                              className={`rounded-lg px-4 py-2 mr-2 mb-3 ${
                                searchParams.neighborhood === neighborhood
                                  ? "bg-primary-50 text-primary-green border-2 border-primary-green"
                                  : "bg-transparent border border-gray-400"
                              }`}
                            >
                              {neighborhood}
                            </button>
                          )
                        )}
                      {toggleValue === 3 &&
                        neighborhoods.map((neighborhood, index) => (
                          <button
                            key={index}
                            onClick={() =>
                              handleFilterClick("neighborhood", neighborhood)
                            }
                            className={`rounded-lg px-4 py-2 mr-2 mb-3 ${
                              searchParams.neighborhood === neighborhood
                                ? "bg-primary-50 text-primary-green border-2 border-primary-green"
                                : "bg-transparent border border-gray-400"
                            }`}
                          >
                            {neighborhood}
                          </button>
                        ))}
                      <div>
                        <span
                          className="cursor-pointer m-1 text-primary-green"
                          onClick={handleShowMoreAndShowLess}
                        >
                          {toggleValue === 1 && "Show more"}
                          {toggleValue === 2 && "Show all"}
                          {toggleValue === 3 && "Show less"}
                        </span>
                      </div>
                    </div>
                    <hr className="border-t mb-4" />
                    <div className="mb-4">
                      <h2 className="font-semibold mb-2">Move-in Date</h2>
                      <DatePicker
                        placeholderText={"Move-in Date"}
                        selected={
                          searchParams?.moveInDate &&
                          searchParams?.moveInDate.split("-").length > 0
                            ? moment(searchParams?.moveInDate!).toDate()
                            : null
                        }
                        onChange={(date) => {
                          handleFilterClick(
                            "moveInDate",
                            moment(date).format("YYYYMMDD")!
                          );
                        }}
                        className="filter-date-picker w-[220px]"
                        fixedHeight
                      />
                    </div>
                  </div>
                )}
              </div>
              <div>
                <ButtonSecondary
                  className={`rounded-md text-red-600 focus:ring-red-600`}
                  onClick={() => {
                    handleClearFilter();
                    setToggleBeds(false);
                    setTogglePrice(false);
                    setToggleUniversities(false);
                    setToggleMore(false);
                  }}
                >
                  Clear All
                </ButtonSecondary>
              </div>
            </div>
            <div className="flex md:w-1/4 w-full justify-center items-center ml-3 md:ml-0">
              <div className="hidden md:flex mr-2 md:mr-4 md:w-1/3 md:justify-end">
                <h3 className="font-semibold">Sort By</h3>
              </div>
              <div className="md:w-2/3">
                <select
                  value={searchParams.sortBy ? searchParams.sortBy : "priceAsc"}
                  onChange={(e) => {
                    handleFilterClick("sortBy", e.target.value);
                  }}
                  className="rounded-full md:px-4 md:py-2 md:w-full"
                >
                  <option value="newest">Newest</option>
                  <option value="priceAsc">Price: Low to High</option>
                  {/* Add more sorting options as needed */}
                </select>
              </div>
            </div>
          </div>
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
          <div className="max-h-fit overflow-auto">
            {/* Properties go here */}
            {!loading &&
              pageDetails &&
              pageDetails.total! >= 1 &&
              pageDetails.items! > 0 && (
                <div className="">
                  {
                    toggleView ?
                      <div className="w-full h-96 lg:h-[calc(100vh-88px)] hidden md:block">
                        {showFullMapFixed && (
                          <ButtonClose
                            onClick={() => setShowFullMapFixed(false)}
                            className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
                          />
                        )}
                        <GoogleMapReact
                          defaultZoom={12}
                          defaultCenter={{
                            lat: 42.361145,
                            lng: -71.057083,
                          }}
                          bootstrapURLKeys={{
                            key: "AIzaSyBKAfYPRG77UvTMaEPEkl2_KvZeRKzuZlA",
                          }}
                          yesIWantToUseGoogleMapApiInternals
                        >
                          {listings.map((property) => (
                            <AnyReactComponent
                              isSelected={currentHoverID === property.id}
                              key={property.id}
                              lat={+property!.address!.lat!}
                              lng={+property!.address!.lon!}
                              listing={property}
                            />
                          ))}
                        </GoogleMapReact>
                      </div>
                      :
                      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 2xl:gap-12">
                        {listings?.length
                          ? listings.map((property, index) => (
                            <div
                              key={index}
                              onMouseEnter={() => setCurrentHoverID((_) => property.id!)}
                              onMouseLeave={() => setCurrentHoverID((_) => -1)}
                            >
                              <ListingCard
                                className="h-full"
                                property={property}
                                userId={userAccount.id}
                              />
                            </div>
                          ))
                          : []}
                      </div>

                  }

                  <Pagination
                    currentPage={currentPage}
                    totalPages={pageDetails ? pageDetails?.total! : 1}
                    onPageChange={handlePageChange}
                  />
                  ``
                </div>
              )}
            {loading && (
              <div className="flex justify-center items-center h-screen">
                {/* Display loading indicator here */}
                <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary-green"></div>
              </div>
            )}

            {!loading &&
              pageDetails &&
              ((pageDetails?.total! <= 1 && pageDetails.items === 0) ||
                (pageDetails.current === 1 && pageDetails.items === 0)) && (
                <div className="flex justify-center flex-col items-center h-screen">
                  <img src={SearchHouse} alt="No Listings Found"></img>
                  <h1 className="text-2xl font-semibold my-20">
                    Uh oh! No listings found. Try changing filters or enter a
                    new search term
                  </h1>
                </div>
              )}
          </div>
        </div>
      </div>
      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        tabActive={"Boston"}
        tabs={["Boston", "New Jersey (Coming Soon)"]}
        selectedSearchParams={searchParams}
        onFilterClick={handleFilterClick}
        onClearFilter={handleClearFilter}
        onUpdateFilter={handleUpdateFilter}
        onPriceChange={handlePriceChange}
      />
    </>
  );
};

export default SearchResultsScreen;
