export class RequiredValidator {
  error?: string;
  public validate(value: any): string {
    this.error = "";
    if (!value) {
      this.error = "Required Field";
    }
    return this.error;
  }
}
