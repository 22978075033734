import { FC } from "react";
import termsAndConditionsCode from "./TermsAndConditionsHtmlCode";

export interface TermsAndConditonsProps {
  className?: string;
}

const TermsAndConditions: FC<TermsAndConditonsProps> = ({ className = "" }) => {
  return (
    <div
      className="m-16"
      dangerouslySetInnerHTML={{ __html: termsAndConditionsCode }}
    />
  );
};

export default TermsAndConditions;
