import { RequiredValidator } from "../form-validators/required-validator";
import { Address } from "../property";
import { PersonalDetailsModel } from "./personal-details-model";
import { RentalApplicationStepDataModel } from "./rental-application-step-model";

export class ResidencesModel extends RentalApplicationStepDataModel {
  id?: string;
  currentResidence?: ResidenceModel = new ResidenceModel();
  formerResidence?: ResidenceModel = new ResidenceModel();
  constructor(data?: Partial<ResidencesModel>) {
    super();
    this.createValidationMap();
    if (data) {
      Object.assign(this, data);
    }
  }

  static get key() {
    return "residences";
  }

  toRentalApplicationDocumentJson(data: any) {
    data.present_address =
      this.currentResidence!.address!.line1! +
      " " +
      (this.currentResidence!.address!.line2 ?? "");
    data.occupancy_from = this.currentResidence!.moveInDate
      ? this.currentResidence!.moveInDate!.toString()
      : "";
    data.occupancy_to = this.currentResidence!.moveOutDate
      ? this.currentResidence!.moveOutDate!.toString()
      : "";
    data.city = this.currentResidence!.address!.city;
    data.state = this.currentResidence!.address!.state;
    data.zip = this.currentResidence!.address!.zip;

    data.present_landlord_phone =
      this.currentResidence != null &&
      this.currentResidence!.landordDetails != null &&
      this.currentResidence!.landordDetails!.phoneNumber != null
        ? this.currentResidence!.landordDetails!.phoneNumber.toSerialized()
        : "";

    data.present_landlord_complete_address = "";

    data.present_landlord =
      this.currentResidence != null &&
      this.currentResidence!.landordDetails != null
        ? this.currentResidence!.landordDetails!.firstName
        : "";
    data.former_landlord = "";
    data.former_landlord_complete_address = "";
    data.former_landlord_phone = "";
    return data;
  }

  createValidationMap() {
    this.validationMap = new Map();
    const key = "currentResidence";

    this.validationMap.set(`${key}`, {
      validators: [new RequiredValidator()],
      getValue: (currentModel: ResidencesModel) => {
        return currentModel[key]!;
      },

      errors: [],
    });

    for (const entry of ["Line1", "City", "State", "Zip", "Country"]) {
      this.validationMap.set(`${key}${entry}`, {
        validators: [new RequiredValidator()],
        getValue: (currentModel: ResidencesModel) =>
          currentModel.currentResidence!.address![
            entry.toLowerCase() as keyof Address
          ]!,

        errors: [],
      });
    }
  }

  validate(): void {
    Array.from(this.validationMap.keys()).forEach((key) => {
      this.validationMap.get(key)!.errors = [];
      this.validationMap.get(key)!.validators.forEach((validator: any) => {
        const error = validator.validate(
          this.validationMap.get(key).getValue(this)
        );
        if (error) {
          this.validationMap.get(key)!.errors.push(error);
        }
      });
    });
  }
}

export class ResidenceModel {
  id?: string;
  address?: Address = new Address();
  type?: ResidenceType;
  moveInDate?: string;
  moveOutDate?: string;
  landordDetails?: PersonalDetailsModel = new PersonalDetailsModel();
  constructor(data?: Partial<ResidenceModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

export enum ResidenceType {
  LEASED = "Leased",
  OWNED = "Owned",
}
