import { useAuth0 } from "@auth0/auth0-react";
import { plainToClass } from "class-transformer";
import SectionInProgress from "components/SectionInProgress/SectionInProgress";
import SectionNewUpdates from "components/SectionNewUpdates/SectionNewUpdates";
import SectionSubmitted from "components/SectionSubmitted/SectionSubmitted";
import ListingCard from "components/listingcard/ListingCard";
import {
  PageDetails,
  Property,
  PropertyPaginatedResponse,
} from "data/models/property";
import UserAccountRepository from "data/repositories/UserAccountRepository";
import { useUserAccount } from "providers/UserAccountProvider";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "services/api.service";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const PageYourApplications = () => {
  const { userAccount, setUserAccount } = useUserAccount();
  const [properties, setProperties] = useState<Property[]>([]);
  const [loading, setLoading] = useState(false);
  const [likedListings, setLikedListings] = useState<Property[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const url = "https://prod.studentslanding.com/api/public/v2/listings";
        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
        };
        const response = await fetch(url, { headers });
        const json = await response.json();
        const listings = plainToClass(Property, json.items) as Property[];
        setProperties(listings.slice(0, 2));
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchShortlisted();
  }, []);
  const fetchShortlisted = async () => {
    setLikedListings(userAccount.likedListings!.slice(0, 3));
  };
  const handleClick = () => {
    navigate("/shortlist");
  };
  return (
    <div
      className={`nc-PageYourApplications relative`}
      data-nc-id="PageYourApplications"
    >
      <div className="container relative space-y-24 mt-20 mb-24 lg:space-y-20 lg:mb-28">
        <div>
          <h3 className="font-semibold text-3xl md:text-4xl xl:text-5xl !leading-[140%]">
            Your Apartment{" "}
            <span className="text-primary-green">Applications</span>
          </h3>
          <br />
          <p className="font-normal text-sm md:text-lg xl:text-xl text-neutral-500 dark:text-neutral-400 !leading-[145%]">
            Lorem ipsum dolor sit amet consectetur. Diam aliquet egestas arcu
            leo suspendisse consectetur in in. Purus molestie facilisis odio nec
            vitae vulputate placerat egestas id.
          </p>
        </div>
        {!loading && properties && (
          <>
            <SectionNewUpdates properties={properties} />
            <SectionSubmitted properties={properties} />
            <SectionInProgress properties={properties} />
          </>
        )}
        {loading && (
          <div className="flex justify-center items-center h-screen">
            {/* Display loading indicator here */}
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary-green"></div>
          </div>
        )}
        {!loading && likedListings.length === 0 && (
          <div className="space-y-7">
            <div className="text-center">
              <h3 className="font-semibold text-xl md:text-2xl !leading-[140%]">
                From your <span className="text-primary-green">shortlist</span>
              </h3>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 2xl:gap-12">
              {likedListings.map((likedListing, index) => (
                <ListingCard
                  key={index}
                  className="h-full"
                  property={likedListing}
                />
              ))}
            </div>
            <div className="text-center">
              <ButtonPrimary onClick={handleClick}>View more</ButtonPrimary>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageYourApplications;
