import React, { FC } from "react";
import image from "images/graphics/about us.png"

export interface SectionAboutUsHeaderProps {
    className?: string;
    children?: React.ReactNode;
}

const SectionAboutUsHeader: FC<SectionAboutUsHeaderProps> = ({ className = "", children }) => {
    return (
        <div className="nc-SectionAboutUsHeader" data-nc-id="SectionAboutUsHeader">
            <div className="grid align-middle justify-center items-center text-center">
                <div className="flex justify-center">
                    <img className="object-contain w-2/3" src={image} alt="" />
                </div>
                <div className="mt-6 md:mt-14">
                    <h2 className="text-2xl md:text-5xl font-bold">Welcome to Landing</h2>
                </div>
                <div className="md:mt-5">
                    <h2 className="text-2xl md:text-4xl font-bold text-primary-green">Your Trusted Partner in Your US Housing Journey</h2>
                </div>
                <div className="mt-5 md:mt-12">
                    <p> At Landing, we're more than just a service; we're your partners in navigating the exciting yet challenging <br />path of studying in the United States. Our mission is clear and close to our hearts: to assist international <br />students like you in landing in the US with ease and confidence.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SectionAboutUsHeader;