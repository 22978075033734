import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import SectionSeniorConnectHeader from "components/SectionSeniorConnect/SectionSeniorConnect";
import React, { FC } from "react";
import hiw1 from "images/graphics/rect_1.png"
import hiw2 from "images/graphics/rect_2.png"
import hiw3 from "images/graphics/rect_3.png"
import SectionSeniorConnectRoaster from "components/SectionSeniorConnectRoaster/SectionSeniorConnectRoaster";

export interface PageMentorConnectProps {
    className?: string;
}

const PageMentorConnect: FC<PageMentorConnectProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-PageMentorConnect overflow-hidden relative ${className}`}
            data-nc-id="PageMentorConnect"
        >
            <div className="container py-10 lg:py-14 space-y-16 lg:space-y-28 md:my-14">
                <SectionSeniorConnectHeader />
                <SectionHowItWork
                    data={[
                        {
                            id: 1,
                            img: hiw1,
                            title: "Request a Buddy",
                            desc: "For any virtual viewing you schedule on Students Landing, you can opt-in for a buddy & we will automatically assign a university senior to view the house in-person.",
                        },
                        {
                            id: 2,
                            img: hiw2,
                            title: "Pay the Buddy",
                            desc: "Once the buddy is assigned, you will be requested to pay the buddy before the tour begins.",
                        },
                        {
                            id: 3,
                            img: hiw3,
                            title: "Receive Feedback",
                            desc: "The buddy will look for things  that are hard to notice during virtual tours like unit damages, and surrounding environment. They will then share a detailed feedback over to you directly.",
                        },
                    ]}
                />
                <SectionSeniorConnectRoaster />
            </div>
        </div>
    )
}

export default PageMentorConnect;