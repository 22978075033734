import React, {
  ChangeEvent,
  ChangeEventHandler,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
  isRequired?: boolean;
  label?: string;
  type: string;
  error?: string;
  onChange?: ChangeEventHandler;
  onValidationChange?: (error: string | null) => void; // New prop for validation change
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id = "",
      label = "",
      isRequired = false,
      className = "",
      sizeClass = "px-8 py-3",
      fontClass = "text-sm font-normal",
      rounded = "rounded-full",
      error,
      children,
      onChange = () => { },
      onValidationChange = () => { }, // Default to an empty function
      type = "text",
      ...args
    },
    ref
  ) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
      error!
    );

    useEffect(() => {
      // Update errorMessage when parentError changes
      setErrorMessage(error);
    }, [error]);

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      if (errorMessage && value) {
        setErrorMessage("");
        onValidationChange && onValidationChange(null);
      }

      onChange && onChange(event);
    };
    const onBlurHandler = (event: any) => {
      const { value } = event.target;
      let error: string | null = null;

      if (isRequired && !value) {
        error = "Required Field";
        setErrorMessage(error);
      }

      onValidationChange && onValidationChange(error);
    };
    return (
      <>
        {label && (
          <label
            htmlFor={id}
            className="block text-sm font-medium text-gray-600 mb-1"
          >
            {label}{" "}
            {isRequired ? <span className="text-red-500">*</span> : null}
            {errorMessage ? (
              <span className="text-red-500"> Required field</span>
            ) : null}
          </label>
        )}

        <input
          id={id}
          name={id}
          ref={ref}
          type={type}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          className={`block w-full border-neutral-200 focus:border-primary-green focus:ring focus:ring-primary-green focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className} ${errorMessage
            ? "border-red-500 focus:border-red-500 focus:ring-red-500 "
            : ""
            }`}
          {...args}
        />
      </>
    );
  }
);

export default Input;
