import React, { FC } from "react";
import insurent2 from "images/graphics/insurent2.png"

export interface SectionInsurentHero2Props {
    className?: string;
}

const SectionInsurentHero2: FC<SectionInsurentHero2Props> = ({ className = "" }) => {

    return (
        <div
            className={`nc-SectionInsurentHero2 relative ${className}`}
            data-nc-id="SectionInsurentHero2"
        >
            <div className="relative grid md:grid-cols-2">
                <div className="flex justify-center items-center align-middle">
                    <img src={insurent2} alt="" />
                </div>
                <div className="flex justify-center items-center align-middle mt-10 md:mt-0">
                    <div className="grid items-center">
                        <p className="font-semibold text-lg xl:text-xl text-neutral-600">
                            <span className="text-primary-green">Landing Cosigner</span> acts as your lease guarantor, <br className="hidden md:block" />eliminating the need for a co-signer and <br className="hidden md:block" />simplifying the process of securing your dream <br className="hidden md:block" />apartment.
                        </p>
                        <br />
                        <p className="text-base !leading-[145%] text-neutral-500">
                            Best of all, you can get <span className="text-primary-green font-bold">approved in 30 minutes</span> at the lowest <br className="hidden md:block" />Guarantee prices, and the Guarantee is normally issued within 24 <br />hours.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SectionInsurentHero2;