export class PhoneValidator {
  error?: string;
  public validate(value: string): string {
    this.error = "";
    if (!value.length || value.length !== 10) {
      this.error = "10 Digit Mobile Number Required";
    }
    if (!/^[0-9]+$/.test(value)) {
      this.error = "Only Numbers Allowed";
    }
    return this.error;
  }
}
