import { plainToClass } from "class-transformer";
import { School } from "data/models/school";
import { ApiService } from "services/api.service";
import { HttpError } from "data/models/errors/http-error";

class PlacesRepository {
  private apiService: ApiService;
  private static instance: PlacesRepository | null = null;

  private constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  public static getInstance(apiService: ApiService): PlacesRepository {
    if (!PlacesRepository.instance) {
      if (!apiService) {
        throw new Error(
          "PlacesRepository.getInstance: apiService cannot be empty"
        );
      }
      PlacesRepository.instance = new PlacesRepository(apiService);
    }
    return PlacesRepository.instance;
  }

  async getAllSchools(): Promise<School[]> {
    try {
      const jsonResponse = await this.apiService.get(
        `${process.env.REACT_APP_LANDING_PUBLIC_API_URL}/v2/schools`
      );

      const schools = plainToClass(School, jsonResponse);
      return schools as School[];
    } catch (error) {
      const httpError = error as HttpError;
      throw httpError;
    }
  }

  async getSchoolById(schoolId: string): Promise<School> {
    try {
      const jsonResponse = await this.apiService.get(
        `${process.env.REACT_APP_LANDING_PUBLIC_API_URL}/v2/schools/${schoolId}`
      );

      const schools = plainToClass(School, jsonResponse) as School[];
      return schools && schools[0];
    } catch (error) {
      const httpError = error as HttpError;
      throw httpError;
    }
  }

  async getAllNeighborhoods(): Promise<string[]> {
    try {
      const jsonResponse = await this.apiService.get(
        `${process.env.REACT_APP_LANDING_PUBLIC_API_URL}/address/neighborhoods`
      );

      const neighborhoods = plainToClass(
        String,
        jsonResponse
      ) as unknown as string[];
      return neighborhoods.filter((neighborhood) => !!neighborhood).sort();
    } catch (error) {
      const httpError = error as HttpError;
      throw httpError;
    }
  }
}

export default PlacesRepository;
