import Heading from "components/Heading/Heading";
import { useListingSearchParams } from "providers/SearchParamsProvider";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";

export interface SectionPopularAreasProps {
  className?: string;
  tabActive: string;
  tabs: string[];
  data?: {
    id: number;
    img: string;
    heading: string;
    noOfProperties: number;
  }[];
  onClickTab: (item: string) => void;
}

const SectionPopularAreas: FC<SectionPopularAreasProps> = ({
  className = "",
  tabActive,
  tabs,
  data = [],
  onClickTab,
}) => {
  const [tabActiveState, setTabActiveState] = useState(tabActive);
  const navigate = useNavigate();
  const { searchParams, setSearchParams } = useListingSearchParams();

  useEffect(() => {
    setTabActiveState(tabActive);
  }, [tabActive]);

  const handleClick = (neighborhood: string) => {
    setSearchParams({
      city: "Boston",
      university: null,
      beds: null,
      useExactMatchForBeds: null,
      baths: null,
      moveInDate: null,
      maxRent: null,
      minRent: null,
      sortBy: "priceAsc",
      neighborhood: neighborhood,
      address: null,
    });
    navigate("/search-results?neighborhood=" + neighborhood);
  };

  return (
    <div
      className={`nc-SectionPopularAreas ${className}`}
      data-nc-id="SectionPopularAreas"
    >
      <Heading isCenter desc="Get started with these areas">
        Popular Areas
      </Heading>
      <div className="flex items-center justify-between">
        <Nav
          className="sm:space-x-2 mx-auto"
          containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
        >
          {tabs.map((item, index) => (
            <NavItem key={index} isActive={tabActiveState === item}>
              {item}
            </NavItem>
          ))}
        </Nav>
      </div>
      <div className="mt-14 mb-40 relative grid md:grid-cols-4 gap-20">
        {data.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-start max-w-xs mx-auto"
          >
            <div className="">
              <div className="">
                <img className="w-64 h-40 rounded-2xl" src={item.img} alt="" />
              </div>
              <div className="mt-6 text-center">
                <h3 className="text-2xl font-semibold mb-4">{item.heading}</h3>
                {/* <span className="block mt-1 mb-7 text-neutral-500 dark:text-neutral-400">
                  {item.noOfProperties} properties
                </span> */}
                <ButtonSecondary
                  className=""
                  onClick={() => handleClick(item.heading)}
                >
                  Explore
                </ButtonSecondary>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionPopularAreas;
