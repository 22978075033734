// BedsModal.jsx
import React from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";

interface BedsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectBeds: (beds: string) => void;
}

const BedsModal: React.FC<BedsModalProps> = ({
  isOpen,
  onClose,
  onSelectBeds,
}) => {
  const handleBedsSelect = (beds: string) => {
    onSelectBeds(beds);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      appElement={document.getElementById("root") || undefined}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          position: "absolute",
          height: "150px",
          width: "340px",
          margin: "auto",
          padding: "3px",
          background: "white",
          border: "1px solid #ccc",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
        },
      }}
      contentLabel="Beds Modal"
    >
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-xl font-semibold text-gray-500 ml-4 mt-4">
          Select number of Beds
        </h4>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 focus:outline-none mr-5 mt-4"
        >
          <FaTimes />
        </button>
      </div>
      <hr className="border-t border-gray-300 mb-4" />
      <div className="justify-center flex ">
        {Array.from(Array(6).keys()).map((number, index) => (
          <div key={index} className="text-center">
            <button
              className="rounded-full border px-4 py-2 mr-1" // Added margin for spacing
              onClick={() => handleBedsSelect(number.toString())}
            >
              {number === 0 ? "St" : number}
            </button>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default BedsModal;
