import { SectionProps } from "components/RentalApplicationForm/RentalApplicationForm";
import { FC } from "react";

export class RentalApplicationStepDataModel {
  id?: string;
  isCompleted?: Boolean;
  isRequired?: Boolean;
  validationMap: Map<string, any> = new Map();
  public getValidatonErrorMap(): Map<string, string[]> {
    const errorsMap: Map<string, string[]> = new Map();

    Array.from(this.validationMap.keys()).forEach((key) => {
      errorsMap.set(key, this.validationMap.get(key)!.errors);
    });
    return errorsMap;
  }

  public isValid(): boolean {
    this.validate();
    return Array.from(this.validationMap.keys()).every((key) => {
      return this.validationMap.get(key)!.errors.length === 0;
    });
  }
  validate(): void {
    Array.from(this.validationMap.keys()).forEach((key) => {
      this.validationMap.get(key)!.errors = [];
      this.validationMap.get(key)!.validators.forEach((validator: any) => {
        const error = validator.validate(
          this.validationMap.get(key).getValue(this)
        );
        if (error) {
          this.validationMap.get(key)!.errors.push(error);
        }
      });
    });
  }
}

export interface RentalApplicationStepUIProps<
  T extends RentalApplicationStepDataModel
> {
  name: string;
  shortName: string;
  component: FC<SectionProps<T>>;
  model: T;
  key: string;
}
