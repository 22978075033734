import { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Link, useNavigate } from "react-router-dom";
import { AttachmentType, Property } from "data/models/property";
import formatCurrency from "utils/formatCurrency";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import commingsoon from "images/graphics/comming_soon_image.png"


export interface StayCardHProps {
  className?: string;
  property?: Property;
}

const StayCardH: FC<StayCardHProps> = (props) => {
  const { attachments, description, bed, bath, rent, area, id, listingType, availabilityDate, address } =
    props.property!;

  const navigate = useNavigate();

  const handleClick = (id = "") => {
    navigate(`/listing-detail/${id}`);
  }

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-72 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={
            attachments
              ? attachments
                .filter((a) => a.type === AttachmentType.Image)
                .map((a) => a.link!)
              : [
                commingsoon
              ]
          }
          uniqueID={`${id}`}
          href={`/listing-detail/${id}`}
        />
        {/* <BtnLikeIcon isLiked={false} className="absolute right-3 top-3" /> */}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="grid grid-cols-3 gap-2 mt-4 sm:mt-0">
        <div className="flex items-center space-x-3">
          <i className="las la-bed text-lg"></i>
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {bed} bed{bed! > 1 ? "s" : ""}
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <i className="las la-bath text-lg"></i>
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {bath} bath{bath! > 1 ? "s" : ""}
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <i className="las la-calendar"></i>
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {availabilityDate?.toDateString().substring(4, 10)}
          </span>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{address?.line1} {address?.city}</span>
            </h2>
          </div>
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>{address?.city}, {address?.state} </span>
          </div>
        </div>
        <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {renderTienIch()}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div className="flex justify-between align-middle items-end">
          <p>
            <span className="text-xl font-bold">
              {`${formatCurrency(rent!)}`}
            </span>
            / month
          </p>
          <ButtonPrimary onClick={() => handleClick(id)}>See Details</ButtonPrimary>
        </div>
        {/* <div className="flex justify-end">
          <ButtonPrimary>See Details</ButtonPrimary>
        </div> */}
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform`}
      data-nc-id="StayCardH"
    >
      <Link to={`/listing-detail/${id}`} className="absolute inset-0"></Link>
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default StayCardH;
