import { Property } from "data/models/property";
import React, { useEffect, useState } from "react";
import ListingCard from "components/listingcard/ListingCard";
import cannot_find from "images/graphics/cannot_find.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useCopyToClipboard } from "react-use";
import { useUserAccount } from "providers/UserAccountProvider";
import { useAuth0 } from "@auth0/auth0-react";

const PageShortlist = () => {
  const { userAccount } = useUserAccount();

  const { isAuthenticated } = useAuth0();

  const [buttonText, setButtonText] = useState("Share your listings");
  const [value, copy] = useCopyToClipboard();
  const navigate = useNavigate();

  const handleShareClick = () => {
    if (userAccount.likedListings!.length === 0)
      alert("You don't have any shortlisted listings");
    else {
      var text =
        "Here are the listings I've shortlisted on Students Landing: \n\n\n";
      for (let index = 0; index < userAccount.likedListings!.length; index++) {
        const id = userAccount.likedListings![index].id;
        text += "https://studentslanding.com/listing-detail/" + id + "\n\n";
      }
      copy(text);
    }
    setButtonText("Copied!");
  };
  const handleClick = () => {
    navigate("/search-results");
  };
  return (
    <div className={`nc-PageShortlist relative `} data-nc-id="PageShortlist">
      <div className="container relative space-y-24 mt-20 mb-20 lg:space-y-24 lg:mb-28">
        <div className="md:flex justify-between">
          <div>
            <h3 className="font-semibold text-3xl md:text-4xl xl:text-5xl !leading-[140%]">
              Your <span className="text-primary-green">Shortlisted</span>{" "}
              Apartments
            </h3>
            <br />
            <p className="font-normal text-sm md:text-lg xl:text-xl text-neutral-500 dark:text-neutral-400 !leading-[145%]">
              Apartments that you love.
            </p>
          </div>
          <div className="mt-8">
            <ButtonSecondary onClick={handleShareClick}>
              {buttonText}
            </ButtonSecondary>
          </div>
        </div>
        <div>
          {userAccount.id && userAccount.likedListings!.length > 0 && (
            <div className="md:border md:rounded-3xl md:p-10">
              <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 2xl:gap-12">
                {userAccount.likedListings!.map((property, index) => (
                  <ListingCard
                    key={index}
                    className="h-full"
                    property={property}
                    userId={userAccount.id}
                  />
                ))}
              </div>
            </div>
          )}
          {!userAccount.id && (
            <div className="flex justify-center items-center h-screen">
              {/* Display loading indicator here */}
              <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary-green"></div>
            </div>
          )}
          {userAccount.id && userAccount.likedListings!.length === 0 && (
            <div className="rounded-3xl p-10 bg-green-50">
              <div className="grid w-full p-8 md:p-14 flex-shrink-0 xl:px-8 justify-center space-y-10">
                <div>
                  <img className="object-contain" src={cannot_find} alt="" />
                </div>
                <div className="text-center">
                  <h2 className="font-semibold md:text-3xl text-xl text-gray-600">
                    Oh no!
                  </h2>
                  <p className="font-normal text-sm md:text-lg xl:text-xl text-neutral-500 dark:text-neutral-400 mt-2">
                    You haven’t shortlisted any apartments.
                  </p>
                </div>
                <div className="text-center">
                  <ButtonPrimary onClick={handleClick}>
                    Search Listings
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageShortlist;
