import { plainToClass } from "class-transformer";
import { School } from "data/models/school";
import { ApiService } from "services/api.service";
import { HttpError } from "data/models/errors/http-error";

class RentalApplicationRepository {
  private apiService: ApiService;
  private static instance: RentalApplicationRepository | null = null;

  private constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  public static getInstance(
    apiService: ApiService
  ): RentalApplicationRepository {
    if (!RentalApplicationRepository.instance) {
      if (!apiService) {
        throw new Error(
          "RentalApplicationRepository.getInstance: apiService cannot be empty"
        );
      }
      RentalApplicationRepository.instance = new RentalApplicationRepository(
        apiService
      );
    }
    return RentalApplicationRepository.instance;
  }

  async saveSignature(id: string, formData: FormData) {
    try {
      const message = await this.apiService.postFormData(
        `${process.env.REACT_APP_LANDING_PRIVATE_API_URL}/account/student/${id}/signature`,
        formData
      );
      return message;
    } catch (error) {
      const httpError = error as HttpError;
      throw httpError;
    }
  }

  async creategroupRentalApplication(
    name: string,
    emails: string[],
    studentId: string
  ) {
    try {
      await this.apiService.post(
        `${process.env.REACT_APP_LANDING_PRIVATE_API_URL}/v1/groups/${studentId}`,
        {
          name,
          emails,
        }
      );
    } catch (error) {
      const httpError = error as HttpError;
      throw httpError;
    }
  }

  async submitCompletedRentalApplication(
    rentalApplicationData: any,
    studentId: string
  ) {
    try {
      await this.apiService.post(
        `${process.env.REACT_APP_LANDING_PRIVATE_API_URL}/account/student/${studentId}/rental-application`,
        rentalApplicationData
      );
      // {
      //   "emails": [
      //     "string"
      //   ],
      //   "name": "string"
      // }
    } catch (error) {
      const httpError = error as HttpError;
      throw httpError;
    }
  }
}

export default RentalApplicationRepository;
