// ProfessionalInformationSection.jsx

import React, { ChangeEvent, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { SectionProps } from "./RentalApplicationForm";
import {
  Occupation,
  ProfessionalDetailsModel,
} from "data/models/rental-application/professional-details-model";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const ProfessionalInformationSection: React.FC<
  SectionProps<ProfessionalDetailsModel>
> = ({ sectionDetails, setSectionDetails, handleBack, handleNext }) => {
  const [validationErrorMap, setValidatorErrorMap] = useState<
    Map<string, string[]>
  >(sectionDetails.getValidatonErrorMap());
  const handleInputChange = (
    field: keyof ProfessionalDetailsModel,
    value: string
  ) => {
    setSectionDetails(
      new ProfessionalDetailsModel({
        ...sectionDetails,
        [field]: value,
      })
    );
  };

  const onNextClick = () => {
    if (sectionDetails.isValid()) {
      handleNext();
    } else {
      setValidatorErrorMap(sectionDetails.getValidatonErrorMap());
    }
  };
  return (
    <div className="">
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        {/* Current Occupation (Radio Buttons) */}
        <div className="mb-6 lg:col-span-2">
          <label
            htmlFor="currentOccupation"
            className="block text-sm font-medium text-gray-600"
          >
            Current Occupation
            <span className="ml-1 text-red-500">*</span>
            {validationErrorMap.get("currentOccupation")?.length! > 0 && (
              <span className="ml-1 text-red-500">Required field</span>
            )}
          </label>
          <FormControl>
            <RadioGroup
              row
              name="currentOccupation"
              value={sectionDetails.currentOccupation}
              onChange={(e) => {
                handleInputChange("currentOccupation", e.target.value);
              }}
            >
              {Object.values(Occupation)
                .filter((key) => key !== Occupation.NONE)
                .map((key) => (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio />}
                    label={key}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <hr className="mb-6"></hr>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-1">
          <Select
            id="incomeSource"
            options={["Loans", "Family", "Self"]}
            label="Income Source"
            value={sectionDetails.incomeSource || "Select Income Source"}
            placeholder="Select income source"
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              handleInputChange("incomeSource", e.target.value)
            }
          ></Select>
        </div>
        <div className="mb-6 lg:col-span-1">
          {/*TODO: Validated income */}
          <Input
            type="number"
            label="Monthly Income"
            id="monthlyIncome"
            name="monthlyIncome"
            isRequired={true}
            placeholder="Enter your Monthly Income in USD"
            value={sectionDetails.monthlyIncome || ""}
            error={
              validationErrorMap.get("monthlyIncome")?.length
                ? validationErrorMap.get("monthlyIncome")?.[0]
                : undefined
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange("monthlyIncome", e.target.value)
            }
          />
        </div>
      </div>

      <hr className="mt-2 mb-6"></hr>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <div className="mb-6 lg:col-span-2">
          <label
            htmlFor="formerOccupation"
            className="block text-sm font-medium text-gray-600"
          >
            Former Occupation
          </label>
          <FormControl>
            <RadioGroup
              row
              name="formerOccupation"
              value={sectionDetails.formerOccupation || null}
              onChange={(e) => {
                handleInputChange("formerOccupation", e.target.value);
              }}
            >
              {Object.values(Occupation).map((key) => (
                <FormControlLabel
                  key={key}
                  value={key}
                  control={<Radio />}
                  label={key}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <hr className="mb-6"></hr>
      <div className="flex justify-around">
        <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>
        <ButtonPrimary onClick={onNextClick}>Save & Continue</ButtonPrimary>
      </div>
    </div>
  );
};

export default ProfessionalInformationSection;
