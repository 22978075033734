import Glide from "@glidejs/glide";
import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import { useEffect } from "react";
import clientSay1 from "images/graphics/a1.png";
import clientSay2 from "images/graphics/a2.png";
import clientSay3 from "images/graphics/a3.png"
import clientSay4 from "images/graphics/a4.png";
import clientSay5 from "images/graphics/a5.png";
import quotationImg from "images/quotation.png";
import quotationImg2 from "images/quotation2.png";
import useNcId from "hooks/useNcId";

export interface SectionClientSayProps {
  className?: string;
  uniqueClassName: string;
}

const DEMO_DATA = [
  {
    id: 1,
    clientName: "Ojaswin T",
    clientAddress: "Boston University, Fall 2023 Intake",
    clientimg: clientSay1,
    content:
      "The Students Landing team is amazing! Even though my visa got approved last minute, Abhishek got me housing close to campus.",
  },
  {
    id: 2,
    clientName: "Manish S",
    clientAddress: "NEU, Fall 2022 Intake",
    clientimg: clientSay3,
    content:
      "We were about to pay a high broker fee before getting in touch with Abhishek. He helped us save money and finalize the house quickly.",
  },
  {
    id: 3,
    clientName: "Priya S",
    clientAddress: "NEU, Spring 2023 Intake",
    clientimg: clientSay2,
    content:
      "Abhishek helped me find roommates and accommodation at a good price. I will definitely recommend his services to others.",
  },
  {
    id: 4,
    clientName: "Suhas K",
    clientAddress: "MCPHS, Fall 2022 Intake",
    clientimg: clientSay5,
    content:
      "I almost got scammed finding property through Facebook. Abhishek is trustworthy, responsive and this apartment exceeds expectations.",
  },
  {
    id: 5,
    clientName: "Sapna R",
    clientAddress: "NEU, Fall 2023 Intake",
    clientimg: clientSay4,
    content:
      "I had a great experience interacting with Students Landing team. He was very helpful and my four roommates and I were able to find a property quickly.",
  },
];

const SectionClientSay: FC<SectionClientSayProps> = ({
  className = "",
  uniqueClassName = "",
}) => {
  const UNIQUE_CLASS = `SectionClientSay_glide_${uniqueClassName}` + useNcId();

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      setTimeout(() => {
        new Glide(`.${UNIQUE_CLASS}`, {
          perView: 1,
        }).mount();
      }, 10);
    }
  }, []);

  const renderBg = () => {
    return (
      <div className="hidden md:block">
        <img
          className="absolute bottom-full right-full top-1 w-16"
          src={clientSay2}
          alt=""
        />
        <img
          className="absolute top-full right-full w-16"
          src={clientSay3}
          alt=""
        />

        <img
          className="absolute top-full left-full w-16"
          src={clientSay4}
          alt=""
        />
        <img
          className="absolute bottom-full left-full top-1 w-16"
          src={clientSay5}
          alt=""
        />
      </div>
    );
  };

  return (
    <div
      className={`nc-SectionClientSay relative ${className} `}
      data-nc-id="SectionClientSay"
    >
      <Heading desc="Quotes from previous international students about us!" isCenter>
        Your Seniors trust us, you can too.
      </Heading>
      <div className="relative md:mb-16 max-w-2xl mx-auto">
        {renderBg()}
        {/* <img className="mx-auto md:w-24 w-20" src={clientSay1} alt="" /> */}
        <div className={`mt-12 lg:mt-16 relative ${UNIQUE_CLASS}`}>
          <img
            className="opacity-50 md:opacity-100 absolute -mr-16 lg:mr-3 right-full top-36"
            src={quotationImg}
            alt=""
          />
          <img
            className="opacity-50 md:opacity-100 absolute -ml-16 lg:ml-3 left-full top-36"
            src={quotationImg2}
            alt=""
          />
          <div className="glide__track " data-glide-el="track">
            <ul className="glide__slides ">
              {DEMO_DATA.map((item) => (
                <li
                  key={item.id}
                  className="glide__slide flex flex-col items-center text-center"
                >
                  <img className="mx-auto md:w-24 w-20 my-5" src={item.clientimg} alt="" />
                  <span className="block text-base md:text-2xl">{item.content}</span>
                  <span className="block mt-8 text-2xl font-semibold">
                    {item.clientName}
                  </span>
                  <div className="flex items-center space-x-2 md:text-lg mt-2 text-neutral-400">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg> */}
                    🎈
                    <span>{item.clientAddress}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div
            className="mt-10 glide__bullets flex items-center justify-center"
            data-glide-el="controls[nav]"
          >
            {DEMO_DATA.map((item, index) => (
              <button
                key={item.id}
                className="glide__bullet w-2 h-2 rounded-full bg-neutral-300 mx-1 focus:outline-none"
                data-glide-dir={`=${index}`}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionClientSay;
