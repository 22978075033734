import moment from "moment";
import { Property } from "../property";
import { AdditionalRoomatesModel } from "./additional-roomates-model";
import { PersonalDetailsModel } from "./personal-details-model";
import { ProfessionalDetailsModel } from "./professional-details-model";
import { ReferencesModel } from "./references-model";
import { ResidencesModel } from "./residences-model";

export class RentalApplicationModel {
  id?: string;
  personalDetails?: PersonalDetailsModel = new PersonalDetailsModel();
  professionalDetails?: ProfessionalDetailsModel =
    new ProfessionalDetailsModel();
  additionalRoomates?: AdditionalRoomatesModel = new AdditionalRoomatesModel();
  references?: ReferencesModel = new ReferencesModel();
  residences?: ResidencesModel = new ResidencesModel();
  listing: Property;

  constructor(data?: Partial<RentalApplicationModel>) {
    this.personalDetails =
      (data && data.personalDetails) || new PersonalDetailsModel();
    this.professionalDetails =
      (data && data.professionalDetails) || new ProfessionalDetailsModel();
    this.references = (data && data.references) || new ReferencesModel();
    this.residences = (data && data.residences) || new ResidencesModel();
    this.additionalRoomates =
      (data && data.additionalRoomates) || new AdditionalRoomatesModel();

    this.listing = (data && data.listing) || new Property();
  }

  toRentalApplicationDocumentJson() {
    let data = {
      date: moment().format("MM/DD/YYYY"),
      listing_id: this.listing.id,
      listing_city: this.listing.address!.city,
    };

    if (this.personalDetails) {
      data = this.personalDetails!.toRentalApplicationDocumentJson(data);
    }
    if (this.professionalDetails) {
      data = this.professionalDetails!.toRentalApplicationDocumentJson(data);
    }
    if (this.residences != null) {
      data = this.residences!.toRentalApplicationDocumentJson(data);
    }
    if (this.additionalRoomates != null) {
      data = this.additionalRoomates!.toRentalApplicationDocumentJson(data);
    }
    if (this.references != null) {
      data = this.references!.toRentalApplicationDocumentJson(data);
    }

    return data;
  }
}
