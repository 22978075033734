import { instanceToPlain, plainToClass } from "class-transformer";
import { HttpError } from "data/models/errors/http-error";
import { LikedListingsPaginatedResponse, UserAccount } from "data/models/user-account";
import { ApiService } from "services/api.service";

class UserAccountRepository {
  private apiService: ApiService;
  private static instance: UserAccountRepository | null = null;
  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  public static getInstance(apiService?: ApiService): UserAccountRepository {
    if (!UserAccountRepository.instance) {
      if (!apiService) {
        throw new Error(
          "UserAccountRepository.getInstance: apiService cannot be empty"
        );
      }
      UserAccountRepository.instance = new UserAccountRepository(apiService!);
    }
    return UserAccountRepository.instance;
  }

  async getAccountByEmailId(email: string): Promise<UserAccount> {
    try {
      const userAccountResponse = await this.apiService.get(
        `${process.env.REACT_APP_LANDING_PRIVATE_API_URL}/account/details/?email=${email}`
      );

      const userAccount = plainToClass(UserAccount, userAccountResponse);
      return userAccount;
    } catch (error) {
      const httpError = error as HttpError;
      throw httpError;
    }
  }

  async createUserAccount(userAccount: UserAccount): Promise<UserAccount> {
    try {
      let createdUserAccountResponse = await this.apiService.post(
        `${process.env.REACT_APP_LANDING_PRIVATE_API_URL}/account/student`,
        instanceToPlain(userAccount)
      );

      createdUserAccountResponse = await createdUserAccountResponse.json();

      const createdUserAccount = plainToClass(UserAccount, createdUserAccountResponse);

      return createdUserAccount;
    } catch (error) {
      const httpError = error as HttpError;
      throw httpError;
    }
  }

  async modifyUserAccount(userAccount: UserAccount): Promise<UserAccount> {
    try {
      const modifiedUserAccount = await this.apiService.put(
        `${process.env.REACT_APP_LANDING_PRIVATE_API_URL}/account/student/${userAccount.id}`,
        userAccount
      );
      return modifiedUserAccount;
    } catch (error) {
      // Handle error
      console.error("Error modifying user account:", error);
      throw error;
    }
  }

  async submitUserDocument(id : string, file : File, type: string): Promise<Boolean>{
    try {
      const message = await this.apiService.postMultiPartRequest(
        `${process.env.REACT_APP_LANDING_PRIVATE_API_URL}/account/student/${id}/document?type=${type}`, file
      );
      return message;
    } catch (error) {
      const httpError = error as HttpError;
      throw httpError;
    }
  }

  async deleteUserDocument(userId: string, documentId: string): Promise<void> {
    try {
      await this.apiService.delete(
        `${process.env.REACT_APP_LANDING_PRIVATE_API_URL}/account/student/${userId}/document/${documentId}`
      );
    } catch (error) {
      const httpError = error as HttpError;
      throw httpError;
    }
  }

  async shortlistListing(userId: string, listingId: string): Promise<UserAccount>{
    try {
      const userAccount = await this.apiService.put(
        `${process.env.REACT_APP_LANDING_PRIVATE_API_URL}/account/student/${userId}/like-listing-toggle/${listingId}`, {}
      );

      return userAccount;
    } catch (error) {
      const httpError = error as HttpError;
      throw httpError;
    }
  }

  async getShorlistListings(userId: string): Promise<LikedListingsPaginatedResponse>{
    try {
      const likedListings = await this.apiService.get(
        `${process.env.REACT_APP_LANDING_PRIVATE_API_URL}/v2/listings/${userId}/liked`
      );
      return likedListings;
    } catch (error) {
      const httpError = error as HttpError;
      throw httpError;
    }
  }

  async getImageById(userId: string, attachmentId: string): Promise<Blob>{
    try {
      const jsonResponse = await this.apiService.get(
        `${process.env.REACT_APP_LANDING_PRIVATE_API_URL}/${userId}/document/${attachmentId}`
      );

      return jsonResponse.blob();
    } catch (error) {
      const httpError = error as HttpError;
      throw httpError;
    }
  }

}

export default UserAccountRepository;
