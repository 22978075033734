import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import img1 from "images/avatars/Rectangle 16.png"
import img2 from "images/avatars/Rectangle 17.png"
import img3 from "images/avatars/Rectangle 18.png"

export interface SectionSeniorConnectRoasterProps {
    className?: string;
}

const SectionSeniorConnectRoaster: FC<SectionSeniorConnectRoasterProps> = ({ className = "" }) => {
    return (
        <div
            className="nc-SectionSeniorConnectRoaster"
            data-nc-id="SectionSeniorConnectRoaster"
        >
            <div className="grid md:p-14 p-5 bg-green-50 rounded-3xl">
                <div>
                    <Heading isCenter desc="These folks and more go above and beyond to get feedback for you.">Our Senior Connect roster</Heading>
                </div>
                <div className="grid md:grid-cols-3 grid-cols-2 gap-8">
                    <div className="bg-white rounded-3xl p-5">
                        <div className="flex space-x-2 items-center">
                            <img src={img1} alt="" />
                            <div>
                                <h2 className="font-semibold">Rishab Khuba</h2>
                                <p className="md:text-sm text-xl">Northeastern University</p>
                            </div>
                        </div>
                        <div>
                            <p className="md:text-sm ">Hello, I am an extrovert with the mindset of helping people as much as I can. Feel free to reach out to me for your upcoming journey, and I make sure I will be helpful every way possible :)</p>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl p-5">
                        <div className="flex space-x-2 items-center">
                            <img src={img2} alt="" />
                            <div>
                                <h2 className="font-semibold">Advaith Kandiraju</h2>
                                <p className="md:text-sm text-xl">Northeastern University</p>
                            </div>
                        </div>
                        <div>
                            <p className="md:text-sm ">I know finding a house is difficult. I believe i cater to students needs for finding their house and giving proper feedback.</p>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl p-5">
                        <div className="flex space-x-2 items-center">
                            <img src={img3} alt="" />
                            <div>
                                <h2 className="font-semibold">Nidhi Mehta </h2>
                                <p className="md:text-sm text-xl">Northeastern University</p>
                            </div>
                        </div>
                        <div>
                            <p className="md:text-sm ">I am a graduate student at Northeastern University, pursuing my masters in Data Analytics Engineering. I love travelling, food and movies. I like to have long conversations on anything that interests me.
                            </p>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl p-5">
                        <div className="flex space-x-2 items-center">
                            <img src={img2} alt="" />
                            <div>
                                <h2 className="font-semibold">Rishab Khuba</h2>
                                <p className="md:text-sm text-xl">Northeastern University</p>
                            </div>
                        </div>
                        <div>
                            <p className="md:text-sm ">I am a graduate student at Northeastern University, pursuing my masters in Data Analytics Engineering. I love travelling, food and movies. I like to have long conversations on anything that interests me.
                            </p>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl p-5">
                        <div className="flex space-x-2 items-center">
                            <img src={img3} alt="" />
                            <div>
                                <h2 className="font-semibold">Advaith Kandiraju</h2>
                                <p className="md:text-sm text-xl">Northeastern University</p>
                            </div>
                        </div>
                        <div>
                            <p className="md:text-sm ">I know finding a house is difficult. I believe i cater to students needs for finding their house and giving proper feedback</p>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl p-5">
                        <div className="flex space-x-2 items-center">
                            <img src={img1} alt="" />
                            <div>
                                <h2 className="font-semibold">Nidhi Mehta</h2>
                                <p className="md:text-sm text-xl">Northeastern University</p>
                            </div>
                        </div>
                        <div>
                            <p className="md:text-sm ">Hello, I am an extrovert with the mindset of helping people as much as I can. Feel free to reach out to me for your upcoming journey, and I make sure I will be helpful every way possible :)</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionSeniorConnectRoaster;