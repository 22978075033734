import { HttpError } from "data/models/errors/http-error";
import axios from "axios";

export class ApiService {
  private _accessToken: string | undefined;
  private static instance: ApiService | null = null;

  constructor(token: string | undefined) {
    this._accessToken = token;
  }

  private async makeRequest(
    url: string,
    method: string,
    data?: any,
    contentType: string = "application/json",
  ): Promise<any> {
    const headers: any = {
      "Content-Type": contentType,
      Accept: "application/json",
    };

    if (url.startsWith(process.env.REACT_APP_LANDING_PRIVATE_API_URL!)) {
      if (this._accessToken) {
        headers["Authorization"] = `Bearer ${this._accessToken}`;
      }
    }

    const response = await fetch(url, {
      method: method,
      headers: headers,
      body: data ? JSON.stringify(data) : undefined,
    });

    if (!response.ok) {
      try {
        const errorResponse = await response.json();
        throw new HttpError(
          errorResponse
            ? errorResponse.ErrorMessage
            : `HTTP error! Status: ${response.status}`,
          response.status,
          url
        );
      } catch (e) {
        if (response.status === 404) {
          throw new HttpError(
            `HTTP error! Status: ${response.status}`,
            response.status,
            url
          );
        }
      }
      
    }

    return response.json();
  }

  static getInstance(token?: string): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService(token);
    }
    return ApiService.instance;
  }

  setToken(accessToken: string) {
    this._accessToken = accessToken;
  }

  async get(url: string): Promise<any> {
    return this.makeRequest(url, "GET");
  }

  async post(url: string, data: any): Promise<any> {
    const headers: any = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (url.startsWith(process.env.REACT_APP_LANDING_PRIVATE_API_URL!)) {
      if (this._accessToken) {
        headers["Authorization"] = `Bearer ${this._accessToken}`;
      }
    }

    const response = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new HttpError(
        errorResponse
          ? errorResponse.ErrorMessage
          : `HTTP error! Status: ${response.status}`,
        response.status,
        url
      );
    }

    return response;
  }


  async getMultiPartRequest(url: string, file: any): Promise<any> {
    const headers: any = {
      "Content-Type": "multipart/form-data",
    };
    if (url.startsWith(process.env.REACT_APP_LANDING_PRIVATE_API_URL!)) {
      if (this._accessToken) {
        headers["Authorization"] = `Bearer ${this._accessToken}`;
      }
    }
    const formData = new FormData();
    formData.append("document", file);
    try {
      const response = await axios.post(url, formData, {
        headers: headers,
      });
      return response;
    } catch (ex) {
      console.log(ex);
    }
  }

  async put(url: string, data: any): Promise<any> {
    return this.makeRequest(url, "PUT", data);
  }

  async postMultiPartRequest(url: string, file: any): Promise<any> {
    const headers: any = {
      "Content-Type": "multipart/form-data",
    };
    if (url.startsWith(process.env.REACT_APP_LANDING_PRIVATE_API_URL!)) {
      if (this._accessToken) {
        headers["Authorization"] = `Bearer ${this._accessToken}`;
      }
    }
    const formData = new FormData();
    formData.append("document", file);
    try {
      const response = await axios.post(url, formData, {
        headers: headers,
      });
      return response;
    } catch (ex) {
      console.log(ex);
    }
  }

  async postFormData(url: string, formData: FormData): Promise<any> {
    const headers: any = {
      "Content-Type": "multipart/form-data",
    };
    if (url.startsWith(process.env.REACT_APP_LANDING_PRIVATE_API_URL!)) {
      if (this._accessToken) {
        headers["Authorization"] = `Bearer ${this._accessToken}`;
      }
    }
    try {
      const response = await axios.post(url, formData, {
        headers: headers,
      });
      return response;
    } catch (ex) {
      console.log(ex);
    }
  }

  async delete(url: string): Promise<any> {
    const headers: any = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (url.startsWith(process.env.REACT_APP_LANDING_PRIVATE_API_URL!)) {
      if (this._accessToken) {
        headers["Authorization"] = `Bearer ${this._accessToken}`;
      }
    }

    const response = await fetch(url, {
      method: "DELETE",
      headers: headers,
      body: null,
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new HttpError(
        errorResponse
          ? errorResponse.ErrorMessage
          : `HTTP error! Status: ${response.status}`,
        response.status,
        url
      );
    }

    return response;
  }
}

export default ApiService;
