import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.png";
import { useAuth0 } from "@auth0/auth0-react";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ img = logoImg, className = "" }) => {
  const { isAuthenticated } =
    useAuth0();
  return (
    <Link
      to={isAuthenticated ? "/search-results" : "/"}
      className={`flex ttnc-logo items-center text-primary-6000 focus:outline-none focus:ring-0 hover:opacity-90 ${className}`}
    >
      {img ? (
        <img className={`block md:max-h-12 max-h-12`} src={img} alt="Logo" />
      ) : (
        "Logo Here"
      )}

      <h1 className="text-base font-sans hidden md:block mb-2 ml-2 text-neutral-600 dark:text-neutral-50">
        Students
        <br />
        Landing
      </h1>
    </Link>
  );
};

export default Logo;
