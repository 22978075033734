import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import logo from "images/logo.png"

export interface SectionWhatMakesUsDifferentProps {
    className?: string;
    data?: {
        id: number;
        properties: string;
        slanding: string;
        other: string;
    }[];
}

const SectionWhatMakesUsDifferent: FC<SectionWhatMakesUsDifferentProps> = ({
    className = "",
    data = []
}) => {
    return (
        <div
            className={`nc-SectionWhatMakesUsDifferent  ${className}`}
            data-nc-id="SectionWhatMakesUsDifferent"
        >
            <Heading isCenter desc="Trust, safety, and personalisation for international students">
                What makes us different
            </Heading>
            <div className="rounded-3xl border-2">
                <div className="relative grid grid-cols-3 gap-20  md:pt-14 md:pr-20 md:pl-20 md:pb-10 p-8">
                    <div className="pb-10"> </div>
                    <div className="grid items-center justify-center">
                        <img src={logo} className="md:hidden max-h-12" alt="" />
                        <p className="hidden md:block font-semibold text-xl">
                            Students Landing
                        </p>
                    </div>
                    <div className="grid items-center justify-center">
                        <p className="md:hidden font-semibold text-xl">Others</p>
                        <p className="hidden md:block font-semibold text-xl">Other Platforms</p>
                    </div>
                </div>
                <div className="relative grid grid-cols-3 gap-20 md:pr-20 md:pl-20 md:pb-10 md:pt-0 p-8">
                    <div className="text-sm md:text-base">
                        <p>Reduced fees</p>
                    </div>
                    <div className="grid items-center justify-center">
                        <p className="bg-green-50 px-3 py-1 rounded-3xl w-fit">✔</p>
                    </div>
                    <div className="grid items-center justify-center">
                        <p className="bg-red-50 px-3 py-1 rounded-3xl w-fit">✖</p>
                    </div >
                </div >
                <div className="relative grid grid-cols-3 gap-20 md:pr-20 md:pl-20 md:pb-10 md:pt-0 p-8">
                    <div className="text-sm md:text-base">
                        <p>Pay in India</p>
                    </div>
                    <div className="grid items-center justify-center">
                        <p className="bg-green-50 px-3 py-1 rounded-3xl w-fit">✔</p>
                    </div>
                    <div className="grid items-center justify-center">
                        <p className="bg-red-50 px-3 py-1 rounded-3xl w-fit">✖</p>
                    </div >
                </div >
                <div className="relative grid grid-cols-3 gap-20 md:pr-20 md:pl-20 md:pb-10 md:pt-0 p-8">
                    <div className="text-sm md:text-base">
                        <p>100% scam protection</p>
                    </div>
                    <div className="grid items-center justify-center">
                        <p className="bg-green-50 px-3 py-1 rounded-3xl w-fit">✔</p>
                    </div>
                    <div className="grid items-center justify-center">
                        <p className="bg-red-50 px-3 py-1 rounded-3xl w-fit">✖</p>
                    </div >
                </div >
                <div className="relative grid grid-cols-3 gap-20 md:pr-20 md:pl-20 md:pb-10 md:pt-0 p-8">
                    <div className="text-sm md:text-base">
                        <p>Cosigner service</p>
                    </div>
                    <div className="grid items-center justify-center">
                        <p className="bg-green-50 px-3 py-1 rounded-3xl w-fit">✔</p>
                    </div>
                    <div className="grid items-center justify-center">
                        <p className="bg-red-50 px-3 py-1 rounded-3xl w-fit">✖</p>
                    </div >
                </div >
                <div className="relative grid grid-cols-3 gap-20 md:pr-20 md:pl-20 md:pb-10 md:pt-0 p-8">
                    <div className="text-sm md:text-base">
                        <p>On-demand apartment tours</p>
                    </div>
                    <div className="grid items-center justify-center">
                        <p className="bg-green-50 px-3 py-1 rounded-3xl w-fit">✔</p>
                    </div>
                    <div className="grid items-center justify-center">
                        <p className="bg-red-50 px-3 py-1 rounded-3xl w-fit">✖</p>
                    </div >
                </div >
                <div className="relative grid grid-cols-3 gap-20 md:pr-20 md:pl-20 md:pb-14 md:pt-0 p-8">
                    <div className="text-sm md:text-base">
                        <p>Online application</p>
                    </div>
                    <div className="grid items-center justify-center">
                        <p className="bg-green-50 px-3 py-1 rounded-3xl w-fit">✔</p>
                    </div>
                    <div className="grid items-center justify-center">
                        <p className="bg-green-50 px-3 py-1 rounded-3xl w-fit">✔</p>
                    </div>
                </div >
            </div >
        </div >
    )
}

export default SectionWhatMakesUsDifferent