import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import maintenance from "images/graphics/maintenance.png"
import Heading from "components/Heading/Heading";

export interface PageMaintenanceProps {
    className?: string;
    children?: React.ReactNode;
}

const PageMaintenance: FC<PageMaintenanceProps> = ({ className = "", children }) => {
    return (
        <div className="container relative pt-10 pb-16 lg:pb-20 lg:pt-11">
            <div className="grid align-middle justify-center items-center text-center">
                <div className="flex justify-center">
                    <img className="object-contain w-2/3" src={maintenance} alt="" />
                </div>
                <div className="my-16 text-center">
                    <Heading desc="Please wait for our commencement." isCenter>Graduating to a better version!</Heading>
                </div>
            </div>
        </div>
    )
}

export default PageMaintenance