import React, { FC } from "react";
import insurent1 from "images/graphics/insurent1.png"

export interface SectionInsurentHeroProps {
    className?: string;
}

const SectionInsurentHero: FC<SectionInsurentHeroProps> = ({ className = "" }) => {

    return (
        <div
            className={`nc-SectionInsurentHero relative ${className}`}
            data-nc-id="SectionInsurentHero"
        >
            <div className="mt-20 md:mt-28 relative grid md:grid-cols-2">
                <div className="md:hidden justify-center items-center align-middle flex mb-8">
                    <img src={insurent1} alt="" />
                </div>
                <div className="flex justify-center items-center align-middle">
                    <div>
                        <h3 className="font-semibold text-3xl md:text-4xl xl:text-5xl !leading-[140%]">
                            <span className="text-primary-green">Your Apartment,</span>
                            <br /> Our Guarantee
                        </h3>
                        <br />
                        <p className="text-sm md:text-base !leading-[145%] text-neutral-500">
                            Finding the perfect apartment can be a dream for students, but it <br className="hidden md:block" />often requires meeting strict financial and credit requirements set by <br />landlords.
                        </p>
                        <br />
                        <p className="text-sm md:text-base !leading-[145%] text-neutral-500">
                            If your income isn't at least 40 times the monthly rent, your U.S. credit <br className="hidden md:block" />score falls below 700, you lack U.S. credit history as a foreigner, or if <br className="hidden md:block" />you're not currently employed or you’re a student, your options can be <br className="hidden md:block" />limited.
                        </p>
                        <br />
                        <p className="font-semibold md:text-lg xl:text-xl text-neutral-600">
                            This is where <span className="text-primary-green">Landing Cosigner</span> steps in to make <br className="hidden md:block" />getting an approval a whole lot <br className="md:hidden" />easier.
                        </p>
                    </div>
                </div>
                <div className="md:flex justify-center items-center align-middle hidden">
                    <img src={insurent1} alt="" />
                </div>
            </div>
        </div>
    )

}

export default SectionInsurentHero;