import React, { ChangeEvent, useState, useEffect } from "react";

interface PhoneInputProps {
  countryCode: string;
  label: string;
  className: string;
  phoneNumber: string;
  id: string;
  error?: string;
  isRequired?: boolean;
  name: string;
  onPhoneNumberChange: (phoneNumber: string, countryCode: string) => void;
}

interface Country {
  name: string;
  code: string;
  dialCode: string;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  countryCode,
  phoneNumber,
  label,
  onPhoneNumberChange,
  className,
  error,
  isRequired,
  id,
  name,
}) => {
  const phoneNumberAutoFormat = (phoneNumber: string): string => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, "");

    if (number.length < 3) return number;
    if (number.length < 7) return number.replace(/(\d{3})(\d{3})/, "$1-$2");
    return number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  };

  const [errorMessage, setErrorMessage] = useState<string | undefined>(error!);
  const [formattedNumber, setFormattedNumber] = useState<string | undefined>(
    phoneNumberAutoFormat(phoneNumber)
  );

  useEffect(() => {
    // Update errorMessage when parentError changes
    setErrorMessage(error);
  }, [error, phoneNumber]);
  const [countries, setCountries] = useState<Country[]>([]);

  const countryData: Country[] = require("../../data/countrycodes.json");

  useEffect(() => {
    setCountries(countryData);
  }, []);

  const handleCountryCodeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (errorMessage && phoneNumber && e.target.value) {
      setErrorMessage("");
    }
    onPhoneNumberChange(phoneNumber, e.target.value);
  };

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (errorMessage && e.target.value && countryCode) {
      setErrorMessage("");
    }
    onPhoneNumberChange(e.target.value, countryCode);
  };

  const onBlurHandler = (event: any) => {
    const { value } = event.target;
    let error: string | null = null;

    if (isRequired && (!value || !countryCode)) {
      error = "Required Field";
      setErrorMessage(error);
    }
  };

  return (
    <div>
      {label && (
        <label
          htmlFor="phone"
          className="block mb-2 text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label} {isRequired ? <span className="text-red-500">*</span> : null}
          {errorMessage ? (
            <span className="text-red-500"> {errorMessage}</span>
          ) : null}
        </label>
      )}

      <div className="flex">
        <select
          placeholder="Select"
          value={countryCode}
          onChange={handleCountryCodeChange}
          className="mr-2 p-2 border border-neutral-200 focus:border-primary-green focus:ring focus:ring-primary-green focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-full text-sm font-normal h-10 px-4 mt-1"
        >
          {/* Render country options dynamically */}
          {countries.map((country) => (
            <option key={country.code} value={country.dialCode}>
              {country.code
                ? `${country.dialCode} (${country.code})`
                : "Select"}
            </option>
          ))}
        </select>
        <input
          type="tel"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          onBlur={onBlurHandler}
          className={`border border-neutral-200 focus:border-primary-green focus:ring focus:ring-primary-green focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-full text-sm font-normal h-10 px-4 py-3 ${className} mt-0`}
          placeholder="Enter phone number"
        />
      </div>
    </div>
  );
};

export default PhoneInput;
